import React, {useState} from 'react';
import PostItem from '../PostItem/PostItem';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

/**
 *
 * props: {servicePosts:[], taskPosts:[]}
 */

function MultiPostDisplay(props) {
  function changePostType(type) {
    setPostType(type);
    props.history.push({search: queryString.stringify({type})});
  }

  const isMobile = window.innerWidth <= 500;

  const search = queryString.parse(props.location.search);

  const [postType, setPostType] = useState(search.type || 'Task');

  //const filters = this.props.filters;
  let posts = postType === 'Task' ? props.taskPosts : props.servicePosts; //this.props.list;
  if (posts) {
    return (
      <React.Fragment>
        <div className={'subbar ' + (isMobile ? ' subbar-connected-m' : '')}>
          <div
            className={
              'subbar-buttons ' +
              (postType === 'Task' ? 'subbar-buttons-selected' : '')
            }
            onClick={() => changePostType('Task')}
          >
            Opgaver
          </div>
          <div
            className={
              'subbar-buttons ' +
              (postType === 'Service' ? ' subbar-buttons-selected' : '')
            }
            onClick={() => changePostType('Service')}
          >
            Service
          </div>
        </div>
        <div className="row ">
          {posts.map(item => (
            <PostItem
              postItem={item}
              postType={postType === 'Service' ? 'service' : 'opgaver'}
            />
          ))}

          {posts.length === 0 && (
            <div className="text-center w-100 p-5 ">
              <p>Ingen poster.. </p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return <div className="container">Loading.. </div>;
  }
}

export default withRouter(MultiPostDisplay);
