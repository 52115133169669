import {SetNewConversation} from '../../../redux/reducers/messageReducer';
import React, {Component} from 'react';
import {connect} from 'react-redux';

const withNewConversation = WrappedComponent => {
  const Wrapped = class extends React.Component {
    constructor(props) {
      super(props);
    }

    contactUser({receiverID, name, lastName}) {
      if (this.props.loggedInUser.isLoggedIn) {
        const userID = this.props.loggedInUser._id;
        //const receiverID = this.props.user._id;
        /*    MessageFacade.postNewConversation(userID, receiverID);*/

        this.props.openConversationModal({
          userID,
          receiverID,
          name,
          lastName
        });

        ///  const message = {content: 'new message blah', author: userID};
        // MessageFacade.sendMessage(receiverID, message);
      }
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          contactUser={this.contactUser.bind(this)}
        />
      );
    }
  };

  function mapStateToProps(state) {
    return {
      loggedInUser: state.user.userData
    };
  }
  const mapDispatchToProps = dispatch => {
    return {
      SetNewConversation: value => {
        dispatch({
          type: SetNewConversation,
          val: {
            userID: '',
            receiverID: '',
            ...value
          }
        });
      },
      openConversationModal: value => {
        dispatch({
          type: SetNewConversation,
          val: {...value, open: true}
        });
      },
      closeConversationModal: value => {
        dispatch({
          type: SetNewConversation,
          val: {
            open: false,
            userID: '',
            receiverID: ''
          }
        });
      }
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(Wrapped);
};

export default withNewConversation;
