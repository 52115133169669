import React, {Component} from 'react';
import {connect} from 'react-redux';
import DataStore from '../../Data/DataStore';
import './EditProfile.css';
import Moment from 'moment';
import 'moment/locale/da';
//import * as firebase from 'firebase';
import firebase from '../../Data/firebase';
import axios from 'axios';
import {
  HandleProfileChange,
  HandleCancelEditProfileBtn,
  HandleEditProfileBtn,
  SetPreviousData,
  ResetUserData
} from '../../redux/reducers/userReducer';

import {
  Button,
  Form,
  Popup,
  Header,
  Icon,
  Divider,
  Checkbox,
  Segment
} from 'semantic-ui-react';
import profileImagePlaceholder from '../../style/svg/pb-placeholder.svg';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import FileProcessor from '../reuseable/FileSelector/FileSelector';
import EditWorkerData from './EditWorkerData';
import PasswordChange from './PasswordChange';
import ProfileDeletionModal from './ProfileDeletionModal';
import toColor from '../../utils/toColor';
import Loading from '../reuseable/Loading/Loading';
import Cookies from 'js-cookie';
import LoginRequired from '../Login/LoginRequired';
import {successToast, warningToast} from '../../utils/toast';
import {errorLog} from '../../utils/logger';
import PageHead from '../reuseable/PageHead/PageHead';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    Moment.locale('da');
    this.state = {
      emailValidated: false,
      mail: '',
      mailconfirm: '',
      showPasswordChange: false,
      openDeletionModal: false,
      profileDeleted: false,
      isLoading: false
    };
    this.textInput = React.createRef();
  }
  componentWillMount() {
    this.props.handleEditProfileBtn();
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
  componentDidUpdate() {
    if (this.state.profileDeleted) {
      this.props.history.push('/');
    }
  }
  componentWillUnmount() {
    if (this.state.profileDeleted) {
      this.props.setPreviousData({});
      this.props.signOut();
      Cookies.remove('elfj');
    } else if (this.props.previousData.name) {
      this.props.handleCancelEditProfileBtn();
      this.props.setPreviousData({});
    }
  }

  handleProfilePhotoSelect(e, files) {
    let file = files[0];
    if (file) {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Make a fileInfo Object

        const fileInfo = {
          file: file,
          base64: reader.result
        };
        this.props.handleProfileChange({
          name: 'photoURL',
          value: fileInfo
        });
      }; // reader.onload
    }
  }

  uploadImage = async () => {
    if (this.props.user.photoURL.base64) {
      let signedUrl = await DataStore.getSignedUrls([
        this.props.user.photoURL.file.type
      ]);
      await DataStore.uploadOne(this.props.user.photoURL.file, signedUrl[0]);
      this.props.handleProfileChange({
        name: 'photoURL',
        value: signedUrl[0].getURL
      });
    }
    if (this.props.user.coverURL.base64) {
      let signedUrl = await DataStore.getSignedUrls([
        this.props.user.coverURL.file.type
      ]);
      await DataStore.uploadOne(this.props.user.coverURL.file, signedUrl[0]);
      this.props.handleProfileChange({
        name: 'coverURL',
        value: signedUrl[0].getURL
      });
    }
  };
  handleCoverPhotoSelect(e, files) {
    let file = files[0];
    if (file) {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          file: file,
          base64: reader.result
        };
        this.props.handleProfileChange({
          name: 'coverURL',
          value: fileInfo
        });
      }; // reader.onload
    }
  }

  validateEmailField() {
    let re = /\S+@\S+\.\S+/;
    this.setState({emailValidated: re.test(this.state.mail)});
  }

  _updateFireBaseEmail(email) {
    //updates users email in firebase
    //TODO: use state
    let user = firebase.auth().currentUser;
    return user.updateEmail(email);
  }
  updateProfile = async () => {
    const changedMail = !(
      this.props.previousData.mail === this.props.user.mail
    );
    const mailConfirm = !(this.props.user.mail === this.state.mailconfirm);
    if (mailConfirm && changedMail) {
      warningToast({header: 'Mail er ikke ens'});
      this.setState({isLoading: false});

      return;
    }

    try {
      await this.uploadImage();

      let user = firebase.auth().currentUser;
      let updatedData = {...this.props.user};

      if (updatedData.mail !== user.email) {
        //only update users email in firebase if email has been updated
        //TODO: Dont update email in elillaServer if firebase refuses emailUpdate!
        await this._updateFireBaseEmail(updatedData.mail);
      }

      await DataStore.updateUserProfile(updatedData);
      this.props.setPreviousData(updatedData);
      successToast({
        header: 'Din profildata er opdateret'
      });
    } catch (error) {
      errorLog('editProfile.updateProfile', error);
    } finally {
      this.setState({isLoading: false});
    }
  };

  _getCityNameByZip = async zip => {
    let endPoint = 'https://dawa.aws.dk/postnumre/' + zip;
    try {
      let response = await axios.get(endPoint);
      return response.data.navn; //response.data.nr returns postalcode
    } catch (er) {
      errorLog('editProfile._getCityNameByZip', er);

      return null;
    }
  };

  async _handleAddressChange(target) {
    this.props.handleProfileChange(target);
    let zipcode = target.value;
    if (zipcode.length === 4) {
      try {
        let cityName = await this._getCityNameByZip(zipcode);
        this.props.handleProfileChange({
          name: target.name.replace('zipCode', 'cityName'),
          value: cityName
        });
      } catch (er) {}
    } else {
      this.props.handleProfileChange({
        name: target.name.replace('zipCode', 'cityName'),
        value: ''
      });
    }
  }

  render() {
    let user = this.props.user;

    if (!user.isLoggedIn) {
      return <LoginRequired />;
    }
    if (this.state.isLoading) {
      return <Loading text="Gemmer.." fullpage={true} />;
    }
    const backgroundImage = user.coverURL.base64 || user.coverURL;
    let imgStyle = {
      backgroundColor: toColor(user._id),
      backgroundImage: `url(${backgroundImage})`
    };
    const changedMail = !(this.props.previousData.mail === user.mail);
    return (
      <React.Fragment>
        <PageHead title="Indstillinger" />
        <div className="row text-center mb-3 ">
          <Header dividing className="w-100 p-3" as="h2">
            Rediger profil
          </Header>
        </div>

        <div className="row pb-5">
          <div className="col-12 col-md-4 ">
            <div className="profile-image-container">
              <UserThumbnail
                src={user.photoURL.base64 || user.photoURL}
                enableLink={false}
                size={150}
                type="simple"
                userId={user._id}
                name={user.name}
              />

              <FileProcessor
                ref="chooseProfilePhoto"
                onFileSelect={this.handleProfilePhotoSelect.bind(this)}
                multiple={false}
              />

              <Button
                className="upload-btn-style"
                onClick={() => this.refs.chooseProfilePhoto.chooseFile()}
              >
                <Icon name="image outline" /> Upload profilbillede
              </Button>
            </div>
          </div>

          <div className="col-12 col-md-8 ">
            <Segment>
              <Form className="form-data-input">
                <Form.Group widths="equal">
                  <Popup
                    trigger={
                      <Form.Input
                        label="Fornavn"
                        placeholder="Fornavn"
                        name="name"
                        type="text"
                        onChange={evt =>
                          this.props.handleProfileChange(evt.target)
                        }
                        value={this.state.name || user.name}
                        ref={this.textInput}
                      />
                    }
                    content="Skriv dit fornavn."
                  />

                  <Popup
                    trigger={
                      <Form.Input
                        label="Efternavn"
                        placeholder="Efternavn"
                        name="lastName"
                        type="text"
                        onChange={evt =>
                          this.props.handleProfileChange(evt.target)
                        }
                        value={this.state.lastName || user.lastName}
                      />
                    }
                    content="Skriv dit efternavn."
                  />
                </Form.Group>
              </Form>

              <Form>
                <Popup
                  trigger={
                    <Form.Input
                      onFocus={() => {
                        this.setState({emailValidated: true});
                      }}
                      onBlur={() => this.validateEmailField()}
                      label="E-mail adresse"
                      placeholder="E-mail Addresse"
                      name="mail"
                      type="text"
                      required
                      onChange={event => {
                        //this.setState({ emailValidated: true });

                        this.props.handleProfileChange(event.target);
                      }}
                      value={this.props.user.mail}
                      className="form-data-input"
                    />
                  }
                  content="Skriv din email."
                />

                {changedMail && (
                  <Form.Input
                    onFocus={() => {
                      // this.setState({emailValidated: true});
                    }}
                    onBlur={() => this.validateEmailField()}
                    label="Bekræft E-mail adresse"
                    placeholder="Bekræft E-mail Addresse"
                    name="mailconfirm"
                    type="text"
                    required
                    onChange={event => {
                      this.setState({mailconfirm: event.target.value});
                    }}
                    className={
                      'form-data-input ' +
                      (!(
                        this.state.mailconfirm.length === 0 ||
                        this.state.mailconfirm.localeCompare(user.mail) === 0
                      )
                        ? ' form-input-error'
                        : '')
                    }
                    value={this.state.mailconfirm}
                  />
                )}
              </Form>
              <a
                className="form-data-input"
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  display: 'flex'
                }}
                onClick={() =>
                  this.setState({
                    showPasswordChange: !this.state.showPasswordChange
                  })
                }
              >
                Skift adgangskode
              </a>
              {this.state.showPasswordChange && (
                <PasswordChange
                  hidePasswordChange={() =>
                    this.setState({showPasswordChange: false})
                  }
                />
              )}
            </Segment>

            <Popup
              trigger={
                <Checkbox
                  className="checkbox-style form-data-input"
                  toggle
                  checked={user.workerData.isWorkerProfile}
                  onChange={(evt, p) => {
                    this.props.handleProfileChange({
                      name: 'workerData.isWorkerProfile',
                      value: p.checked
                    });
                  }}
                  label="Aktiver arbejder-profil"
                />
              }
              content="Hvis du aktiverer din arbejder-profil, vil du kunne oprette service og være synlig for andre brugere som kan kontakte dig."
            />

            {user.workerData.isWorkerProfile && (
              <Segment style={{marginBottom: '40px'}}>
                <EditWorkerData />
              </Segment>
            )}

            <Segment>
              <Header size="small" as="h2">
                Coverbillede
              </Header>
              <Divider />
              <div
                style={{...imgStyle, marginTop: '10px'}}
                className="user-cover-img "
              />
              <Button
                className="upload-btn-style form-data-input"
                onClick={() => this.refs.chooseCoverPhoto.chooseFile()}
              >
                <Icon name="image outline" /> Upload coverbilllede
              </Button>
              <FileProcessor
                ref="chooseCoverPhoto"
                onFileSelect={this.handleCoverPhotoSelect.bind(this)}
                multiple={false}
              />
            </Segment>
            <div className="edit-profile-btn-container">
              <Button
                className="upload-btn-style success-button"
                onClick={() => {
                  this.setState({isLoading: true});
                  this.updateProfile();
                  this.setState({editEnabled: false});
                }}
              >
                Gem
              </Button>

              <a
                style={{marginLeft: '7px', cursor: 'pointer'}}
                onClick={() => {
                  // this.props.handleCancelEditProfileBtn();
                  this.props.history.push('/profil');
                }}
              >
                Annuller
              </a>
            </div>
            <a
              style={{
                color: 'red',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                float: 'right'
              }}
              onClick={() => {
                this.setState({openDeletionModal: true});
              }}
            >
              Slet profil
            </a>
            <ProfileDeletionModal
              open={this.state.openDeletionModal}
              onClose={() => {
                this.setState({openDeletionModal: false});
              }}
              onDeletionComplete={() => {
                this.setState({profileDeleted: true});
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    previousData: state.user.previousData
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleProfileChange: value => {
      dispatch({
        type: HandleProfileChange,
        val: value
      });
    },
    handleEditProfileBtn: value => {
      dispatch({
        type: HandleEditProfileBtn,
        val: value
      });
    },
    handleCancelEditProfileBtn: value => {
      dispatch({
        type: HandleCancelEditProfileBtn,
        val: value
      });
    },
    setPreviousData: value => {
      dispatch({
        type: SetPreviousData,
        val: value
      });
    },
    signOut() {
      dispatch({
        type: ResetUserData
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
