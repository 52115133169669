import React, {Component} from 'react';
import {connect} from 'react-redux';
import './ImageUploader.css';
import FileProcessor from '../FileSelector/FileSelector';
import DataStore from '../../../Data/DataStore';
import deleteIcon from '../../../style/svg/delete.svg';
import addIcon from '../../../style/svg/add.svg';
import {Button, Icon} from 'semantic-ui-react';
import toColor from '../../../utils/toColor';
import {warningToast} from '../../../utils/toast';
import Resizer from 'react-image-file-resizer';

import {Popup, Form} from 'semantic-ui-react';
class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImageCountMsg: true
    };
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  handleClick(e) {
    this.refs.myFileInput.chooseFile();
  }
  resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        'WEBP',
        80,
        0,
        uri => {
          resolve(uri);
        },
        'file'
      );
    });
  handleFileSelect(e, files) {
    const uploadedImagesLength = [
      ...this.props.localGallery,
      ...this.props.gallery
    ].length;
    // Process each file
    let imageCount = files.length + uploadedImagesLength;
    var allFiles = [];
    let filesLength =
      files.length > 4 - uploadedImagesLength
        ? 4 - uploadedImagesLength
        : files.length;

    if (imageCount > 4) {
      warningToast({header: 'Du kan maksimum uploade 4 billeder'});
    }
    for (var i = 0; i < filesLength; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          file: file,
          base64: reader.result
        };
        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length == filesLength) {
          // Apply Callback function
          this.props.updateLocalGallery(allFiles.splice(0, filesLength));
        }
      }; // reader.onload
    } // for
  }

  async handleImageUpload() {
    if (this.props.localGallery.length > 0) {
      let dataTypes = this.props.localGallery.map(image => image.type);
      let signedUrls = await DataStore.getSignedUrls(dataTypes);
      //  let uploadedGallery = [];
      await this.props.localGallery.map(async (image, index) => {
        const resizedImageFile = await this.resizeFile(image.file);
        // console.log('resizedImageFile', resizedImageFile);
        //console.log('image', image);
        // await DataStore.uploadOne(image.file, signedUrls[index]);
        //resize file
        await DataStore.uploadOne(resizedImageFile, signedUrls[index]);
        //uploadedGallery.push(signedUrls[index].getURL);
      });
      let uploadedGallery = signedUrls.map(url => url.getURL);
      if (this.props.onGalleryReady) {
        this.props.onGalleryReady(uploadedGallery);
      }

      return uploadedGallery;
    }
  }

  renderImageGallery() {
    let localGallery = this.props.localGallery ? this.props.localGallery : [];
    let gallery = this.props.gallery ? this.props.gallery : [];
    let images = [...gallery, ...localGallery];
    return (
      <div className="uploaded-image-gallery">
        {this.props.localGallery.length < 4 && (
          <FileProcessor
            ref="myFileInput"
            onFileSelect={this.handleFileSelect.bind(this)}
            multiple={true}
          >
            <div
              onClick={this.handleClick.bind(this)}
              className="add-image-btn"
            >
              <Popup
                trigger={
                  <div className="pulse">
                    <img
                      loading="lazy"
                      alt="Tilføj billeder"
                      src={addIcon}
                      style={{width: '20px', height: '20px'}}
                    />
                  </div>
                }
                content={this.props.popupText || ''}
              />
              <span> Tilføj billeder</span>
            </div>
          </FileProcessor>
        )}
        {images.map((file, index) => {
          return (
            <div className="uploaded-image-item">
              <img
                loading="lazy"
                alt="Fjern billede"
                src={deleteIcon}
                className="delete-icon"
                onClick={() => {
                  file.base64
                    ? this.props.removeLocalImage(localGallery.indexOf(file))
                    : this.props.removeUploadedImage(gallery.indexOf(file));
                }}
              />

              <img
                loading="lazy"
                alt="Tilføjet billed"
                src={file.base64 || file}
              />
            </div>
          );
        })}
      </div>
    );
  }
  renderSingleImageUploader() {
    let localGallery = this.props.localGallery ? this.props.localGallery : [];
    let gallery = this.props.gallery ? this.props.gallery : [];

    const backgroundImage = (localGallery[0] || {}).base64 || gallery[0];
    let imgStyle = {
      backgroundColor: toColor('user._id'),
      backgroundImage: `url(${backgroundImage})`
    };

    return (
      <React.Fragment>
        <Form.Field label="Coverbillede" />
        <div
          style={{...imgStyle, marginTop: '10px'}}
          className="user-cover-img "
        />

        <Popup
          trigger={
            <Button
              className="upload-btn-style form-data-input"
              onClick={this.handleClick.bind(this)}
            >
              {' '}
              <Icon name="image outline" /> Upload coverbilllede
            </Button>
          }
          content={this.props.popupText || ''}
        />
        <FileProcessor
          ref="myFileInput"
          onFileSelect={this.handleFileSelect.bind(this)}
          multiple={false}
        />
      </React.Fragment>
    );
  }
  render() {
    if (this.props.type === 'single') {
      return this.renderSingleImageUploader();
    }
    return <div>{this.renderImageGallery(this.props.localGallery)}</div>;
  }
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader);

/**
 *           <button
            type="button"
            class="btn btn-primary"
            onClick={this.handleImageUpload.bind(this)}
          >
            Upload billeder
          </button>
 */
