//import * as firebase from 'firebase';
/*import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics'

import Cookies from 'js-cookie';
import store from '../redux/store';
import {SETPROVIDERID} from '../redux/reducers/loginReducer';*/
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/firestore';

import 'firebase/analytics';

import 'firebase/auth';
import Cookies from 'js-cookie';
import store from '../redux/store';
import {SETPROVIDERID} from '../redux/reducers/loginReducer';

const fbConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
};

firebase.initializeApp(fbConfig);

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    const providerId = user.providerData[0].providerId;
    store.dispatch({type: SETPROVIDERID, val: providerId});

    user.getIdToken().then(function(idToken) {
      Cookies.set('elfj', idToken, {expires: 365});
    });
  }
});
const firebaseAnalytics = firebase.analytics();
const performance = firebase.performance();
const firestore = firebase.firestore();
const clientErrorRef = firestore.collection('clientError');
const clientWarningRef = firestore.collection('clientWarning');

/*clientErrorRef
  .get()
  .then(res => {
    res.docs.map(d => {
      console.log('firebase clientErrorRef.res', d.data());
    });
  })
  .catch(er => {
    console.log(' firebase clientErrorRef er', er);
  });*/

//firebase.auth();
const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      if (user) {
        user.getIdToken().then(
          idToken => {
            resolve(idToken);
          },
          error => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  });
};

export {clientErrorRef, clientWarningRef, getIdToken, firebaseAnalytics};
export default firebase;
