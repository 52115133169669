import React, {Component} from 'react';
import YoutubeVideo from '../../reuseable/YoutubeVideo/YoutubeVideo';
import {isSafari} from 'react-device-detect';
import './Middlespot.css';
const coverPhotos = [
  '2.jpg',
  '1.jpg'
  //  '3.jpg'
];
const texts = [
  //{head: 'Tilbyd og søg hjælp til praktiske opgaver.', sub: 'Udforsk'},
  {head: 'Sidejob? ', sub: 'Find dine kunder her'},
  {head: 'Hvorfor gør det selv?', sub: 'Find hjælp her'}

  // {head:'3Hvorfor gør det selv?, ',sub:'-når du kan betale nogen til gøre det?'},
];

class Middlespot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }
  componentDidMount() {
    //this.interval = setInterval(this.nextIndex.bind(this), 4000);
  }
  componentWillUnmount() {
    //clearInterval(this.interval);
  }
  nextIndex() {
    const nextI =
      this.state.index >= texts.length - 1 ? 0 : this.state.index + 1;
    this.setState({index: nextI});
  }
  scrollDown() {
    window.scrollBy(0, window.innerHeight - 100);
  }
  render() {
    const showPipe = !isSafari; //TODO: why not? test again.

    const isSmallScreen = window.innerWidth <= 1039;
    const text = texts[this.state.index];
    return (
      <div className="middleSpot">
        <YoutubeVideo />

        {showPipe && (
          <img
            src="/images/middlespot/pipe.png"
            className="ms-pipe"
            alt="middle spot pipe"
          />
        )}
        {showPipe && (
          <img
            src="/images/middlespot/drop.png"
            className="ms-drop"
            alt="middle spot pipe"
          />
        )}
        <img
          src="/images/middlespot/movingFam.png"
          className="ms-movingfam"
          alt="middle spot moving family"
        />
        <img
          src="/images/middlespot/redBirds.png"
          className="ms-redBirds"
          alt="middle spot birds"
        />
        <img
          src="/images/middlespot/ring.png"
          className="ms-ring"
          alt="middle spot ring"
        />
        {false && (
          <img
            src="/images/middlespot/bucket.png"
            className="ms-bucket"
            alt="middle spot bucket"
          />
        )}
        <img
          src="/images/middlespot/line.png"
          className="ms-line"
          alt="middle spot line"
        />

        <div className="middelspot-text-container">
          <h1>{text.head}</h1>
          <h2 onClick={this.scrollDown}>
            {text.sub}

            <i className="material-icons material-icons-expand_more md-xlarge discover-icon ">
              expand_more
            </i>
          </h2>
        </div>
      </div>
    );
  }
}

export default Middlespot;

/**
 *    return (
      <div
        id="carouselExample1 middleSpot"
        className="carousel slide z-depth-1-half"
        data-ride="carousel"
        data-interval="6000"
      >
        <div className="carousel-inner middleSpot">

          <div className=" carousel-item  active ">
            <div
              className=" carousel-image-container carousel-image-container-A"
              style={{
                backgroundImage: `url(/images/slider/4${
                  isSmallScreen ? '_small' : ''
                }.jpg)`
              }}
            >
              <div className="hero-text-container">
                <h1>Sidejob?</h1>
                <h2 onClick={this.scrollDown}>
                  {this.state.text.sub}

                  <i className="material-icons material-icons-expand_more md-xlarge discover-icon ">
                    expand_more
                  </i>
                </h2>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div
              className=" carousel-image-container carousel-image-container-B"
              style={{backgroundImage: `url(/images/slider/2.jpg)`}}
            >
              <div className="hero-text-container">
                <h1>Har du brug for en handyman?</h1>
                <h2 onClick={this.scrollDown}>
                  Find hjælp her
                  <i className="material-icons material-icons-expand_more md-xlarge discover-icon ">
                    expand_more
                  </i>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
 */
