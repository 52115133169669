import React, {Component} from 'react';
import Middlespot from '../Middlespot/Middlespot';
import {Menu, Input, Dropdown, Icon, Segment} from 'semantic-ui-react';
import DataStore from '../../../Data/DataStore';
import {
  RemoveFilter,
  AddFilter,
  AddPostType
} from '../../../redux/reducers/feedReducer';
import {ToggleNavbarFilter} from '../../../redux/reducers/mainReducer';
import {connect} from 'react-redux';
import NavBar from '../../Navbar/Navbar';
import './Topbar.css';
import filterIcon from '../../../style/svg/filter.svg';
import {enterKeyHandler} from '../../../utils/keyHandlers';
import postTypeTranslate from '../../../utils/postTypeTranslate';
import {withRouter} from 'react-router-dom';
class Topbar extends Component {
  constructor() {
    super();
    this.state = {
      isBarsConnected: false,
      showTopBar: true
    };
  }

  componentDidMount() {
    this.listener = window.addEventListener(
      'scroll',
      this.checkScrollPosition,
      false
    );
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScrollPosition, false);
  }

  hideShowTopbar() {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = () => {
      var currentScrollPos = window.pageYOffset;

      if (prevScrollpos > currentScrollPos) {
        if (!this.state.showTopBar) {
          this.setState({showTopBar: true});
        }
      } else {
        if (this.state.showTopBar) {
          this.setState({showTopBar: false});
        }
      }
      prevScrollpos = currentScrollPos;
    };
  }

  checkScrollPosition = () => {
    if (this.props.onMainPage && this._containerDOM) {
      let containerPosition =
        this._containerDOM.getBoundingClientRect().top - 60;
      let getScroll = window.pageYOffset;

      const subHeaderTop = containerPosition + getScroll;

      if (this.state.isBarsConnected !== getScroll > subHeaderTop) {
        this.setState({isBarsConnected: getScroll > subHeaderTop});
      }
    }
  };

  getContainer = node => {
    this._containerDOM = node;
  };

  onPostTypeChange = name => {
    this.props.history.push({
      search: `?feed=${postTypeTranslate(name)}`
    });
    this.props.addPostType({
      name: 'postType',
      value: name
    });
  };
  render() {
    const isMobile = window.innerWidth <= 768;
    const filters = this.props.filters;
    return (
      <div className={'bar '}>
        {!false && (
          <NavBar
            className={
              'topbar  ' +
              (this.state.isBarsConnected ? ' topbar-connected ' : '') +
              (this.state.isBarsConnected && !this.state.showTopBar
                ? ' hide-top-bar'
                : '') +
              (isMobile ? ' mobile-topbar ' : '')
            }
            isBarsConnected={this.state.isBarsConnected}
          />
        )}

        {this.props.onMainPage && <Middlespot />}

        {this.props.onMainPage && (
          <div
            ref={this.getContainer}
            className={
              this.state.isBarsConnected ? 'container-connected' : 'container'
            }
          >
            <div
              className={
                'fliter-btn ' +
                (this.state.isBarsConnected ? ' fliter-btn-connected ' : '') +
                (isMobile ? '  fliter-btn-connected-m ' : '')
              }
              tabIndex={0}
              role="button"
              onClick={() => this.props.toggleNavbarFilter()}
              onKeyDown={event =>
                enterKeyHandler(event, () => this.props.toggleNavbarFilter())
              }
            >
              <img
                //  onClick={}
                style={{
                  width: '26px',
                  marginRight: '20px',
                  cursor: 'pointer',
                  zIndex: '100'
                }}
                alt="filter menu"
                src={filterIcon}
              />
            </div>

            <div
              className={
                'subbar ' +
                (this.state.isBarsConnected ? ' subbar-connected ' : '') +
                (isMobile ? ' subbar-connected-m ' : '')
              }
            >
              <span
                className={
                  'subbar-buttons ' +
                  (filters.postType === 'SERVICE'
                    ? 'subbar-buttons-selected'
                    : '')
                }
                onClick={() => this.onPostTypeChange('SERVICE')}
              >
                Service
              </span>

              <span
                className={
                  'subbar-buttons ' +
                  (filters.postType === 'TASK' || !filters.postType
                    ? 'subbar-buttons-selected'
                    : '')
                }
                onClick={() => this.onPostTypeChange('TASK')}
              >
                Opgaver
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    filters: state.feed.filters,
    onMainPage: state.main.onMainPage
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addPostType: value => {
      dispatch({
        type: AddPostType,
        val: value
      });
      DataStore.fetchFeed();
    },
    addFilter: value => {
      dispatch({
        type: AddFilter,
        val: value
      });
      DataStore.fetchFeed();
    },
    removeFilter: value => {
      dispatch({
        type: RemoveFilter,
        val: value
      });

      DataStore.fetchFeed();
    },
    toggleNavbarFilter: value => {
      dispatch({
        type: ToggleNavbarFilter,
        val: value
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));
