import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Form,
  Popup,
  Message,
  TextArea,
  Icon,
  Divider,
  Header,
  Segment
} from 'semantic-ui-react';
import {HandleProfileChange} from '../../redux/reducers/userReducer';
import './EditProfile.css';
import toColor from '../../utils/toColor';
import FileProcessor from '../reuseable/FileSelector/FileSelector';

class EditWorkerData extends Component {
  render() {
    const style = this.props.style ? this.props.style : {};

    const user = this.props.user;
    const backgroundImage = user.coverURL.base64 || user.coverURL;
    let imgStyle = {
      backgroundColor: toColor(user._id),
      backgroundImage: `url(${backgroundImage})`
    };
    const workerType = [
      {value: 'Freelancer', text: 'Freelancer'},
      {value: 'Virksomhed', text: 'Virksomhed'}
    ];

    return (
      <div style={{...style}}>
        <Header size="medium" as="h2">
          Arbjederprofil data
        </Header>
        <Divider />

        <Popup
          trigger={
            <Form>
              <Form.TextArea
                style={{maxWidth: '100%'}}
                label="Beskrivelse"
                placeholder="Hvad tilbyder du/I?"
                name="workerData.description"
                type="text"
                value={user.workerData.description}
                onChange={evt => this.props.handleProfileChange(evt.target)}
                className="form-data-input"
              />
            </Form>
          }
          content="Beskriv dine kompetencer"
        />
        <Form>
          <Popup
            trigger={
              <Form.Select
                className="col-12 col-md-5 worker-type-selector form-data-input"
                label="Type af arbejder"
                placeholder="Vælg type"
                options={workerType}
                value={user.workerData.type || ''}
                onChange={(e, data) => {
                  this.props.handleProfileChange({
                    name: 'workerData.type',
                    value: data.value
                  });
                }}
              />
            }
            content="Hvilken type arbejder er du?"
          />
        </Form>

        <div />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleProfileChange: value => {
      dispatch({
        type: HandleProfileChange,
        val: value
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkerData);
