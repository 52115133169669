const initialState = {
  recentAddedServices: null,
  editServiceMode: false,
  servicePageService: null,
  servicesBySameOwner: [],

  newService: {
    category: '',
    ownerType: 'FREELANCER',
    owner: null,
    title: '',
    description: '',
    gallery: [],
    localGallery: [],
    location: [],
    payment: {
      type: 'HOURLY',
      amount: ''
    }
  }
};

export const SetServicePageService = 'SetServicePageService';
export const UpdateServiceGallery = 'UpdateServiceGallery';
export const UpdateServiceLocalGallery = 'UpdateServiceLocalGallery';
export const HandleNewServiceChange = 'HandleNewServiceChange';
export const ResetNewService = 'ResetNewService';
export const SetNewService = 'SetNewService';
export const RemoveServiceLocalImage = 'RemoveLocalImage';
export const SetEditServiceMode = 'SetEditServiceMode';
export const RemoveServiceUploadedImage = 'RemoveServiceUploadedImage';
export const AddRegion = 'AddRegion';
export const RemoveRegion = 'RemoveRegion';
export const SetLocationArray = 'SetLocationArray';

export const SetNewServiceCategory = 'SetNewServiceCategory';
export const SetServicesBySameOwner = 'SetServicesBySameOwner';

export default function dispatch(state = initialState, action) {
  let newGoods = []; // make a separate copy of the array

  switch (action.type) {
    case SetServicePageService:
      return {
        ...state,
        servicePageService: action.val
      };
    case SetServicesBySameOwner:
      return {
        ...state,
        servicesBySameOwner: action.val
      };
    case SetNewServiceCategory:
      return {
        ...state,
        newService: {...state.newService, category: action.val}
      };

    case SetLocationArray:
      return {
        ...state,
        newService: {
          ...state.newService,
          location: action.val
        }
      };
    case AddRegion:
      return {
        ...state,
        newService: {
          ...state.newService,
          location: [...state.newService.location, action.val]
        }
      };
    case RemoveRegion:
      let newLocation = [...state.newService.location];

      let index = newLocation.indexOf(action.val);
      newLocation.splice(index, 1);
      return {
        ...state,
        newService: {
          ...state.newService,
          location: newLocation
        }
      };

    case SetEditServiceMode:
      return {
        ...state,
        editServiceMode: action.val
      };

    case UpdateServiceGallery:
      return {
        ...state,
        newService: {
          ...state.newService,
          gallery: [...state.newService.gallery, ...action.val]
        }
      };
    case UpdateServiceLocalGallery:
      return {
        ...state,
        newService: {
          ...state.newService,
          localGallery: [...state.newService.localGallery, ...action.val]
        }
      };
    case RemoveServiceLocalImage:
      let localImageGallery = [...state.newService.localGallery];
      localImageGallery.splice(action.val, 1);

      return {
        ...state,
        newService: {...state.newService, localGallery: localImageGallery}
      };
    case RemoveServiceUploadedImage:
      let imageGallery = [...state.newService.gallery];
      imageGallery.splice(action.val, 1);
      return {
        ...state,
        newService: {...state.newService, gallery: imageGallery}
      };

    case HandleNewServiceChange:
      let newService = {...state.newService};
      let splitetArray = action.val.name.includes('.')
        ? action.val.name.split('.')
        : [action.val.name];

      let level1 = newService[splitetArray[0]];
      let level2 = newService[splitetArray[0]][splitetArray[1]];
      let level3 = {};
      switch (splitetArray.length) {
        case 1:
          newService = {...newService, [splitetArray[0]]: action.val.value};
          break;
        case 2:
          newService = {
            ...newService,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: action.val.value
            }
          };
          break;
        case 3:
          newService = {
            ...newService,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: {
                ...level2,
                [splitetArray[2]]: action.val.value
              }
            }
          };
          break;
        case 4:
          level3 =
            newService[splitetArray[0]][splitetArray[1]][splitetArray[2]];
          newService = {
            ...newService,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: {
                ...level2,
                [splitetArray[2]]: {
                  ...level3,
                  [splitetArray[3]]: action.val.value
                }
              }
            }
          };
          break;
        default:
          break;
      }

      return {
        ...state,
        newService: newService
      };

    case ResetNewService:
      return {
        ...state,
        newService: {...initialState.newService}
      };
    case SetNewService:
      return {
        ...state,
        newService: {
          ...state.newService,
          ...action.val
        }
      };
    default:
      return state;
  }
}
