import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {SET_LOGIN_MODAL} from '../../redux/reducers/loginReducer';

function LoginRequired(props) {
  const pageName = props.pageName ? props.pageName : 'denne side';

  const isSmallScreen = window.innerWidth <= 800;

  return (
    <div className="App">
      <h1>{`Du skal logge ind for at ${pageName}!`}</h1>
      <button
        className="btn btn-warning m-2"
        onClick={() => {
          if (isSmallScreen) {
            props.history.push('/login');
          } else {
            props.setLoginModal(true);
          }
        }}
      >
        Login
      </button>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginModal: value => {
      dispatch({
        type: SET_LOGIN_MODAL,
        val: value
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginRequired));
