import React from 'react';
import './FeedbackButton.css';
import {Button} from 'semantic-ui-react';
import {useSelector} from 'react-redux';

function FeedbackButton() {
  const burgerMenuIsVisible = useSelector(state => state.main.showBurgerMenu);
  if (burgerMenuIsVisible) {
    return <div />;
  }
  return (
    <Button
      className="feedback-container Button__feedback"
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        window.open(
          'https://docs.google.com/forms/d/e/1FAIpQLSfAv_60YC_qe-nGo8KJOHWOgFL5_bZo_O2DwDhnpnQ94TvW4w/viewform?usp=sf_link',
          '_blank'
        );
      }}
    >
      Giv os feedback
    </Button>
  );
}

export default FeedbackButton;
