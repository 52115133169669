import axios from 'axios';
import store from '../redux/store';
import ActionFactory from '../redux/actions/mainActions';
import {SET_IS_LOADING, SET_LOGIN_MODAL} from '../redux/reducers/loginReducer';
import {SetSimilarTasks} from '../redux/reducers/taskReducer';
import {SetServicesBySameOwner} from '../redux/reducers/serviceReducer';
import {
  SetUserTasks,
  SetUserServices,
  SetUserFavourites,
  SetUserPopulatedFavourites,
  ResetUserData
} from '../redux/reducers/userReducer';
import {ResetMessages} from '../redux/reducers/messageReducer';

import MessageFacade from './MessageFacade';
import SocketFacade from './SocketFacade';
import {successToast, warningToast} from '../utils/toast';
import {errorLog} from '../utils/logger';
import firebase, {getIdToken} from './firebase';

import Cookies from 'js-cookie';

const queryString = require('query-string');

const api = process.env.REACT_APP_SERVER_HOST;
/**
 * All calls to the server is made through DataStore
 */
class DataStore {
  /**
   * fetches a list of all available teams
   */
  constructor() {
    //this.getUserByID("5a102aeb78a62649d0b31090");
  }

  fetchWithFilters(filters) {
    //update filters in reducer
    //fetchTasks
  }
  fetchSimilarTasks(filters) {
    let page = 1;

    let query =
      '/?' +
      queryString.stringify({page: page}) +
      '&' +
      queryString.stringify(filters);

    let url = '/api/post/all' + query;

    axios
      .get(url)
      .then(response => {
        let tasks = response.data.docs;
        store.dispatch({
          type: SetSimilarTasks,
          val: tasks
        });
      })
      .catch(error => {
        errorLog('DataStore.fetchSimilarTasks', error);
      });
  }
  fetchFeed() {
    const feedType =
      store.getState().feed.filters.postType === 'SERVICE'
        ? 'serviceFeed'
        : 'taskFeed';

    let page = store.getState().feed[feedType].page;

    let filters = queryString.stringify(store.getState().feed.filters);
    let query = '/?' + queryString.stringify({page: page}) + '&' + filters;
    let url = '/api/post/all' + query;
    axios
      .get(url)
      .then(response => {
        let feedPosts = response.data.docs;
        if (feedType === 'taskFeed') {
          store.dispatch(
            ActionFactory.onTaskFeedResponse({
              feed: feedPosts,
              page: parseInt(response.data.page, 10) + 1,
              pages: response.data.pages,
              total: response.data.total
            })
          );
        } else {
          store.dispatch(
            ActionFactory.onServiceFeedResponse({
              feed: feedPosts,
              page: parseInt(response.data.page, 10) + 1,
              pages: response.data.pages,
              total: response.data.total
            })
          );
        }
      })
      .catch(error => {
        errorLog('DataStore.fetchFeed', error);
      });
  }

  fetchTask(id) {
    return axios.get('/api/task/' + id);
  }
  fetchTaskTaskPageTask(id) {
    this.fetchTask(id)
      .then(response => {
        let task = response.data;
        store.dispatch(ActionFactory.setTaskPageTask(task));
        this.fetchSimilarTasks({
          category: task.category || '',
          postType: 'TASK',
          limit: 4
        });
      })
      .catch(error => {
        errorLog('DataStore.fetchTaskTaskPageTask', error);
      });
  }
  fetchService(id) {
    //error catching?
    return axios.get('/api/services/' + id);
  }
  fetchServicePageService(id) {
    this.fetchService(id)
      .then(response => {
        let service = response.data;
        store.dispatch(ActionFactory.setServicePageService(service));
        this.getUserProfileServices(service.owner._id);
      })
      .catch(error => {
        errorLog('DataStore.fetchServicePageService', error);
      });
  }

  deleteUser(idToken) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/user/delete', {
          data: {idToken: idToken}
        })
        .then(res => {
          if (res.status === 200) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(er => {
          errorLog('DataStore.deleteUser', er);
        });
    });
  }
  handleSignOut() {
    try {
      firebase
        .auth()
        .signOut()
        .catch(error => errorLog('DataStore.firebase.handleSignOut', error));

      Cookies.remove('elfj');

      store.dispatch({
        type: ResetUserData
      });

      store.dispatch({
        type: ResetMessages
      });
    } catch (error) {
      errorLog('DataStore.handleSignOut.error', error);
    }
  }

  /**
   * Check if elilla user exists
   *
   */
  async getUserByMail({idToken, mail}) {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await axios.post('/api/login/bymail', {
          //change to api/user/bymail
          idToken,
          mail
        });

        const userExists = resp.data.userExists;
        resolve(userExists);
      } catch (error) {
        errorLog('DataStore.getUserByMail', error);

        reject();
      }
    });
  }
  handleSignIn(idToken) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/login', {
          idToken
        })
        .then(response => {
          try {
            let userData = response.data;
            const statusCode = response.status;
            if (statusCode === 204) {
              //status 204 : user does not exists in elillaDB.
              //we delete the user from firebase if it does not exists in elillaDB

              const user = firebase.auth().currentUser;
              if (user) {
                user.delete();
                Cookies.remove('elfj');
                store.dispatch({
                  type: ResetUserData
                });

                store.dispatch({
                  type: ResetMessages
                });
              }
              reject('User does not exist in DB.');
              //user does not exist
              //delete user?
            } else {
              store.dispatch(
                ActionFactory.setUserData({...userData, isLoggedIn: true})
              );
              SocketFacade.connect(userData._id);

              MessageFacade.loadConversations();
              store.dispatch({type: SET_IS_LOADING, val: false});
              store.dispatch({type: SET_LOGIN_MODAL, val: false});
              resolve(userData);
            }
          } catch (error) {
            //log out of firebase
            this.handleSignOut();
            warningToast({
              header: 'Kunne ikke logge ind.',
              message: 'Bruger findes ikke.'
            });
            store.dispatch({type: SET_IS_LOADING, val: false});
            store.dispatch({type: SET_LOGIN_MODAL, val: false});
            reject('User does not exist.');
          }
        })
        .catch(error => {
          //  this.handleSignOut()

          warningToast({
            header: 'Kunne ikke logge ind.',
            message: 'Bruger findes ikke.'
          });
          store.dispatch({type: SET_IS_LOADING, val: false});
          store.dispatch({type: SET_LOGIN_MODAL, val: false});
          errorLog('DataStore.handleSignIn', error);
          reject('Something went wrong.');
        });
    });
  }
  createElillaUser(user) {
    axios
      .post('/api/login/new', user)
      .then(response => {
        let userData = response.data;
        store.dispatch(
          ActionFactory.setUserData({...userData, isLoggedIn: true})
        );
        store.dispatch({type: SET_IS_LOADING, val: false});
        SocketFacade.connect(userData._id);
      })
      .catch(error => {
        errorLog('DataStore.createElillaUser', error);
      });
  }

  updateUserProfile = async user => {
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();
    let {isLoggedIn, ...newUserInfo} = user;

    axios.post('/api/user/update', {...newUserInfo, idToken}).then(response => {
      let userData = response.data;
      store.dispatch(
        ActionFactory.setUserData({...userData, isLoggedIn: true})
      );
    });
  };

  async getSignedUrls(fileTypes) {
    //fileTypes: ["image/jpeg","image/jpeg"]
    //fileTypes = ["image/jpeg","image/jpeg"];
    let response = await axios.post('/api/task/imageuploadlinks', fileTypes);
    return response.data;
  }
  uploadOne(newImage, URLObject) {
    const xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {
      xhr.open('PUT', URLObject.postURL);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(URLObject.getURL);
          } else {
            errorLog('DataStore.getSignedUrls', 'Could not upload image.');

            reject();
          }
        }
      };
      xhr.send(newImage);
    });
  }

  postNewTask(task) {
    return axios.post('/api/task/new', task);
  }
  postNewService(service) {
    return axios.post('/api/services/new', service);
  }
  getUserTasks(userID) {
    axios
      .get('/api/task/by-user/' + userID)
      .then(response => {
        let userTasks = response.data;
        store.dispatch({type: SetUserTasks, val: userTasks});
      })
      .catch(error => {
        errorLog('DataStore.getUserTasks', error);
      });
  }
  getUserServices(userID) {
    axios
      .get('/api/services/by-user/' + userID + '/8')
      .then(response => {
        let userServices = response.data;
        store.dispatch({type: SetUserServices, val: userServices});
      })
      .catch(error => {
        errorLog('DataStore.getUserServices', error);
      });
  }
  getUserProfileServices(userID) {
    axios
      .get('/api/services/by-user/' + userID + '/8') //limit to 8 posts
      .then(response => {
        let userServices = response.data;
        store.dispatch({type: SetServicesBySameOwner, val: userServices});
      })
      .catch(error => {
        errorLog('DataStore.getUserProfileServices', error);
      });
  }
  async deleteTaskById(taskID) {
    try {
      await axios.delete('/api/task/' + taskID);
      this.getUserTasks(store.getState().user.userData._id);
      successToast({header: 'Opgaven blev slettet'});
    } catch (er) {}
  }
  setTaskIsactive(task) {
    let updatedTask = {...task, active: !task.active};
    this.postNewTask(updatedTask).then(res => {
      if (res.status === 200) {
        store.dispatch(ActionFactory.setTaskPageTask(updatedTask));
        this.getUserTasks(store.getState().user.userData._id);
      }
    });
  }

  getUserByID(id) {
    return axios.get('/api/user/' + id);
  }

  fetchFavourites = async () => {
    //todo are we catching the error ?
    const userID = store.getState().user.userData._id;
    axios.get('/api/user/' + userID + '/favourite').then(res => {
      const favourites = res.data;

      if (favourites.favouriteTaks && favourites.favouriteServices) {
        store.dispatch({type: SetUserPopulatedFavourites, val: favourites});
      }
    });
  };

  addToFavourites = async postItemID => {
    //todo: error catching?
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();
    const userID = store.getState().user.userData._id;
    axios
      .put('/api/user/favourite', {
        idToken,
        postItemID,
        userID
      })
      .then(res => {
        const favourites = res.data;
        store.dispatch({type: SetUserFavourites, val: favourites});
      });
  };
  removeFromFavourites = async postItemID => {
    //todo: error catching?
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();
    const userID = store.getState().user.userData._id;
    axios
      .delete('/api/user/favourite', {
        data: {idToken, postItemID, userID}
      })
      .then(res => {
        const favourites = res.data;
        store.dispatch({type: SetUserFavourites, val: favourites});
      });
  };
}

export default new DataStore();
