import React, {Component} from 'react';
//import ActionFactory from '../../redux/actions/mainActions';
import './PostItem.css';
import toColor from '../../utils/toColor';
import {connect} from 'react-redux';

import {Card, Icon, Image} from 'semantic-ui-react';
import Moment from 'moment';
import DataStore from '../../Data/DataStore';
import {warningToast, successToast} from '../../utils/toast';
import {errorLog} from '../../utils/logger';
import {withRouter} from 'react-router-dom';

class PostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {isFavourite: false};
  }
  componentWillMount() {
    this.setState({
      isFavourite: this.props.user.favourites.includes(this.props.postItem._id)
    });
  }

  componentDidUpdate() {
    const newValue = this.props.user.favourites.includes(
      this.props.postItem._id
    );
    if (newValue !== this.state.isFavourite) {
      this.setState({isFavourite: newValue});
    }
  }
  formatServiceLocation(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
  handleAddFavourite() {
    if (this.props.user.isLoggedIn) {
      if (this.state.isFavourite) {
        DataStore.removeFromFavourites(this.props.postItem._id);
        this.setState({isFavourite: false});
        successToast({header: 'Fjernet fra favoritter.'});
      } else {
        DataStore.addToFavourites(this.props.postItem._id);
        this.setState({isFavourite: true});
        successToast({header: 'Tilføjet til favoritter.'});
      }
    } else {
      warningToast({
        header: 'Du skal logge ind for at tilføje favoritter'
      });
    }
  }

  handleClick = e => {
    const name = e.target.getAttribute('elementname');

    if (name === 'addToFavs') {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let postItem = this.props.postItem;
      // this.props.history.push('/')
      this.props.history.push('/' + this.props.postType + '/' + postItem._id);
    }
  };
  render() {
    let postItem = this.props.postItem;
    const index = this.props.index;
    try {
      return (
        <div
          key={postItem._id}
          aria-label="Link til opslag side"
          onClick={this.handleClick}
          className=" post-link col-12 col-sm-6 col-md-4 col-lg-3 mt-5 d-flex d-sm-block justify-content-center"
          tabIndex={0}
          role="button"
        >
          <Card
            className="post-item-container"
            // to={'/' + this.props.postType + '/' + postItem._id}
          >
            <div
              className="imageContainer"
              style={{background: toColor(postItem._id || ' ')}}
            >
              {postItem.gallery[0] && (
                <Image
                  src={postItem.gallery[0]}
                  alt="opslag billede"
                  loading={index > 10 ? 'lazy' : 'eager'}
                />
              )}
            </div>
            <Card.Content>
              <h1 className="postitem-label">{postItem.category}</h1>
              <div
                elementname="addToFavs"
                className={
                  'postitem-fav-label right pointer' +
                  (this.state.isFavourite ? ' fav-label' : '')
                }
                onClick={() => this.handleAddFavourite()}
              >
                <Icon
                  elementname="addToFavs"
                  size="large"
                  name={'heart ' + (this.state.isFavourite ? '' : 'outline')}
                  color={this.state.isFavourite ? 'red' : 'grey'}
                />
              </div>
              <Card.Header
                className="post-item-header"
                //as={Link}
                // to={'/' + this.props.postType + '/' + postItem._id}
              >
                {' '}
                {postItem.title}
              </Card.Header>
              <Card.Meta>{Moment(postItem.created_at).fromNow()}</Card.Meta>
              <Card.Description className="card-description">
                {postItem.description}
              </Card.Description>
            </Card.Content>

            <Card.Content extra className="location-container">
              <Icon name="location arrow" />
              {postItem?.regions?.length > 0
                ? postItem.regions
                    .map(reg => reg.charAt(0) + reg.toLowerCase().slice(1))
                    .join(', ')
                : postItem.location.cityName ||
                  (postItem.location.length > 1
                    ? postItem.location[0] +
                      ', ' +
                      postItem.location[0].substring(0, 3) +
                      '..'
                    : this.formatServiceLocation(postItem.location[0])) ||
                  ''}
            </Card.Content>
          </Card>
        </div>
      );
    } catch (er) {
      errorLog('PostItem', er);
      return null;
    }
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostItem));
