import {
  firebaseAnalytics,
  clientWarningRef,
  clientErrorRef
} from '../Data/firebase';
import {
  browserName,
  osName,
  mobileVendor,
  deviceType
} from 'react-device-detect';

export const errorLog = (path, errObj) => {
  // firebaseAnalytics.logEvent(path, obj); //todo add date?
  if (
    process.env.NODE_ENV != 'production' ||
    process.env.REACT_APP_URL.includes('elilla') //maybe not, since we have two different errolog enviorments
  ) {
    // console.log('Error', {path: path, error: errObj});
    return;
  }
  errObj = JSON.stringify(errObj, Object.getOwnPropertyNames(errObj));
  clientErrorRef.add({
    osName: osName,
    browserName: browserName,
    mobileVendor: mobileVendor,
    deviceType: deviceType,
    errorObject: errObj,
    path: path,
    timestamp: new Date()
    // date: new Date()

    //userID: "",
  });
};
export const warningLog = (path, obj) => {
  if (
    process.env.NODE_ENV != 'production' ||
    process.env.REACT_APP_URL.includes('elilla')
  ) {
    return;
  }

  clientWarningRef.add({
    osName: osName,
    browserName: browserName,
    mobileVendor: mobileVendor,
    deviceType: deviceType,
    warningObject: obj,
    path: path,
    timestamp: new Date()
    //userID: "",
  });
  if (process.env.NODE_ENV != 'production') {
    console.error('Warn', path, obj);
  }
};
