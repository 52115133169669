import React from 'react';
import './Navbar.css';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import SignUp from '../Login/SignUp';
import LoginModal from '../Login/Modal';
import {connect} from 'react-redux';
import {SET_LOGIN_MODAL} from '../../redux/reducers/loginReducer';
//import {ResetMessages} from '../../redux/reducers/messageReducer';
//import {ResetUserData} from '../../redux/reducers/userReducer';
import DataStore from '../../Data/DataStore';
import filterIcon from '../../style/svg/filter.svg';
import {
  setNewPostModalOpen,
  setNewPostModalType,
  ToggleNavbarFilter,
  ToggleBurgerMenu
} from '../../redux/reducers/mainReducer';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import {Menu, Button, Icon, Dropdown, Label} from 'semantic-ui-react';
//import * as firebase from 'firebase';
import firebase, {firebaseAnalytics} from '../../Data/firebase';
//import Cookies from 'js-cookie';
import {successToast} from '../../utils/toast';
//import {errorLog} from '../../utils/logger';
import {withRouter} from 'react-router-dom';

export class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onOpenSignIn = () => {
    this.props.setLoginModal(true);
  };

  handleItemClick = (e, {name}) => this.setState({activeItem: name});
  handleSignBtn() {
    firebaseAnalytics.logEvent('login_modal');
    this.onOpenSignIn();
  }
  handleSignOut() {
    /* firebase
      .auth()
      .signOut()
      .catch(error => errorLog('Navbar.handleSignOut', error));
    Cookies.remove('elfj');

    this.props.signOut();*/

    DataStore.handleSignOut();
    successToast({header: 'Du er logget ud.'});
  }

  renderProfileDropdown() {
    const photoURL = this.props.user.userData.photoURL;
    const thumbnail = (
      <UserThumbnail
        name={this.props.user.userData.name}
        src={photoURL.base64 || photoURL}
        enableLink={false}
        nameOnRight
        navbar
        size={30}
        type="hori"
        userId={this.props.user.userData._id}
      />
    );

    return (
      <Dropdown
        icon={false}
        trigger={thumbnail}
        basic
        pointing="top right"
        className="icon z-999"
        id="p´logindropdown"
      >
        <Dropdown.Menu className="z-999">
          <Dropdown.Header
            content="Min profil"
            as={Link}
            to="/profil"
            className="item"
          />
          <Dropdown.Header
            content="Mine annoncer"
            as={Link}
            to="/profil/annoncer"
            className="item"
          />
          <Dropdown.Header
            content="Favoritter"
            as={Link}
            to="/profil/favoritter"
            className="item"
          />
          <Dropdown.Header
            content="Indstillinger"
            as={Link}
            to="/profil/rediger"
            className="item"
          />

          <Dropdown.Divider />
          <Dropdown.Header
            className="item"
            content="Log ud"
            onClick={() => this.handleSignOut()}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  handleLogoClick() {
    const {pathname} = this.props.history.location;
    if (pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      this.props.history.push('/');
    }
  }
  render() {
    const {activeItem} = this.state;
    const pathname = window.location.pathname;
    const profileDropdown = this.renderProfileDropdown();
    const logoSrc = '/images/logo/7.png';
    const imgRation = 269 / 570;
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      return (
        <div>
          {
            <div className={'mb-5 w-100 ' + this.props.className}>
              <div className="m-0" style={{borderBottom: 0}}>
                <Menu borderless size="big" widths="3">
                  <Menu.Item position="center">
                    <div
                      className="navbar-link-style "
                      //   to="/"
                      onClick={this.handleLogoClick.bind(this)}
                    >
                      {
                        <img
                          className="topbar-logo-image mobile-topbar-logo"
                          width={100}
                          height={100 * imgRation}
                          src={logoSrc}
                          alt="Heelp.dk logo"
                        />
                      }
                    </div>
                  </Menu.Item>
                  <Menu.Item></Menu.Item>

                  <Menu.Item>
                    <Icon
                      className="burger-menu-icon"
                      size="large"
                      onClick={() => this.props.toggleBurgerMenu()}
                      name="bars"
                    />
                  </Menu.Item>
                </Menu>
              </div>
            </div>
          }
        </div>
      );
    }
    //url activeItem
    return (
      <React.Fragment>
        <LoginModal />

        <div className={' w-100 ' + this.props.className || ''}>
          <div className="mb-0" style={{borderBottom: 0}}>
            <Menu stackable borderless size="big">
              <Menu.Item>
                <div
                  className="navbar-link-style "
                  // to="/"
                  //  onClick={() => window.scrollTo(0, 0)}
                  onClick={this.handleLogoClick.bind(this)}
                >
                  {
                    <img
                      className="topbar-logo-image"
                      src={logoSrc}
                      alt="praktiske opagaver logo"
                    />
                  }
                </div>
              </Menu.Item>

              {/*   <Menu.Item>
                <Search size="mini" />
           </Menu.Item>*/}

              <Menu.Menu position="right">
                <Menu.Item
                  className="mt-0"
                  name="newservice"
                  // active={activeItem === 'newservice'}
                  //  active={pathname.includes('/om')}
                  onClick={this.handleItemClick}
                  as={Link}
                  to="/om"
                >
                  Om
                </Menu.Item>

                <Menu.Item
                  name="newtask"
                  // active={activeItem === 'newtask'}
                  onClick={this.handleItemClick}
                  className="mb-0"
                  as={Link}
                  to="/opret/opgave"
                >
                  Opret opgave
                </Menu.Item>

                <Menu.Item
                  className="mt-0"
                  name="newservice"
                  onClick={this.handleItemClick}
                  as={Link}
                  to="/opret/service"
                >
                  Tilbyd service
                </Menu.Item>

                <Menu.Item
                  name="messages"
                  //     active={pathname.includes('beskeder')}
                  onClick={this.handleItemClick}
                  className="mb-0"
                  as={Link}
                  to="/beskeder"
                >
                  Beskeder
                  {this.props.totalUnread > 0 && (
                    <Label circular color="red" className="notification-color">
                      {this.props.totalUnread}
                    </Label>
                  )}
                </Menu.Item>

                {this.props.user.userData.isLoggedIn ? (
                  <Menu.Item
                    name="sign-in"
                    //as={Button}
                    // active={activeItem === 'sign-in'}
                  >
                    {profileDropdown}
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    name="sign-in"
                    as={Button}
                    role="button"
                    style={{fontSize: '1.14285714rem'}}
                    //todo accesbility fix. RN. the item is not focusable.
                    // active={activeItem === 'sign-in'}
                    onClick={() => this.handleSignBtn()}
                  >
                    {this.props.user.userData.isLoggedIn ? 'Log ud' : 'Log in'}
                  </Menu.Item>
                )}
              </Menu.Menu>
            </Menu>
          </div>
        </div>
        <div className="navbar-placeholder" />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginModal: state.login.loginModal,
    user: state.user,
    showFilterMenu: state.main.showFilterMenu,
    totalUnread: state.chats.totalUnread
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginModal: value => {
      dispatch({
        type: SET_LOGIN_MODAL,
        val: value
      });
    },
    toggleNavbarFilter: value => {
      dispatch({
        type: ToggleNavbarFilter,
        val: value
      });
    },
    toggleBurgerMenu: value => {
      dispatch({
        type: ToggleBurgerMenu,
        val: value
      });
    },

    openModal: value => {
      dispatch({
        type: setNewPostModalType,
        val: value
      });
      dispatch({
        type: setNewPostModalOpen,
        val: true
      });
    },
    setNewPostModalType: value => {
      dispatch({
        type: setNewPostModalType,
        val: value
      });
    }
    /*  signOut() {
      dispatch({
        type: ResetUserData
      });
      dispatch({
        type: ResetMessages
      });
    }*/
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
