import React, {Component} from 'react';
import DataStore from '../../Data/DataStore';
import './Login.css';
import Loading from '../reuseable/Loading/Loading';
//import * as firebase from 'firebase';
import firebase from '../../Data/firebase';

import {errorToast, successToast, warningToast} from '../../utils/toast';
import {errorLog} from '../../utils/logger';
import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';
import {compose} from 'redux';

import {
  SET_IS_LOADING,
  SET_LOGIN_MODAL
} from '../../redux/reducers/loginReducer';

import {SetAfterLoginModalOpen} from '../../redux/reducers/mainReducer';
import {Button, Form, Popup, Message} from 'semantic-ui-react';
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastName: '',
      mail: '',
      mailconfirm: '',
      phone: '',
      password: '',
      passwordConfirmed: null,
      signUpReady: false,
      emailValidated: false,
      emailConfirmed: false,
      passwordValidated: false,
      policiesAgreed: false,
      showError: false,
      error: {
        isError: false,
        message: ''
      },
      firstSubmitAttemptUsed: false
    };
  }

  /*
  creates a new account in firebase if email does'nt already exist and callas _createElillaUser 
  
  */

  _handleCreateAccount = async () => {
    const {
      mail,
      mailconfirm,
      passwordConfirmed,
      passwordValidated,
      emailValidated,
      phone,
      name,
      lastName
    } = this.state;

    const nameValidated = name && name.length > 0;
    const lastnameValidated = lastName && lastName.length > 0;

    if (
      nameValidated &&
      lastnameValidated &&
      emailValidated &&
      passwordValidated &&
      mail.localeCompare(mailconfirm) === 0
    ) {
      this.props.setLoading(true);

      try {
        let res = await firebase
          .auth()
          .createUserWithEmailAndPassword(mail, passwordConfirmed);
        let idToken = await res.user.getIdToken();
        const elillaUserWithSameMail = await DataStore.getUserByMail({
          idToken,
          mail: mail
        });

        if (elillaUserWithSameMail) {
          //delete firebase user firebase.auth().currentUser;

          warningToast({
            header: 'Kunne ikke logge ind',
            message: `Email: ${mail} bruges i en anden konto.`,
            autoClose: 15000
          });
        } else {
          let newUser = {name, lastName, phone, mail, idToken};

          await DataStore.createElillaUser(newUser);
          //close modal
          this.props.setLoginModal(false);

          // Show afterlogin modal SetAfterLoginModalOpen
          setTimeout(() => {
            this.props.setAfterLoginModal(true);
          }, 2000);
          successToast({header: 'Velkommen til Heelp.dk!'});
        }
        if (this.props.fromLoginPage) {
          this.props.history.push('/'); // Redirect to the main page. usually from mobile view
        }
      } catch (er) {
        this.setState({error: {isError: true, message: er.message}});
        this.props.setLoading(false);
        let toastMessage =
          'auth/email-already-in-use' === er.code
            ? 'Der findes allerrede en bruger med denne e-mail.'
            : er.message;
        errorToast({header: toastMessage});
        errorLog('_handleCreateAccount.catch', er);
      }
    } else {
      if (
        !(
          mail
            .toLocaleLowerCase()
            .localeCompare(mailconfirm.toLocaleLowerCase()) === 0
        )
      ) {
        errorToast({header: 'Email er ikke ens.'});
      }
      if (!passwordValidated) {
        errorToast({header: 'Password er ikke ens. Prøv igen.'});
      }
      if (!emailValidated) {
        errorToast({header: 'Skriv en gyldig email'});
      }

      if (!nameValidated) {
        errorToast({header: 'Skriv dit navn.'});
      }
      if (!lastnameValidated) {
        errorToast({header: 'Skriv dit efternavn.'});
      }
      this.setState({firstSubmitAttemptUsed: true});
    }
  };
  validateEmailField() {
    let re = /\S+@\S+\.\S+/;
    this.setState({emailValidated: re.test(this.state.mail)});
  }

  validatePassword(passwordConfirmed) {
    let passwordValidation =
      this.state.password.localeCompare(passwordConfirmed) === 0 ? true : false;
    this.setState({passwordValidated: passwordValidation});
  }

  validadteConfirmMail() {
    let confirmed = this.state.mail === this.state.confimrMail;
    this.setState({emailConfirmed: confirmed});
  }

  render() {
    const isSmallScreen = window.innerWidth <= 800;
    if (this.props.isLoading) {
      return <Loading fullpage={true} />;
    }
    //let confimrMailLength = this.state.mailconfirm.length;
    return (
      <div className=" card-body container">
        <Form>
          <Form.Group widths="equal">
            <Popup
              trigger={
                <Form.Input
                  label="Fornavn"
                  placeholder="Fornavn"
                  name="name"
                  type="text"
                  onChange={event => this.setState({name: event.target.value})}
                  value={this.state.name}
                />
              }
              content="Skriv dit fornavn."
            />

            <Popup
              trigger={
                <Form.Input
                  label="Efternavn"
                  placeholder="Efternavn"
                  name="Efternavn"
                  type="text"
                  onChange={event =>
                    this.setState({lastName: event.target.value})
                  }
                  value={this.state.lastName}
                />
              }
              content="Skriv dit efternavn."
            />
          </Form.Group>
        </Form>
        <Form>
          <Popup
            trigger={
              <Form.Input
                onFocus={() => {
                  this.setState({emailValidated: true});
                }}
                onBlur={() => this.validateEmailField()}
                label="E-mail adresse"
                placeholder="E-mail Addresse"
                name="mail"
                type="text"
                required
                onChange={event => {
                  //this.setState({ emailValidated: true });

                  this.setState({mail: event.target.value});
                  this.validadteConfirmMail();
                }}
                className={
                  !(this.state.emailValidated || this.state.mail.length === 0)
                    ? 'form-input-error'
                    : ''
                }
                value={this.state.mail}
              />
            }
            content="Skriv din email."
          />

          <Popup
            trigger={
              <Form.Input
                onFocus={() => {
                  // this.setState({emailValidated: true});
                }}
                onBlur={() => this.validateEmailField()}
                label="Bekræft E-mail adresse"
                placeholder="Bekræft E-mail Addresse"
                name="mailconfirm"
                type="text"
                required
                onChange={event => {
                  //this.setState({ emailValidated: true });

                  this.setState({mailconfirm: event.target.value});
                  //this.validadteConfirmMail()
                }}
                className={
                  !(
                    this.state.mailconfirm.length === 0 ||
                    this.state.mailconfirm
                      .toLocaleLowerCase()
                      .localeCompare(this.state.mail.toLocaleLowerCase()) === 0
                  )
                    ? 'form-input-error'
                    : ''
                }
                value={this.state.mailconfirm}
              />
            }
            content="Skriv din email."
          />
        </Form>
        <Form>
          {/*     <Form.Input
          className={!(this.state.emailConfirmed || this.state.confimrMail.length===0)?'form-input-error':''}

          onFocus={()=>{
            this.setState({ emailConfirmed: true });
          }}
          onBlur={()=>{
            this.validadteConfirmMail()

          }}
            fluid
            label="Bekræft email"
            placeholder="Email"
            name="confimrMail"
            type="text"
            onChange={event => this.setState({ confimrMail: event.target.value })}
            value={this.state.confimrMail}
          />
        */}

          <Popup
            trigger={
              <Form.Input
                fluid
                label="Password"
                placeholder="Password"
                name="password"
                type="password"
                required
                onChange={event => {
                  this.setState({password: event.target.value});
                }}
                value={this.state.password}
              />
            }
            content="Skriv et password"
          />

          {/*Error: placeholder="Password"
            name="password"
            type="password"
            onChange={event => {
              this.setState({ password: event.target.value });
            }}
            value={this.state.password}*/}

          <Message
            error
            size="small"
            header="Action Forbidden"
            content="Kodeordet skal bestå af mere end 6 tegn"
          />

          <Popup
            trigger={
              <Form.Input
                fluid
                label="Bekræft password"
                placeholder="Confirm Password"
                name="passwordconfirm"
                type="password"
                required
                value={this.state.passwordConfirmed}
                onChange={event => {
                  this.setState({passwordConfirmed: event.target.value});
                  this.validatePassword(event.target.value);
                }}
              />
            }
            content="Skriv dit password igen"
          />
          {/*this.state.passwordValidated || !this.state.passwordConfirmed*/}

          <Message
            error
            size="small"
            header="Action Forbidden"
            content="Kodeordet matcher ikke."
          />
          <div className="accept-policy-box mt-4 mb-5">
            <Form.Checkbox
              onChange={(event, data) => {
                this.setState({policiesAgreed: data.checked});
              }}
              value={this.state.policiesAgreed}
            />
            <p>Jeg accepterer&nbsp;</p>{' '}
            <a href="/brugerbetingelser">vilkår og betingelser.</a>
          </div>
        </Form>

        <Button
          className="success-button"
          fluid
          onClick={() =>
            this.state.policiesAgreed
              ? this._handleCreateAccount()
              : errorToast({
                  header: 'Du skal acceptere betingelserne for at fortsætte'
                })
          }
        >
          Opret bruger
        </Button>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading
  };
}
const mapDispatchToProps = dispatch => {
  return {
    setLoginModal: value => {
      dispatch({
        type: SET_LOGIN_MODAL,
        val: value
      });
    },
    setLoading: value => {
      dispatch({
        type: SET_IS_LOADING,
        val: value
      });
    },
    setAfterLoginModal: value => {
      dispatch({
        type: SetAfterLoginModalOpen,
        val: value
      });
    }
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
