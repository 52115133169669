import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SetNewTask, SetEditTaskMode} from '../../redux/reducers/taskReducer';
import ServiceDetail from '../ServiceDetail/ServiceDetail';

class ServicePage extends Component {
  /*onEditTaskClick() {
    this.props.setEditTaskMode(true);

    this.props.setNewTask(this.props.task);
    //  let path = this.props.task.;
  }*/

  render() {
    let id = this.props.match.params.id; //get id from params
    return <ServiceDetail id={id} />;
  }
}

function mapStateToProps(state) {
  return {
    service: state.service.servicePageService
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setNewTask: value => {
      dispatch({
        type: SetNewTask,
        val: value
      });
    },
    setEditTaskMode: value => {
      dispatch({
        type: SetEditTaskMode,
        val: value
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);
