import React, {Component} from 'react';
import {connect} from 'react-redux';
import './ConversationContainer.css';
import moment from 'moment';
import MessageFacade from '../../Data/MessageFacade';
import {Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import Message from './Message';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
class ConversationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {showMorePressed: false};
  }
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd && !this.state.showMorePressed) {
      this.messagesEnd.scrollIntoView({behavior: 'instant'});
    }
  };

  loadMore() {
    MessageFacade.loadMessages(this.props.selectedConversationId);
    this.setState({showMorePressed: true});
  }
  renderMessages = () => {
    const ownID = this.props.user._id; //'5c4cc27c1a47aa1ad8f2a3c9';
    const selectedConversation = this.props.conversations[
      this.props.selectedConversationId
    ];

    let hasMore;
    let messages;
    if (selectedConversation && selectedConversation.messages.messageList) {
      messages = selectedConversation.messages.messageList
        .map((msg, index) => {
          let previous = selectedConversation.messages.messageList[index + 1]; //prev

          /*if (!(previous && previous.author) || !(msg && msg.author)) {
            return;
          }*/
          /*let isMine =
            previous && msg && previous.author && msg.author
              ? ownID === msg.author._id
              : false;*/

          let isMine = msg.author && ownID == msg.author._id;
          let showTimestamp = false;
          let startsSequence = true;
          let endsSequence = true;
          let currentMoment = moment(msg.createdAt);
          let noMarginBottom = false;
          let marginTop = false;

          if (previous) {
            let previousMoment = moment(previous.createdAt);
            let previousDuration = moment.duration(
              currentMoment.diff(previousMoment)
            );
            let prevBySameAuthor =
              previous && msg && previous.author && msg.author
                ? previous.author._id === msg.author._id
                : false;

            if (previousDuration.as('hours') >= 1) {
              showTimestamp = true;
            }
            if (prevBySameAuthor && showTimestamp) {
              noMarginBottom = false;
            }
            if (!prevBySameAuthor) {
              marginTop = true;
            }
          }
          return (
            <Message
              key={index}
              isMine={isMine}
              // startsSequence={startsSequence}
              // endsSequence={endsSequence}
              showTimestamp={showTimestamp}
              content={msg.content}
              timeStamp={msg.createdAt}
              noMarginBottom={noMarginBottom}
              marginTop={marginTop}
            />
          );
        })
        .reverse();
    }

    return messages;
  };

  render() {
    const isSmallScreen = window.innerWidth <= 769;
    const selectedConversation = this.props.conversations[
      this.props.selectedConversationId
    ];

    if (
      selectedConversation &&
      selectedConversation.unread &&
      selectedConversation.unread[this.props.user._id] > 0
    ) {
      MessageFacade.setConversationIsRead(
        this.props.user._id,
        this.props.selectedConversationId
      );
    }

    if (selectedConversation && selectedConversation.messages) {
      const messages = this.renderMessages();
      const hasMore =
        selectedConversation.messages.pages >=
        selectedConversation.messages.page;
      return (
        <div className="message-container message-list">
          <div className="toolbar">
            <div className="left-items">
              {isSmallScreen && (
                <div
                  basic
                  onClick={() => this.props.setviewConversation(false)}
                >
                  <Icon name="angle left" />
                  Tilbage
                </div>
              )}
            </div>

            {selectedConversation &&
            selectedConversation.with &&
            selectedConversation.with.name &&
            selectedConversation.with._id ? (
              <Link
                //  onClick={props.closeConversationModal}
                //  as={Link}
                className="toolbar-title"
                to={`/bruger/${selectedConversation.with._id}`}
              >
                <UserThumbnail
                  enableLink={false}
                  type="hori"
                  size={35}
                  name={
                    selectedConversation.with.name +
                    ' ' +
                    selectedConversation.with.lastName
                  }
                  src={selectedConversation.with.photoURL}
                />
              </Link>
            ) : (
              <h1 className="toolbar-title"> 'Slettet bruger' </h1>
            )}

            <div className="right-items">{''}</div>
          </div>

          <div
            //ref={ref => (this.scrollParentRef = ref)}
            className="message-list-container"
          >
            {hasMore && (
              <Button onClick={this.loadMore.bind(this)} attached="top">
                Vi ældre..
              </Button>
            )}
            {messages}
            <div
              ref={el => {
                this.messagesEnd = el;
              }}
            />
          </div>
        </div>
      );
    }
    return '';
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    conversations: state.chats.conversations || {},
    selectedConversationId: state.chats.selectedConversationId
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationContainer);
