import {createStore, combineReducers, compose} from 'redux';
import mainReducer from './reducers/mainReducer';
import userReducer from './reducers/userReducer';
import taskReducer from './reducers/taskReducer';
import loginReducer from './reducers/loginReducer';
import serviceReducer from './reducers/serviceReducer';
import feedReducer from './reducers/feedReducer';
import messageReducer from './reducers/messageReducer';
import {saveState, loadState} from '../utils/localStorage';

const persistedState = loadState();

let rootReducer = combineReducers({
  user: userReducer,
  main: mainReducer,
  task: taskReducer,
  login: loginReducer,
  service: serviceReducer,
  feed: feedReducer,
  chats: messageReducer
});

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
//TODO: too much savings. Save  only when main, user oor login is changed!
store.subscribe(() => {
  saveState({
    //main: store.getState().main,

    user: store.getState().user,
    login: {providerId: store.getState().login.providerId}
  });
});

/*const store = createStore(
  rootReducer,

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);*/
/*
const createPersistentStore = compose(
  persistState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)(createStore);

const store = createPersistentStore(rootReducer);
*/
export default store;

//rootReducer = compose(rootReducer,combineReducers(stateFromSession))
//rootReducer = {...rootReducer, ...stateFromSession}
