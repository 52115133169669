import React, {useState} from 'react';
import './ImageGallery.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import toColor from '../../utils/toColor';
import './ImageGallery.css';
export default function ImageGallery(props) {
  // Declare a new state variable, which we'll call "count"
  const [selctedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const images = props.gallery;

  if (!props.gallery || props.gallery.length == 0) {
    return (
      <div
        style={{background: toColor(props._id || '.')}}
        className=" empty-gallery-placeholder "
      >
        <span>Opslaget har ikke billeder</span>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div>
        <div className=" gallery-image-container gallery ">
          <div
            className="image-view-container "
            style={{
              transform: `translateX(${-selctedIndex * 600}px`,
              width: `${images.length * 600}px`
            }}
          >
            {images.map(img => {
              console.log('images.img', img);
              return (
                <div
                  // src={img}
                  className="gallery-image"
                  onClick={() => setLightboxIsOpen(true)}
                  style={{backgroundImage: `url(${img})`}}
                  width="600px"
                  height="600px"
                  alt="Billede fra opslaget."
                />
              );
            })}
          </div>
          <div className="slide-buttons">
            {selctedIndex !== 0 && (
              <div
                className="left-slide-button"
                onClick={() => setSelectedIndex(selctedIndex - 1)}
              >
                <i aria-hidden="true" className="chevron left icon "></i>
              </div>
            )}
            {selctedIndex < images.length - 1 && (
              <div
                className="right-slide-button"
                onClick={() => setSelectedIndex(selctedIndex + 1)}
              >
                <i aria-hidden="true" class="chevron right icon "></i>
              </div>
            )}
          </div>
          <div
            onClick={() => setLightboxIsOpen(true)}
            className="gallery-view-button"
          >
            <i aria-hidden="true" className="zoom icon "></i>
            <span>Forstør billede</span>
          </div>
        </div>
        <div className="gallery-thumbs">
          {images.map((img, index) => (
            <div
              type="button"
              className={`gallery-thumbs__thumb ${
                selctedIndex === index ? ' gallery-thumbs__thumb-selected' : ' '
              } `}
              onClick={() => setSelectedIndex(index)}
              style={{backgroundImage: `url(${img})`}}
            >
              {/* <img src={img} alt="thumbnail" />*/}
            </div>
          ))}
        </div>
      </div>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[selctedIndex]}
          nextSrc={images[(selctedIndex + 1) % images.length]}
          prevSrc={images[(selctedIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxIsOpen(false)}
          onMovePrevRequest={() =>
            setSelectedIndex((selctedIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setSelectedIndex((selctedIndex + 1) % images.length)
          }
        />
      )}
    </React.Fragment>
  );
}
