import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../App.css';
import {SetOnMainPage} from '../../redux/reducers/mainReducer';

import UserProfile from '../UserProfile/UserProfile';
import './Profile.css';

import LoginRequired from '../Login/LoginRequired';
import PageHead from '../reuseable/PageHead/PageHead';

const isSmallScreen = window.innerWidth <= 769;

class Profile extends Component {
  componentWillMount() {
    this.props.setMainPage(false);
    this.state = {
      activeItem: 'inbox'
    };
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
  handleItemClick = (e, {name}) => this.setState({activeItem: name});

  render() {
    if (!this.props.user.isLoggedIn) {
      return <LoginRequired />;
    }
    /* TODO: small screen menu
    if (isSmallScreen) {
      const {activeItem} = this.state;

      return (
        <div>
          <Menu vertical fluid>
            <Menu.Item name="inbox">Profil</Menu.Item>

            <Menu.Item name="myposts" as={Link} to="/profil/annoncer">
              Mine annoncer
            </Menu.Item>

            <Menu.Item name="favorites" as={Link} to="/profil/favoritter">
              Favoritter
            </Menu.Item>
            <Menu.Item name="settings">Indstillinger</Menu.Item>
          </Menu>
          <Button
            fluid
            color="red"
            style={{marginTop: '100px'}}
            onClick={() => {
              //TODO: handle sign out
            }}
          >
            Log ud
          </Button>
        </div>
      );
    }*/
    return (
      this.props.user && (
        <React.Fragment>
          <PageHead title="Profil" />

          {/*this.props.emailVerified ===false && <Message 
        size="small"
        style={{marginBottom:'60px', marginTop:'-50px'}}
        info
          >
        <Message.Header>Bekræft din konto</Message.Header>
        <p>
          Klik på det link du har fået på din email for at bekræfte din konto.
        </p>
      </Message>*/}
          <UserProfile
            user={this.props.user}
            allowEditing={this.props.user.isLoggedIn}
            showUserServices={this.props.user.workerData.isWorkerProfile}
          />
        </React.Fragment>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    emailVerified: state.user.emailVerified
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setMainPage: val => {
      dispatch({type: SetOnMainPage, val: val});
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
