const initialState = {
  isLoading: false,
  loginModal: false,
  error: null,
  providerId: ''
};

export const SET_IS_LOADING = 'SETISLOADING';
export const SET_LOGIN_MODAL = 'SETLOGINMODAL';
export const SETPROVIDERID = 'SETPROVIDERID';

export default function dispatch(state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.val
      };
    case SET_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.val
      };
    case SETPROVIDERID:
      return {
        ...state,
        providerId: action.val
      };
    default:
      return state;
  }
}
