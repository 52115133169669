const initialState = {
  taskFeed: {
    feed: [],
    page: 1,
    pages: 10,
    total: 0
  },
  serviceFeed: {
    feed: [],
    page: 1,
    pages: 10,
    total: 0
  },

  filters: {
    postType: 'SERVICE'
  }
};

export const SetFetchData = 'SetFetchData';
export const AddPostType = 'AddPostType';
export const AddFilter = 'AddFilter';
export const SetFilters = 'SetFilters';
export const RemoveFilter = 'RemoveFilter';
export const ResetFilters = 'ResetFilters';
export const OnServiceFeedResponse = 'OnServiceFeedResponse';
export const OnTaskFeedResponse = 'OnTaskFeedResponse';

export default function dispatch(state = initialState, action) {
  let newFilters = {};

  switch (action.type) {
    case AddPostType:
      newFilters = {...state.filters};
      newFilters[action.val.name] = action.val.value;
      return {
        ...state,
        filters: newFilters
      };

    case AddFilter:
      newFilters = {...state.filters};
      newFilters[action.val.name] = action.val.value;
      return {
        ...state,
        ...initialState,
        filters: newFilters
      };
    case SetFilters:
      newFilters = action.val;
      return {
        ...state,
        ...initialState,
        filters: newFilters
      };

    case RemoveFilter:
      newFilters = {...state.filters};
      delete newFilters[action.val.name];
      return {
        ...state,
        ...initialState,
        filters: newFilters
      };
    case ResetFilters:
      return {
        ...state,
        ...initialState,
        filters: {}
      };
    case OnTaskFeedResponse:
      return {
        ...state,
        taskFeed: {
          ...action.val,
          feed: [...state.taskFeed.feed, ...action.val.feed]
        }
      };
    case OnServiceFeedResponse:
      if (action.val.page === state.serviceFeed.page) {
        //to prevent duplicates
        return state;
      }
      return {
        ...state,
        serviceFeed: {
          ...action.val,
          feed: [...state.serviceFeed.feed, ...action.val.feed]
        }
      };

    default:
      return state;
  }
}
