import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../App.css';
import MultiPostDisplay from '../MultiPostDisplay/MultiPostDisplay';
import DataStore from '../../Data/DataStore';
import {Icon, Header} from 'semantic-ui-react';
import PageHead from '../reuseable/PageHead/PageHead';

import LoginRequired from '../Login/LoginRequired';

class MyPosts extends Component {
  componentWillMount() {
    if (this.props.user._id) {
      DataStore.getUserTasks(this.props.user._id);

      DataStore.getUserServices(this.props.user._id);
    }
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }

  render() {
    if (!this.props.user.isLoggedIn) {
      return <LoginRequired />;
    }
    return (
      this.props.user && (
        <React.Fragment>
          <PageHead title="Mine annoncer" />
          <Header as="h2">
            <Icon name="plus " />
            <Header.Content>
              Mine annoncer
              <Header.Subheader>Annoncer oprettet af dig</Header.Subheader>
            </Header.Content>
          </Header>
          <MultiPostDisplay
            taskPosts={this.props.userTasks}
            servicePosts={this.props.userServices}
          />
        </React.Fragment>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    userTasks: state.user.userTasks || [],
    userServices: state.user.userServices || [],

    user: state.user.userData
  };
}

export default connect(mapStateToProps)(MyPosts);
