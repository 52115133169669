import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SetNewTask, SetEditTaskMode} from '../../redux/reducers/taskReducer';
import TaskDetail from '../TaskDetail/TaskDetail';
import PageHead from '../reuseable/PageHead/PageHead';

class TaskPage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
  render() {
    let id = this.props.match.params.id; //get id from params
    let task = this.props.task;
    return (
      <>
        {task && (
          <PageHead
            title={task.title}
            canonical={`/opgaver/${task._id}`}
            description={task.description}
            og={
              task
                ? {
                    'og:url': `https://heelp.dk/opgaver/${task._id}`,
                    'og:type': 'website',
                    image: {
                      'og:image:secure_url':
                        (task.gallery && task.gallery[0]) || null,
                      'og:image': (task.gallery && task.gallery[0]) || null
                      // 'og:image:width': '300',
                      // 'og:image:height': '600'
                    }
                  }
                : null
            }
          />
        )}

        <TaskDetail id={id} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    task: state.task.taskPageTask
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setNewTask: value => {
      dispatch({
        type: SetNewTask,
        val: value
      });
    },
    setEditTaskMode: value => {
      dispatch({
        type: SetEditTaskMode,
        val: value
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPage);
