import React, {Component} from 'react';
import {Header, Image} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PostItem from '../../PostItem/PostItem';
import './PostItemsDisplay.css';

export default class PostItemsDisplay extends Component {
  render() {
    return (
      <div className="col-12 postItems-display-container">
        <Header className="mb-2" as="h2">
          {this.props.title}
        </Header>
        <div className="row">
          {this.props.items.map(item => (
            <PostItem postItem={item} postType={this.props.postType} />
          ))}
        </div>
      </div>
    );
  }
}

PostItemsDisplay.contextTypes = {
  title: PropTypes.string,
  items: PropTypes.string
};

PostItemsDisplay.defaultProps = {
  title: '',
  items: []
};
