const initialState = {
  showFilterMenu: false,
  showBurgerMenu: false,
  recentPosts: null,
  newPostModal: {
    open: false,
    type: 'task',
    isLoading: false
  },
  onMainPage: false,
  showAfterLoginModal: false
};
export const ToggleNavbarFilter = 'ToggleNavbarFilter';
export const setNewPostModalOpen = 'setNewPostModalOpen';
export const setNewPostModalType = 'setNewPostModalType';
export const setNewPostModalLoading = 'setNewPostModalLoading';
export const SetOnMainPage = 'SetOnMainPage';
export const ToggleBurgerMenu = 'ToggleBurgerMenu';
export const SetAfterLoginModalOpen = 'SetAfterLoginModalOpen';

export default function dispatch(state = initialState, action) {
  switch (action.type) {
    case SetAfterLoginModalOpen:
      return {
        ...state,
        showAfterLoginModal: action.val
      };

    case setNewPostModalOpen:
      return {
        ...state,
        newPostModal: {
          ...state.newPostModal,
          open: action.val
        }
      };
    case setNewPostModalType:
      return {
        ...state,
        newPostModal: {
          ...state.newPostModal,
          type: action.val
        }
      };
    case setNewPostModalLoading:
      return {
        ...state,
        newPostModal: {
          ...state.newPostModal,
          isLoading: action.val
        }
      };

    case ToggleNavbarFilter:
      return {
        ...state,
        showFilterMenu: action.val != null ? action.val : !state.showFilterMenu
      };

    case ToggleBurgerMenu:
      return {
        ...state,
        showBurgerMenu: action.val != null ? action.val : !state.showBurgerMenu
      };

    case SetOnMainPage:
      return {
        ...state,
        onMainPage: action.val
      };

    default:
      return state;
  }
}
