import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Header, Image, Form} from 'semantic-ui-react';
import Modal from 'react-responsive-modal';
//import * as firebase from 'firebase';
import firebase from '../../Data/firebase';
import {errorToast, warningToast} from '../../utils/toast';
import DataStore from '../../Data/DataStore';
import {errorLog} from '../../utils/logger';

class ProfileDeletionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: '',
      pass: ''
    };
  }

  _handleMailChange(event) {
    this.setState({mail: event.target.value});
  }

  _handlePasswordChange(event) {
    this.setState({pass: event.target.value});
  }

  handleUserDeletion = async () => {
    try {
      const isFacebookUser = this.props.providerId.includes('facebook');

      if (isFacebookUser) {
        let user = firebase.auth().currentUser;
        var provider = new firebase.auth.FacebookAuthProvider();

        // Prompt the user to re-provide their sign-in credentials

        const reauthenticated = await user.reauthenticateWithPopup(provider);
        const idToken = await reauthenticated.user.getIdToken();
        await reauthenticated.user.delete();
        await DataStore.deleteUser(idToken);

        //this.props.signOut();
        warningToast({
          header: 'Din profil er slettet.',
          autoClose: 10000
        });
        this.props.onDeletionComplete();
      } else {
        //sign in , delete firebase, delete elilla
        const firebaseResponse = await firebase
          .auth()
          .signInWithEmailAndPassword(this.state.mail, this.state.pass);

        const idToken = await firebaseResponse.user.getIdToken();
        await DataStore.deleteUser(idToken);
        await firebaseResponse.user.delete();

        warningToast({
          header: 'Din profil er slettet.',
          autoClose: 10000
        });
        this.props.onDeletionComplete();
      }
    } catch (error) {
      errorToast({header: 'Kunne ikke fuldføre handlingen.'});
      errorLog('ProfileDeletionModal.handleUserDeletion', error);
    }
  };

  _emailDeletionForm() {
    return (
      <React.Fragment>
        <Form.Input
          fluid
          label="Mail adresse"
          placeholder="E-mail Address"
          name="email"
          type="text"
          onChange={this._handleMailChange.bind(this)}
          value={this.state.mail}
        />

        <Form.Input
          className="mt-3"
          fluid
          label="Password"
          placeholder="Password"
          name="password"
          type="password"
          onChange={this._handlePasswordChange.bind(this)}
          value={this.state.pass}
        />
      </React.Fragment>
    );
  }

  render() {
    const isFacebookUser = this.props.providerId.includes('facebook');
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        closeIconSize="20"
      >
        <Header>Sletning af profil</Header>

        <Form style={{minWidth: '400px'}}>
          {!isFacebookUser && this._emailDeletionForm()}
          <Button
            className="mt-5"
            fluid
            color="red"
            onClick={this.handleUserDeletion}
          >
            Slet min profil
          </Button>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    providerId: state.login.providerId
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDeletionModal);
