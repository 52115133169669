import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {SET_LOGIN_MODAL} from '../../redux/reducers/loginReducer';
import {useScrollTop} from '../../utils/useScrollTop';
import PageHead from '../reuseable/PageHead/PageHead';

export default () => {
  const dispatch = useDispatch();
  useScrollTop();
  useEffect(() => {
    dispatch({
      type: SET_LOGIN_MODAL,
      val: false
    });
  });
  return (
    <>
      <PageHead title="Brugerbetingelser" />
      <h1> Brugerbetingelser</h1>

      <h3>1. Generelt</h3>

      <p class="c2">
        <span class="c1">
          De følgende betingelse er gældende for enhver der bruger sitet
          Heelp.dk
        </span>
      </p>
      <p class="c2">
        <span class="c1">
          Forbehold tages for at der kan ske ændringer i brugerbetingelserne.
          Såfremt der sker ændringer i brugerbetingelserne, vil brugeren blive
          underrettet på disse ændringer.
        </span>
      </p>

      {/*      <p class="c2">
        <span class="c1">PraktiskeOpgaver</span>
      </p>
    <p class="c2">
        <span class="c1">Nybrovej 304 C-35, 2800 Kongens Lyngby</span>
  </p>*/}
      <p class="c2">
        <span class="c3">
          Har du spørgsmål angående vores behandling af dine personoplysninger,
          kan du kontakte os på
        </span>
        <span class="c3 c7">
          &nbsp;
          <a href="mailto:kontakt@heelp.dk">kontakt@heelp.dk</a>
        </span>
      </p>
      <h3>2. Oprettelse og benyttelse af brugerprofil</h3>

      <p class="c2">
        <span class="c3">Når du </span>
        <span class="c1">
          opretter en profil afgiver du samtykke til at vi må behandle dine
          personoplysninger i overensstemmelse med privatlivspolitikken.
        </span>
      </p>
      <p class="c2">
        <span class="c1">
          Din mailadresse og adgangskode som du bruger til at logge ind i appen
          skal holdes fortroligt og må ikke gives videre til andre.
        </span>
      </p>
      <p class="c2">
        <span class="c1">
          PraktiskeOpgaver forbeholder sig retten til at slette eller deaktivere
          brugerens profil hvis brugeren ikke overholder disse oplysninger. I så
          fald, vil brugeren blive kontaktet og informeret om grunden bag.
        </span>
      </p>
      <h3 class="c4">3. Benyttelse af chatten</h3>
      <p class="c2">
        <span class="c1">
          Formålet med chatten er at gør det nemmere for brugeren at aftale
          forsendelse eller afhentning af bogen. Chatten må ikke bruges til
          andre formål. Der må ikke udveksles personlige kontaktoplysninger
          gennem chatten. Betalingsoplysninger og andre kritiske data må ikke
          udveksles.
        </span>
      </p>
      <h3 class="c4">4. Sletning af brugerprofil</h3>
      <p class="c2">
        <span class="c1">
          Såfremt brugeren ønsker at slette sin profil, kan dette gøres ved at
          logge ind, gå til profilsiden, trykke på indstillinger, rediger profil
          og dernæst slet profil.
        </span>
      </p>
      <p class="c2">
        <span class="c1">
          Vi forbeholder os retten til at beholde dine data i 30 dage efter
          sletning af din profil. Alle dine oplysninger vil blive slettet
          permanent og du vil ikke kunne gendanne disse oplysninger.
        </span>
      </p>

      <h3 class="c4">5. Ansvarsfraskrivelse</h3>
      <p class="c2">
        <span class="c3">PraktiskeOpgaver</span>
        <span class="c1">
          &nbsp;er formidler af oplysninger som brugeren selv er ansvarlig for.
        </span>
      </p>
      <h3 class="c4">6. Ikrafttrædelse og ophør</h3>

      <p class="c2">
        <span class="c4"></span>
      </p>
      <p class="c2">
        <span class="c1">
          Disse betingelser træder i kraft når brugeren benytter
          PraktiskeOpgaver. Betingelserne er gældende så længe brugeren har en
          profil på platformen. Disse betingelser ophører når brugeren sletter
          sin profil.
        </span>
      </p>
    </>
  );
};
