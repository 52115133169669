import React, {Component} from 'react';
import toColor from '../../utils/toColor';
import {SetNewTaskCategory} from '../../redux/reducers/taskReducer';
import {connect} from 'react-redux';

class CategoryItem extends Component {
  componentDidMount() {
    this.handleBackground();
  }

  handleBackground() {
    if (this.props.category.image) {
      this.item.style.backgroundImage = `url(${this.props.category.image})`;
    } else {
      this.item.style.backgroundColor = toColor(
        Math.floor(Math.random() * 100 + 1)
      ); //change to id
    }
  }

  render() {
    let category = this.props.category;
    return (
      <div
        className="category-item"
        ref={node => (this.item = node)}
        onClick={() => this.props.setCategory(category.type)}
      >
        <div className="category-item-content">{category.title}</div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCategory: value => {
      dispatch({
        type: SetNewTaskCategory,
        val: value
      });
    }
  };
};
function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItem);
