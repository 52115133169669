import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../App.css';
import MultiPostDisplay from '../MultiPostDisplay/MultiPostDisplay';
import DataStore from '../../Data/DataStore';
import {Icon, Header} from 'semantic-ui-react';
import {Redirect} from 'react-router-dom';
import PageHead from '../reuseable/PageHead/PageHead';

class Favourites extends Component {
  componentWillMount() {
    DataStore.fetchFavourites();
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }

  render() {
    if (!this.props.user.isLoggedIn) {
      return <Redirect to="/login" />;
    }
    return (
      this.props.user && (
        <React.Fragment>
          <PageHead title="Favoritter" />

          <Header as="h2">
            <Icon name="bookmark outline" />
            <Header.Content>
              Favoritter
              <Header.Subheader>Opslag gemt som favuritter</Header.Subheader>
            </Header.Content>
          </Header>
          <MultiPostDisplay
            taskPosts={this.props.favouritesFetched.favouriteTaks}
            servicePosts={this.props.favouritesFetched.favouriteServices}
          />
        </React.Fragment>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    favouritesFetched: state.user.favourites || [],
    user: state.user.userData
  };
}

export default connect(mapStateToProps)(Favourites);
