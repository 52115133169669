import React, {Component} from 'react';
import {connect} from 'react-redux';

import MessageContainer from '../MessagingNew/Container';

import MessageFacade from '../../Data/MessageFacade';
import LoginRequired from '../Login/LoginRequired';
import PageHead from '../reuseable/PageHead/PageHead';

class MessagePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      messageList: []
    };
  }
  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  }
  componentWillMount() {
    MessageFacade.loadConversations();
  }

  render() {
    if (!this.props.user.isLoggedIn) {
      return <LoginRequired />;
    }

    return (
      <>
        <PageHead title="Beskeder" />
        <MessageContainer
          socket={this.props.socket}
          history={this.props.history}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagePage);
