const postTypeTranslate = (input = '') => {
  if (input === 'SERVICE') {
    return 'service';
  }
  const inputLowercase = input.toLocaleLowerCase();

  switch (inputLowercase) {
    case 'task':
      return 'opgaver';
    case 'opgaver':
      return 'TASK';

    case 'service':
      return 'SERVICE';

    default:
      return input;
  }
};

export default postTypeTranslate;
