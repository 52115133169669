import React, {useEffect} from 'react';
import {Button, Header, Image, Modal, Icon} from 'semantic-ui-react';
import EditWorkerData from '../EditProfile/EditWorkerData';
import {errorLog} from '../../utils/logger';
import DataStore from '../../Data/DataStore';
import './AfterLogin.css';
import Loading from '../reuseable/Loading/Loading';
import {Link} from 'react-router-dom';
//import {useHistory} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {errorToast, warningToast} from '../../utils/toast';
import {SetAfterLoginModalOpen} from '../../redux/reducers/mainReducer';

import {HandleProfileChange} from '../../redux/reducers/userReducer';
//TODO
//clear redux on close modalgit
//

//TODO: new text. tak fordi du vil bruge heelp.dk. Sidste trin. Hvad søger du her? freelancer - virksomhed
const AfterLoginModal = props => {
  //const [open, setOpen] = React.useState(true); //todo change to redux + userobject
  const open = useSelector(state => state.main.showAfterLoginModal);
  const dispatch = useDispatch();

  const setOpen = val => {
    dispatch({
      type: SetAfterLoginModalOpen,
      val: val
    });
  };
  const setIsWorker = val => {
    const actionVal = {
      name: 'workerData.isWorkerProfile',
      value: val
    };
    dispatch({
      type: HandleProfileChange,
      val: actionVal
    });
  };
  const [contentState, setContentState] = React.useState(null); //can be null, helpSeeker,  worker , workerProfileCreated
  //const [isWorker, setIsWorker] = React.useState(false); //todo change to redux + userobject
  const [loading, setLoading] = React.useState(false); //todo change to redux + userobject
  const user = useSelector(state => state.user.userData);

  const isWorker = contentState === 'worker';
  const isWorworkerProfileCreated = contentState === 'workerProfileCreated';

  const showBackButton = !isWorworkerProfileCreated && contentState !== null;

  const isHelpSeeker = contentState === 'helpSeeker';
  const showActions = isWorker; //|| isHelpSeeker;

  const updateProfile = async () => {
    try {
      setLoading(true);
      let updatedData = {...user};

      updatedData.workerData.isWorkerProfile = true;
      await DataStore.updateUserProfile(updatedData);
      setContentState('workerProfileCreated');

      //this.props.setPreviousData(updatedData); why?
    } catch (error) {
      errorLog('TutorialModal.updateProfile', error);
      errorToast({
        header: 'Noget gik galt.',
        message: 'Kunne ikke gemme. Prøv igen.'
      });
    } finally {
      setLoading(false);
    }
  };
  const getHeaderText = () => {
    switch (contentState) {
      case 'worker':
        return 'Opret arbejderprofil - udfyld stamdata';
      case 'helpSeeker':
        return 'Velkommen til ptaktiskeopgaver.dk!'; //Opret en opgave eller udforsk ydelser

      case 'workerProfileCreated':
        return 'Arbejderprofil er aktiveret';

      default:
        return 'Velkommen til Heelp.dk!';
    }
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      {
        <>
          <Modal.Header>
            <div className="afterlogin-modal-header">
              {showBackButton && (
                <Icon
                  onClick={() => {
                    setContentState(null);
                  }}
                  style={{cursor: 'pointer'}}
                  // className="mb-4"
                  size="large"
                  name="arrow  left"
                />
              )}
              <div className="d-flex">
                {isWorworkerProfileCreated && (
                  <Icon
                    size="large"
                    className="afterlogin-success-icon"
                    name="check circle"
                  />
                )}
                <h2 className="afterlogin-modal-header-text">
                  {getHeaderText(contentState)}
                </h2>
              </div>

              <Icon
                onClick={closeModal}
                name="close"
                size="big"
                className="modal-close-icon-style"
              />
            </div>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {!contentState && (
                <>
                  <Header>Hvad søger du her? </Header>

                  <p>
                    Hvis du er freelancer eller virksomhed, skal du aktivere din
                    arbejderprofil, så du kan oprette service og være synlig for
                    andre brugere der kan kontakte dig.
                  </p>
                  <div className="afterlogin-choice-item-container">
                    <div
                      className="afterlogin-choice-item"
                      onClick={() => setContentState('worker')}
                    >
                      <Image
                        src="/images/conWorker1.png"
                        alt="worker"
                        width="40"
                        height="40"
                        loading="lazy"
                      />
                      <p>Jeg vil tilbyde hjælp</p>
                    </div>

                    <div
                      className="afterlogin-choice-item"
                      onClick={() => setContentState('helpSeeker')}
                    >
                      <Icon
                        name="search"
                        size="big"
                        className="modal-search-icon-style"
                      />
                      <p>Jeg vil søge hjælp</p>
                    </div>
                  </div>

                  <p>
                    *Du kan altid ændre dine valg i din profil indstillinger.{' '}
                  </p>
                </>
              )}
              {isHelpSeeker && (
                <>
                  <p className="welcome-text-after-login">
                    Opret en opgave og afvent tilbud eller udforsk service.
                  </p>

                  <Button
                    className="success-button"
                    onClick={() => {
                      props.history.push('/opret/opgave');
                      closeModal();
                    }}
                  >
                    Opret opgave
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      closeModal();

                      props.history.push({
                        pathname: '/',
                        search: `?feed=service`
                      });
                      setTimeout(
                        () => window.scrollBy(0, window.innerHeight / 1.3),
                        50
                      );
                    }}
                  >
                    Udforsk service
                  </Button>
                </>
              )}
              {isWorker && (
                <>
                  {loading ? (
                    <Loading text="Gemmer..." />
                  ) : (
                    <EditWorkerData style={{backgroundColor: 'white'}} />
                  )}
                </>
              )}

              {isWorworkerProfileCreated && (
                <>
                  <p>
                    Din arbejderprofil er oprettet. Find din næste kunde ved at
                    udforske oprettet opgaver eller opret en service så
                    potentielle kunder kan kontakte dig.
                  </p>

                  <Button
                    className="success-button"
                    onClick={() => {
                      props.history.push('/opret/service');

                      closeModal();
                    }}
                  >
                    Opret service
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      props.history.push({
                        pathname: '/',
                        search: `?feed=task`
                      });
                      setTimeout(
                        () => window.scrollBy(0, window.innerHeight / 1.3),
                        50
                      );
                      closeModal();
                    }}
                  >
                    Udforsk opgaver
                  </Button>
                </>
              )}
            </Modal.Description>
          </Modal.Content>
          {showActions && (
            <Modal.Actions className="d-flex justify-content-center">
              <Button
                fluid
                content="Gem"
                onClick={() => {
                  if (user?.workerData?.type?.length == 0) {
                    warningToast({
                      header: 'Udfyld venligst arbejder data.',
                      message: 'Type af arbejder mangler'
                    });
                  } else if (user?.workerData?.description?.length == 0) {
                    warningToast({
                      header: 'Udfyld venligst arbejder data.',
                      message: 'Beskrivelse mangler'
                    });
                  } else {
                    setIsWorker(true);
                    updateProfile();
                  }
                }}
                positive
              />
            </Modal.Actions>
          )}
        </>
      }
    </Modal>
  );
};

export default withRouter(AfterLoginModal);
