import React, {Component} from 'react';
import NewServiceComponent from '../NewServiceComponent/NewServiceComponent';
import {connect} from 'react-redux';
import {SetNewTaskCategory} from '../../redux/reducers/taskReducer';
import {Icon, Label} from 'semantic-ui-react';
import LoginRequired from '../Login/LoginRequired';
import {Link} from 'react-router-dom';
import PageHead from '../reuseable/PageHead/PageHead';

class NewServicePage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
  render() {
    let content;

    const user = this.props.user.userData;
    if (!user.isLoggedIn) {
      //
      content = <LoginRequired />;
    } else if (!user.workerData.isWorkerProfile) {
      //is worker
      content = (
        <div className="">
          <h1>Inaktiv arbejderprofil</h1>
          <p>For at oprette service, skal du aktivere din arbejderprofil.</p>

          <Label className="edit-link-label" as={Link} to={'/profil/rediger'}>
            <Icon name="pencil" />
            Gå til profilredigering
          </Label>
        </div>
      );
    } else {
      let id = this.props.match.params.id; //get id from params
      content = (
        <div className="App">
          <NewServiceComponent
            editMode={this.props.match.url.includes('rediger')}
            id={id}
            history={this.props.history}
          />
        </div>
      );
    }
    return (
      <>
        <PageHead title="Tilbyd service" />
        {content}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCategory: value => {
      dispatch({
        type: SetNewTaskCategory,
        val: value
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewServicePage);
