import React from 'react';
import './Policy.css';
import PageHead from '../reuseable/PageHead/PageHead';
import {useScrollTop} from '../../utils/useScrollTop';

export default () => {
  useScrollTop();
  return (
    <>
      <PageHead title="Privatlivspolitik" />
      <h1> Privatlivspolitik</h1>
      Dataansvarlig: Praktiskeopgaver Kontaktoplysninger til
      Databeskyttelsesrådgiveren: Yousef mohsen, praktiskeopgaver@outlook.com
      <h3>1. Introduktion </h3>
      <p>
        1.1 Når du opretter en profil på Heelp.dk, indgår du en juridisk aftale
        med os om brug af sitet.
      </p>
      <p>
        1.2. For at administrere din profil og levere vores service i henhold
        til aftalen, har vi brug for at behandle dine personlige oplysninger. Vi
        behandler dine personoplysninger i medfør af GDPR-artikel 6, stk. 1,
        litra b) om opfyldelse af en aftale med dig som registreret.
      </p>
      <p>
        1.3. Her i privatlivspolitikken finder du de nærmere oplysninger om
        vores behandling af dine oplysninger.
      </p>
      <h3>2. Medlemsdiskretion </h3>
      <p>
        {' '}
        2.1. Vi ønsker at værne mest muligt om dine og vores øvrige brugeres
        private forhold. Derfor har vi medlemsdiskretion, som går ud på, at du
        ikke er tilladt at gøre brug af oplysninger vedrørende andre brugere,
        hvortil der er adgang fra Praktiskeopgaver.dk.
      </p>
      <h3>3. Profiloprettelse</h3>
      <p>
        4.1 Du opretter en profil på Praktiskeopgaver ved hjælp af din e-mail,
        hvor vi benytter Google Firebase som login system. Din e-mail bliver
        opbevaret sikkert på vores databaser, hvor vi er de eneste der kan
        kontrollere den. Din e-mail bliver ikke sendt videre til andre
        tredjeparts services på henblik på markedsføring. Det samme gælder når
        du logger ind med Facebook.
      </p>
      <h3>6. Registrering af dine oplysninger</h3>
      <p className="policy-list">
        6.1. Når du opretter en profil på Praktiskeopgaver, beder vi dig om at
        oplyse følgende:
        <span>
          {' '}
          Navn: For at sikre en bedre brugeroplevelse på vores site, skal du
          have et letlæseligt brugernavn så andre brugere kan identificere dig.{' '}
        </span>
        <span>
          {' '}
          e-mail: Vi bruger din e-mailadresse for at vi kunne identificere dig
          og for at du kan logge ind på sitet igen.
        </span>
      </p>
      <h3>7. Profildata</h3>
      <p>
        7.1. I forbindelse med din løbende brug af vores service, behandler vi
        alle oplysninger for din Profil, da dette er nødvendigt for at levere og
        forbedre vores service (”Profildata”).
      </p>
      <p className="policy-list">
        7.2. Profildata omfatter følgende, som vi automatisk registrerer:
        <span> Alle oprettet opslag</span>
        <span> Privatbeskeder</span>
        <span> Opslag som du tilføjer til favoritter</span>
        <span>
          Andre profildata genereret i forbindelse med brug af funktioner på
          platformen
        </span>
      </p>
      <p>
        7.3. Husk, at du selv er ansvarlig for risikoen ved at offentliggøre
        dine personlige oplysninger i privatbeskeder på platformen. For at
        varetage din sikkerhed på platformen, samt foretage løbende
        forbedringer, kan dine privatbeskeder blive monitoreret af relevante
        medarbejdere.
      </p>
      <h3> 8. Opslag</h3>
      <p>
        8.1. Til brug for din oprettelse af Opslag beder vi dig om at udfylde en
        række oplysninger, herunder kan du uploade billeder til din annonce.{' '}
      </p>
      <h3>9. Statistik </h3>
      <p>
        9.1. Alle ovennævnte typer af oplysninger anvender vi i anonymiseret
        eller pseudonymiseret form til statistik for at forbedre vores service.
        Vores behandlingsgrundlag for dette er GDPR artikel 6, stk. 1, litra f)
        om vores legitime interesse, som ikke i udgangspunktet overstiger
        hensynet til dine personlige rettigheder.
      </p>
      <h3>10. Nyhedsbrev mv. </h3>
      10.1. Vi anvender din e-mailadresse til at sende dig nyhedsbreve og anden
      direkte markedsføring i overensstemmelse med dit samtykke hertil.
      {/* 10.2. Du kan til hver en tid kan trække dit markedsføringssamtykke tilbage, og du
      kan indstille notifikationer*/}
      <h3>11. Vores sikkerhed </h3>
      <p>
        11.1. For at beskytte dine oplysninger mod hændelig eller ulovlig
        tilintetgørelse, tab, ændring, uautoriseret videregivelse af eller
        adgang i overensstemmelse med databeskyttelsesreglerne i Danmark og EU
        lagrer vi alle personoplysninger hos vores sikkerhedsgodkendte
        databehandlere.
      </p>
      <p>11.2. Vores databehandlere er placeret indenfor EU.</p>
      <p>
        11.3. Alle vores medarbejdere er underlagt fortrolighed, og vi har
        begrænset adgangen til personoplysninger til det højst nødvendige.
      </p>
      <h3>12. Sletning af din Profil </h3>
      <p>
        12.1. Du har til enhver tid mulighed for at slette din Profil. Det kan
        du gøre via selve hjemmesiden.
      </p>
      <p>12.2. Du bedes være opmærksom beskeder beholdes anonymiserede.</p>
      <h3>13. Dine rettigheder </h3>
      <p>
        13.1. Du kan altid se dine oplysninger på din profil. Du er som bruger
        ansvarlig for, at de oplysninger, der fremgår af din Profil, til enhver
        tid er fuldt ud korrekte og gyldige.
      </p>
      <p>
        13.2. Du er også velkommen til at kontakte os for at få yderligere
        indsigt i de oplysninger, vi behandler om dig, samt til at få ajourført,
        rettet, slettet eller udleveret oplysninger, som du ikke selv kan
        administrere på din Profil. Alle anmodninger herom skal fremsættes
        skriftligt på e-mail til{' '}
        <a href="mailto:kontakt@heelp.dk">kontakt@heelp.dk</a>
      </p>
      <p>
        13.3. Ønsker du at klage over vores behandling af dine data, bedes du
        henvende dig direkte til os.
      </p>
      <h3> 14. Ændringer</h3>
      <p>
        15.1. Vi forbeholder os retten til at ændre Persondatapolitikken. Hvis
        der er tale om væsentlige ændringer, vil vi oplyse dig via e-mail eller
        direkte på platformen.
      </p>
    </>
  );
};
