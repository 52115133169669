import React, {useState} from 'react';
import {Comment, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {SelectConversation} from '../../redux/reducers/messageReducer';
import Moment from 'moment';
import MessageFacade from '../../Data/MessageFacade';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import {Button, Label} from 'semantic-ui-react';

import './ConversationList.css';
function ConversationsList(props) {
  let conversations = Object.values(props.conversations);
  function selectConversation(convID) {
    props.selectConversation(convID);
    //props.setDidSelectConversation();

    if (
      props.conversations[convID] &&
      props.conversations[convID].messages.messageList.length <= 1
    ) {
      MessageFacade.loadMessages(convID);
    }
  }
  const hasMore = props.conversationPagination.hasMore;

  if (!conversations || conversations.length === 0) {
    return (
      <div className="text-center pt-3">
        <p>Ingen samtaler.</p>
      </div>
    );
  }
  return (
    <div>
      {conversations.map((conversation, index) => {
        if (!conversation) {
          return;
        }

        const unread =
          conversation.unread && conversation.unread[props.user._id] > 0
            ? conversation.unread[props.user._id]
            : false;
        const lastMsg = conversation.messages.messageList[0];

        if (!lastMsg) {
          return;
        }
        return (
          <div
            onClick={() => {
              selectConversation(conversation._id);
              props.setviewConversation(true);
            }}
            className={
              'conversation-list-item ' +
              (props.selectedConversationId === conversation._id
                ? ' conversation-list-item-selected '
                : '')
              // +(unread? 'conversation-is-unread':'')
            }
          >
            <img
              loading="lazy"
              className="conversation-photo"
              src={
                conversation.with && conversation.with.photoURL
                  ? conversation.with.photoURL
                  : '/images/user.png'
              }
              alt="conversation"
            />
            <div className="conversation-info">
              <h1 className="conversation-title">
                {(conversation &&
                  conversation.with &&
                  conversation.with.name) ||
                  'Slettet bruger'}
              </h1>
              <p className="conversation-snippet">
                {lastMsg ? lastMsg.content : ''}
              </p>
            </div>
            {unread && (
              <Label
                circular
                color="red"
                className="notification-label-convlist"
              >
                {unread}
              </Label>
            )}
          </div>
        );
      })}
      {hasMore && conversations.length > 0 && (
        <Button
          onClick={() => MessageFacade.loadConversations(true)}
          attached="bottom"
        >
          Vis flere
        </Button>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    chats: state.chats,
    conversations: state.chats.conversations || {},
    conversationPagination: state.chats.conversationPagination || {},
    selectedConversationId: state.chats.selectedConversationId
  };
}

const mapDispatchToProps = dispatch => {
  return {
    selectConversation: value => {
      dispatch({
        type: SelectConversation,
        val: value
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationsList);
