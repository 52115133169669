import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import ConversationList from './ConversationList';
import ConversationContainer from './ConversationContainer';
import {Icon, Button, Header, Transition} from 'semantic-ui-react';
import MessageInput from './MessageInput';
import './Container.css';
function MessageContainer(props) {
  // Declare a new state variable, which we'll call "count"
  const [viewConversation, setviewConversation] = useState(0);

  const selectedConversation =
    props.conversations[props.selectedConversationId] || null;

  const isSmallScreen = window.innerWidth <= 769;

  const conversation = selectedConversation ? (
    <div className={'conversation-container '}>
      <ConversationContainer
        setviewConversation={val => setviewConversation(val)}
      />
      <MessageInput socket={props.socket} />
    </div>
  ) : (
    <div className="no-conversaation-selected-container">
      <Header size="huge">Beskeder</Header>

      <Header>Klik på en samtale i listen til venstre for at vise den</Header>
    </div>
  );

  const showFullConversation =
    (viewConversation && isSmallScreen) || !isSmallScreen;
  const showConversationList =
    (!viewConversation && isSmallScreen) || !isSmallScreen;

  return (
    <div className="messenger-container  ">
      {showConversationList && (
        <div className={'conversations-list-container'}>
          <div className="top-conv-list-container">
            <div
              className="close-chat-btn"
              onClick={() => {
                if (props.history.location.key) {
                  props.history.goBack();
                } else {
                  props.history.push('/');
                }
              }}
            >
              <Icon size="large" name="delete" />
              <p> Luk chat</p>
            </div>
          </div>

          <ConversationList
            setviewConversation={val => {
              setviewConversation(val);
            }}
          />
        </div>
      )}
      {showFullConversation && conversation}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    conversations: state.chats.conversations || {},
    selectedConversationId: state.chats.selectedConversationId
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
