import React, {Component} from 'react';
import {connect} from 'react-redux';
import './UserProfile.css';
import toColor from '../../utils/toColor';
import Moment from 'moment';
import 'moment/locale/da';
import {
  Header,
  TextArea,
  Form,
  Button,
  Rating,
  Icon,
  Divider,
  Message,
  Label,
  Popup,
  Dropdown
} from 'semantic-ui-react';
import PostItemsDisplay from '../reuseable/PostItemsDisplay/PostItemsDisplay';
import DataStore from '../../Data/DataStore';
import MessageFacade from '../../Data/MessageFacade';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import {Link} from 'react-router-dom';
import {SetNewConversation} from '../../redux/reducers/messageReducer';
import withNewConversation from '../reuseable/hoc/NewConversation';
import {errorToast, successToast, warningToast} from '../../utils/toast';

class UserProfile extends Component {
  componentDidMount() {
    let user = this.props.user;
    if (user) {
      DataStore.getUserProfileServices(user._id);
    }
  }

  render() {
    let user = this.props.user;
    let imgStyle = {
      backgroundImage: `url(${user.coverURL})` || 'none',
      backgroundColor: toColor(user._id)
    };

    /* this.item.style.backgroundColor = toColor(
        Math.floor(Math.random() * 100 + 1)
      ); //change to id*/
    const showContactBtn =
      this.props.loggedInUser.isLoggedIn && user.workerData.isWorkerProfile;

    return (
      <React.Fragment>
        <div className="row ">
          <div className="col-12">
            <div style={imgStyle} className="user-cover-img" />
          </div>
          <div className="col-12">
            <div>
              {this.props.allowEditing && (
                <React.Fragment>
                  <Dropdown
                    text="Indstillinger"
                    // icon="ellipsis horizontal"
                    style={{marginTop: '6px', float: 'right'}}
                    direction="left"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Kopér link"
                        icon="copy"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            window.location.origin + '/bruger/' + user._id
                          );
                          successToast({
                            header: 'Kopieret',
                            message: 'Link til deling er kopieret'
                          });
                        }}
                      />
                      <Dropdown.Item
                        icon="pencil"
                        as={Link}
                        text="Rediger profil"
                        to={'/profil/rediger'}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  {/*   <Label
                  as={Link}
                  to={'/profil/rediger'}
                  style={{float: 'right', marginTop: '6px'}}
                >
                  <Icon name="pencil" />
                  Rediger
             </Label>*/}
                </React.Fragment>
              )}
              {/*<Label
              onClick={() => {navigator.clipboard.writeText('messi')}}
              >
                <Icon name="copy" />
                Kopier link
              </Label> */}

              {/*  <Popup
                trigger={
                  <Label
                    className="copy-url-btn"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window.location.origin + '/bruger/' + user._id
                      );
                    }}
                  >
                    <Icon name="copy" />
                    Kopiér
                  </Label>
                }
                content="Klik her for at kopiér link til denne profil" //todo show toast
                inverted
              />*/}
            </div>
            <UserThumbnail
              className="user-profile-thumbnail"
              name={(user.name || '') + ' ' + (user.lastName || ' ')}
              subtitle={
                (user.workerData.isWorkerProfile && user.workerData.type) || ''
              }
              src={user.photoURL || null}
              userId={user._id}
              type="verti-large"
              enableLink={false}
            />

            <div className="user-profile-thumbnail-placeholder" />
          </div>
        </div>
        <div className="row labelize-input">
          {user.workerData.isWorkerProfile && (
            <div className="col-12 col-md-12 mb-4 mb-md-0">
              <Form className="text-center">
                <Header as="h2">Om mig</Header>

                <TextArea
                  autoHeight
                  disabled
                  value={user.workerData.description || ''}
                  className="text-center profile-text-area"
                />
              </Form>
            </div>
          )}

          <div className="col-12 col-md-12">
            <div className="row ">
              <div className="col-12  text-center mb-4">
                <p className="bold">Bruger siden</p>
                {Moment(user.created_at).format('Do MMMM YYYY')}
              </div>
              {/*<div className="col-12 text-center mb-4">
                <p className="bold">Løste opgaver</p>
                25
              </div>

              <div className="col-12  text-center mb-4">
                <p className="bold">Sidst aktiv </p>3 dage siden
            </div>*/}
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          {user._id != this.props.loggedInUser._id && showContactBtn && (
            <Button
              className="mt-4 green-btn-style col-9 col-md-2"
              fluid
              onClick={() =>
                this.props.contactUser({
                  receiverID: user._id,
                  name: user.name,
                  lastName: user.lastName
                })
              }
              //disabled={}
            >
              Kontakt {user.name || ''}
            </Button>
          )}
        </div>
        <div className="row">
          {user.workerData.isWorkerProfile &&
            this.props?.servicesBySameOwner?.length > 0 && (
              <React.Fragment>
                <Divider className="col-12" />
                <PostItemsDisplay
                  title={user.name + ' tilbyder '}
                  items={this.props.servicesBySameOwner || []}
                  postType="service"
                />
              </React.Fragment>
            )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    servicesBySameOwner: state.service.servicesBySameOwner,
    loggedInUser: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNewConversation(UserProfile));
