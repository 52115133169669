import MessageFacade from '../../Data/MessageFacade';
const initialState = {
  conversations: {},
  conversationPagination: {
    page: 1,
    pages: 10,
    total: 200
  },
  totalUnread: 0,
  selectedConversationId: null,
  newConversationModal: {
    open: false,
    userID: '',
    reciverID: '',
    name: '',
    lastName: '',
    loading: false,
    success: null
  }
};
/**
   * consersation[0]= { 
   * _id,
   * participants[{_id, name lastName, photoURL}],
   * messageList:[
author: {_id: "5c4f759ff86dad123c007fdc", lastName: "Wisemanwitz", name: "Sophia"}
content: "blah"
conversationId: "5c557a58d5325c0f58b6c590"
createdAt: "2019-02-08T21:31:35.528Z"
updatedAt: "2019-02-08T21:31:35.528Z"
__v: 0
_id: "5c5df5378bc0f38be5cab82b"]
   * }
   */
export const SetConversations = 'SetConversations';
export const UpdateConversations = 'UpdateConversations';
export const AddConversation = 'AddConversation';
export const SelectConversation = 'SelectConversation';
export const SetMessages = 'SetMessages';
export const ResetMessages = 'ResetMessages';
export const SetNewConversation = 'SetNewConversation';
export const SetConversationsPagination = 'SetConversationsPagination';
export const SetConversationsUnread = 'SetConversationsUnread';
export const UpdateConversationIsRead = 'UpdateConversationIsRead';

export const OnGotNewMessage = 'OnGotNewMessage';

function sortConversations(conversations) {
  //console.time('sortConversationsOld');
  try {
    //maybe better to sort in backend
    let convArray = Object.values(conversations);
    convArray.sort(function(a, b) {
      return (
        new Date(b.messages.messageList[0].createdAt).getTime() -
        new Date(a.messages.messageList[0].createdAt).getTime()
      );
    });
    let newConvList = {};
    convArray.forEach(val => {
      const key = val._id;
      newConvList[key] = val;
    });
    //   console.timeEnd('sortConversationsOld');

    return newConvList;
  } catch (error) {
    return conversations;
  }
}

export default function dispatch(state = initialState, action) {
  switch (action.type) {
    case SetNewConversation:
      return {
        ...state,
        newConversationModal: {...state.newConversationModal, ...action.val}
      };

    case ResetMessages:
      return {
        ...initialState
      };

    case SetConversations:
      let sortedConversations = sortConversations({
        ...action.val,
        ...state.conversations
      });
      return {
        ...state,
        conversations: sortedConversations
      };

    case SetConversationsPagination:
      return {
        ...state,
        conversationPagination: action.val
      };
    case SetConversationsUnread:
      return {
        ...state,
        totalUnread: action.val
      };

    case UpdateConversationIsRead:
      const oldConversation = state.conversations[action.val.conversationId];
      return {
        ...state,
        totalUnread: state.totalUnread > 0 ? state.totalUnread - 1 : 0, //dosnt work
        conversations: {
          ...state.conversations,
          [action.val.conversationId]: {
            ...oldConversation,
            unread: {...oldConversation.unread, [action.val.userID]: 0}
          }
        }
      };
    case UpdateConversations:
      return {
        ...state,
        conversations: sortConversations({
          ...state.conversations,
          ...action.val
        })
      };

    case AddConversation:
      const newConversations = {...state.conversations};
      newConversations[action.val._id] = action.val;
      return {
        ...state,
        conversations: sortConversations(newConversations)
      };

    case SelectConversation:
      return {
        ...state,
        selectedConversationId: action.val
      };

    case SetMessages:
      const conversation = state.conversations[action.val.conversationId];
      const loadedMessages =
        conversation.messages.messageList.length === 1
          ? []
          : conversation.messages.messageList;
      const newMessageList = [
        ...loadedMessages,
        ...action.val.messages.messageList
      ];

      const updatedConversation = {
        ...conversation,
        messages: {
          page: action.val.messages.page,
          pages: action.val.messages.pages,
          messageList: newMessageList
        }
      };
      return {
        ...state,
        conversations: sortConversations({
          ...state.conversations,
          [action.val.conversationId]: updatedConversation
        })
      };
    case OnGotNewMessage:
      let prevConversation = state.conversations[action.val.conversationId];
      //remove. do better check
      //fetch conversations, if conv not found
      if (!prevConversation) {
        MessageFacade.loadConversations();
        //alert('in not preview!!');
        //  prevConversation = {messages: {messageList: []}};
        return state;
      }

      prevConversation.messages.messageList.unshift({
        ...action.val,
        createdAt: new Date()
      });

      return {
        ...state,
        totalUnread: state.totalUnread + 1,
        conversations: sortConversations({
          ...state.conversations,
          [action.val.conversationId]: {
            ...prevConversation,
            unread: {
              ...prevConversation.unread,
              [action.val.receiver]: prevConversation.unread[
                action.val.receiver
              ]
                ? prevConversation.unread[action.val.receiver] + 1
                : 1
            }, //todo

            messages: {
              ...prevConversation.messages,
              messageList: prevConversation.messages.messageList
            }
          }
        })
      };

    default:
      return state;
  }
}
