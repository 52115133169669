import firebase, {getIdToken} from './firebase';
import axios from 'axios';
import store from '../redux/store';
import {SetConversations, SetMessages} from '../redux/reducers/messageReducer';
import socketIOClient from 'socket.io-client';
import {OnGotNewMessage} from '../redux/reducers/messageReducer';
import {chatToast} from '../utils/toast';
import {errorLog} from '../utils/logger';

class SocketFacade {
  constructor() {}
  emitMessage(message) {
    //todo: only resolve if message is confirmly sent
    return new Promise((resolve, reject) => {
      if (this.socket) {
        if (message.conversationId && message.receiver) {
          this.socket.emit('newMessage', message);
          store.dispatch({type: OnGotNewMessage, val: message});
          resolve();
          //return true;
        } else {
          //log
          errorLog('SocketFacade.emitMessage', message);
          reject();
        }
      }
      return false;
      //clear state
    });
  }

  connect(userID) {
    if (!this.socket) {
      var socket = socketIOClient.connect('/', {query: 'userID=' + userID});

      /* if(!socket){
        setTimeout(()=>{
          this.connect()
        })
        return;
      }*/
      this.socket = socket;
      this.subscribe(userID);
      /* socketIOClient.Socket.on('close',()=>{

        setTimeout(() => {
       
        }, 1000);
      })*/
      /*
    if(this.socket){
        this.socket.on('refreshMessages', data => {
            this.setState({messageList: data});
          });
    }
*/
    }
  }

  subscribe(userID) {
    if (this.socket) {
      this.socket.on('gotNewMessage', data => {
        if (!window.location.pathname.includes('beskeder')) {
          chatToast({header: data.author.name, message: data.content});
        }
        store.dispatch({type: OnGotNewMessage, val: data});
      });
    }
  }
}
const socket = new SocketFacade();
export default socket;

/** add socket token
 * const socket = io('http://localhost?token=abc');

// server-side
const io = require('socket.io')();

// middleware
io.use((socket, next) => {
  let token = socket.handshake.query.token;
  if (isValid(token)) {
    return next();
  }
  return next(new Error('authentication error'));
});

// then
io.on('connection', (socket) => {
  let token = socket.handshake.query.token;
  // ...
});
 * 
 * 
 */
