import React, {Component} from 'react';
import {connect} from 'react-redux';
import Feed from '../Feed/Feed';
import {SetOnMainPage} from '../../redux/reducers/mainReducer';
import './Main.css';
class Main extends Component {
  componentWillMount() {
    //¨  DataStore.fetchFeed();
    this.props.setMainPage(true);
  }
  componentDidMount() {
    // firebaseAnalytics.logEvent('visited_main_page');
    // errorLog('Main.componentDidMount', {message: 'this is a test error'});
    // warningLog('Main.componentDidMount', {message: 'this is a shitty error'});
  }
  componentWillUnmount() {
    this.props.setMainPage(false);
  }
  render() {
    const {postType} = this.props;
    return (
      <div className="App">
        {postType === 'SERVICE' ? (
          <div className="feed-explanation-container">
            <p className="feed-explanation-container-title">
              Her ser du opslag oprettet af firmaerer og freelancer
            </p>
            <p>
              {' '}
              Dem kan du kontakte direkte, hvis de tilbyder en service som du
              eftersøger.
            </p>
          </div>
        ) : (
          <div className="feed-explanation-container">
            <p className="feed-explanation-container-title">
              Her ser du opslag oprettet af hjælpsøgende
            </p>
            <p>
              Er du freelancer eller har du en virksomhed, kan du kontakte
              opgavernes ejere og give et bud.
            </p>
          </div>
        )}

        <Feed history={this.props.history} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    postType: state.feed.filters.postType === 'SERVICE' ? 'SERVICE' : 'TASK'

    /* total:
      state.feed.filters.postType === 'SERVICE'
        ? state.feed.serviceFeed.total
        : state.feed.taskFeed.total,
    recentAddedTasks: state.task.recentAddedTasks,
    onMainPage: state.main.onMainPage
    // total: state.filter.total*/
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setMainPage: val => {
      dispatch({type: SetOnMainPage, val: val});
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
