import React from 'react';
import {
  Button,
  Header,
  Checkbox,
  Label,
  Card,
  Divider
} from 'semantic-ui-react';

import './TutorialSteps.css';
function Step({header, description, stepNumber, index}) {
  return (
    <div className="col-12 col-md-4 steps-item ">
      <Card
        className="steps-card-style"
        raised
        // extra={<img src="/icons/One.png" />}
        header={
          <div className="step-card-header">
            <Label className="step-label">{index}</Label>
            {/* <img src={`/icons/${stepNumber}.png `} />*/}
            <Card.Header>{header}</Card.Header>
          </div>
        }
        //  meta="Opret profil"
        description={description}
      />
    </div>
  );
}
function TutorialSteps() {
  return (
    <div className="row steps-container ">
      <Step
        header="Opret profil"
        stepNumber="One"
        description="Start med at oprette en profil med email eller Facebook-login"
        index="1"
      />
      <Step
        header="Opret opgave/service"
        stepNumber="Two"
        description="Opret en opgave hvis du søger hjælp. Eller opret en service, hvis du tilbyder hjælp. På forsiden, kan du se oprettet opgaver og services."
        index="2"
      />
      <Step
        header="Få tilbud"
        stepNumber="Three"
        description="Kontakt eller afvent at blive kontaktet ang. din opgave/service."
        index="3"
      />
    </div>
  );
}

export default TutorialSteps;
