import React, {Component} from 'react';
//import * as firebase from 'firebase';
import firebase from '../../Data/firebase';
import DataStore from '../../Data/DataStore';
import './Login.css';
import {connect} from 'react-redux';
import {
  SET_IS_LOADING,
  SET_LOGIN_MODAL
} from '../../redux/reducers/loginReducer';
import Loading from '../reuseable/Loading/Loading';
import {SetEmailVerification} from '../../redux/reducers/userReducer';
import {Button, Form, Popup, Icon, Divider, Header} from 'semantic-ui-react';
import {Redirect} from 'react-router-dom';
import {successToast, errorToast, warningToast} from '../../utils/toast';
import translateFirebaseError from '../../utils/translateFirebaseError';
import {errorLog} from '../../utils/logger';
const testState = {
  mail: 'test@test.dk',
  pass: 'test11'
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: '',
      pass: '',
      remember: false,
      policiesAgreed: false
    };

    //this._handleUserVerifying = this._handleUserVerifying.bind(this);
    this._handleSignIn = this._handleSignIn.bind(this);
  }
  componentDidMount() {
    // setTimeout( this._handleSignIn(),2000)
    if (!process.env.PUBLIC_URL.includes('heelp.dk')) {
      this.setState({
        mail: testState.mail,
        pass: testState.pass
      });
    }
  }
  _handleMailChange(event) {
    this.setState({mail: event.target.value});
  }

  _handlePasswordChange(event) {
    this.setState({pass: event.target.value});
  }

  _handleRememberMedChange(event) {
    this.setState({remember: event.target.value});
  }

  _handleSignIn() {
    this.props.setLoading(true);
    const {mail, pass} = this.state;

    firebase
      .auth()
      .signInWithEmailAndPassword(mail, pass)
      .then(res => {
        if (res) {
          if (res.user && res.user.emailVerified === false) {
            //this.props.setEmailVerification(false)
            res.user.sendEmailVerification().then(function() {});
          } else {
            this.props.setEmailVerification(true);
          }
          res.user
            .getIdToken()
            .then(idToken => {
              //check if user already exists as facebook user. IF yes deny login adn ask to login with facebook
              // this._handleUserVerifying(idToken); //get user ínfo from elilla server
              DataStore.handleSignIn(idToken);
            })
            .catch(error => {
              errorLog('Login._handleSignIn.getIdToken', error);
            });
        }
      })
      .catch(er => {
        errorLog('Login._handleSignIn.signInWithEmailAndPassword', er);
        alert('Forkert adgangskode eller email!');
        this.props.setLoading(false);

        //this.props.showLoading();
      });
  }
  _handleFacebookLogin = async () => {
    if (this.state.policiesAgreed) {
      var provider = new firebase.auth.FacebookAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async res => {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var token = res.credential.accessToken;
          // The signed-in user info.
          var user = res.user;
          let idToken = await res.user.getIdToken();

          if (res.additionalUserInfo.isNewUser) {
            //TODO: check if email is already used. If yes, then don't create.
            //create user in elillabackend
            const loginEmail = res.user.email;
            //todo: optimize performance by doing one request instead of two. Merge .getUserByMail and .createElillaUser in one
            const elillaUserWithSameMail = await DataStore.getUserByMail({
              idToken,
              mail: loginEmail
            });
            //TODO: use this method? DataStore.handleSignIn(idToken); //get user ínfo from elilla server
            //  _handleUserVerifying
            if (elillaUserWithSameMail) {
              //elillaUserWithSameMail is true
              //delete firebase user firebase.auth().currentUser;
              warningToast({
                header: 'Kunne ikke logge ind',
                message: `Email: ${loginEmail} bruges i en anden konto.`,
                autoClose: 15000
              });
            } else {
              let newUser = {
                name: res.additionalUserInfo.profile.first_name,
                lastName: res.additionalUserInfo.profile.last_name,
                phone: res.user.phoneNumber,
                mail: res.user.email,
                idToken,
                photoURL: res.user.photoURL
              };
              ///res.additionalUserInfo.profile.picture.data.url
              await DataStore.createElillaUser(newUser);

              successToast({header: 'Du er logget ind.'});
              this.props.setLoginModal(false);
            }
          } else {
            //check if elilla user exists:

            //  const user DataStore
            //login only
            // await this._handleUserVerifying(idToken);
            DataStore.handleSignIn(idToken).then(() => {
              successToast({header: 'Du er logget ind.'});
              this.props.setLoginModal(false);
            });

            //handleSignIn
          }

          // ...
        })
        .catch(function(error) {
          translateFirebaseError(error);
          /* errorToast({
            header: 'Noget gik galt. Prøv igen'
          });*/
          errorLog('_handleFacebookLogin.catch', error);
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    } else {
      errorToast({
        header: 'Du skal acceptere betingelserne for at fortsætte'
      });
    }
  };
  _handleUserVerifying(idToken) {
    try {
      //get user ínfo from elilla server
      DataStore.handleSignIn(idToken);
    } catch (error) {
      errorLog('error', error);
    }
  }

  render() {
    let user = this.props.user;
    const isSmallScreen = window.innerWidth <= 800;

    if (this.props.isLoading) {
      return (
        <Loading
          fullpage={true}
          onDismiss={() => this.props.setLoading(false)}
        />
      );
    }

    if (user.isLoggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <div className=" card-body signUpForm">
        <Popup
          trigger={
            <Form.Input
              fluid
              label="Mail adresse"
              placeholder="E-mail Address"
              name="email"
              type="text"
              onChange={this._handleMailChange.bind(this)}
              value={this.state.mail}
            />
          }
          content="Skriv din email."
        />
        <Popup
          trigger={
            <Form.Input
              className="mt-3"
              fluid
              label="Password"
              placeholder="Password"
              name="password"
              type="password"
              onChange={this._handlePasswordChange.bind(this)}
              value={this.state.pass}
            />
          }
          content="Indtast password"
        />
        {/*
        <Form.Checkbox
          label="husk mig"
          className="mt-3"
          onChange={this._handleRememberMedChange.bind(this)}
          value={this.state.remember}
/>*/}

        <label className="sign-up-login-btn-link mt-3">
          <p
            onClick={() => {
              this.props.showForgotView();
            }}
            className="pointer"
          >
            {' '}
            Har du glemt din adgangskode? <span>Gendan </span>
          </p>
        </label>

        <Button
          className="success-button mt-2"
          fluid
          onClick={() => this._handleSignIn()}
        >
          Login med email
        </Button>
        <Divider className="mt-5" horizontal>
          <Header as="h4">eller</Header>
        </Divider>
        <Button
          color="facebook"
          fluid
          className="mt-2"
          onClick={() => this._handleFacebookLogin()}
        >
          <Icon name="facebook" size="large" /> Login med Facebook
        </Button>
        <div className="accept-policy-box mt-4">
          <Form.Checkbox
            onChange={(event, data) => {
              this.setState({policiesAgreed: data.checked});
            }}
            value={this.state.policiesAgreed}
          />
          <p>Jeg accepterer&nbsp;</p>{' '}
          <a href="/brugerbetingelser">vilkår og betingelser.</a>
        </div>
        {/*
       Facebook login disabled. TODO: fix double email
  

      */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginModal: value => {
      dispatch({
        type: SET_LOGIN_MODAL,
        val: value
      });
    },
    setLoading: value => {
      dispatch({
        type: SET_IS_LOADING,
        val: value
      });
    },
    setEmailVerification: value => {
      dispatch({
        type: SetEmailVerification,
        val: value
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
