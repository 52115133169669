import React from 'react';
import moment from 'moment';
import './Message.css';

export default function Message(props) {
  const {
    content,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp,
    timeStamp,
    noMarginBottom,
    marginTop
  } = props;

  const friendlyTimestamp = moment(timeStamp).format('LLLL');
  return (
    <div
      className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`,
        `${noMarginBottom ? ' mb-0 ' : ''}`,
        `${marginTop ? ' mt-2 ' : ''}`
      ].join(' ')}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      <div className="bubble-container">
        <div className="bubble" title={friendlyTimestamp}>
          {content}
        </div>
      </div>
    </div>
  );
}
