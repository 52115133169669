import React, {Component, useEffect, useState} from 'react';
import PostItem from '../PostItem/PostItem';
import './Feed.css';
import InfiniteScroll from 'react-infinite-scroller';
import DataStore from '../../Data/DataStore';
import {connect} from 'react-redux';
import {
  RemoveFilter,
  AddFilter,
  AddPostType
} from '../../redux/reducers/feedReducer';
import Loading from '../reuseable/Loading/Loading';
import {useLocation, useParams} from 'react-router-dom';
import queryString from 'query-string';

//class Feed extends Component {

const Feed = props => {
  const [activeItem, setActiveItem] = useState(props.postType || 'TASK');

  const search = useLocation().search;

  useEffect(() => {
    const {feed} = queryString.parse(search);
    const feedString = (feed || '').toLocaleLowerCase();

    const urlFeedType =
      feedString == 'opgaver'
        ? 'TASK'
        : feedString == 'service'
        ? 'SERVICE'
        : null;
    if (urlFeedType && urlFeedType !== activeItem) {
      //change feed in state
      onPostTypeChange(urlFeedType);
    }
  }, [search]);
  const loadMoreItems = () => {
    DataStore.fetchFeed();
  };

  const onPostTypeChange = name => {
    props.addPostType({
      name: 'postType',
      value: name
    });
  };

  let posts = props.postType === 'TASK' ? props.taskPosts : props.servicePosts; //props.list;
  let hasMore =
    props.postType === 'TASK' ? props.taskHasMore : props.serviceHasMore;
  if (posts) {
    return (
      <React.Fragment>
        <InfiniteScroll
          id="feedContainer"
          pageStart={0}
          dataLength={posts.length} //This is important field to render the next data
          loadMore={loadMoreItems}
          hasMore={hasMore}
          loader={<Loading text="Henter" />}
          endMessage={
            <p style={{textAlign: 'center'}}>
              <b>Der er ikke flere opslag.</b>
            </p>
          }
          scrollableTarget="body"
        >
          <div className="row posts-container">
            {posts.map((item, index) => (
              <PostItem
                index={index}
                postItem={item}
                postType={props.postType === 'SERVICE' ? 'service' : 'opgaver'}
              />
            ))}
          </div>
        </InfiniteScroll>
      </React.Fragment>
    );
  } else {
    return <Loading text="Henter" />;
  }
  //  }
};

function mapStateToProps(state) {
  return {
    servicePosts: state.feed.serviceFeed.feed,
    taskPosts: state.feed.taskFeed.feed,
    taskHasMore: state.feed.taskFeed.page <= state.feed.taskFeed.pages,
    serviceHasMore: state.feed.serviceFeed.page <= state.feed.serviceFeed.pages,
    postType: state.feed.filters.postType === 'SERVICE' ? 'SERVICE' : 'TASK',
    filters: state.feed.filters
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addPostType: value => {
      dispatch({
        type: AddPostType,
        val: value
      });
      DataStore.fetchFeed();
    },
    addFilter: value => {
      dispatch({
        type: AddFilter,
        val: value
      });
      DataStore.fetchFeed();
    },
    removeFilter: value => {
      dispatch({
        type: RemoveFilter,
        val: value
      });

      DataStore.fetchFeed();
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Feed);
