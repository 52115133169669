import {SetTaskPageTask} from '../reducers/taskReducer';
import {SetServicePageService} from '../reducers/serviceReducer';
import {SETUSERDATA} from '../reducers/userReducer';
import {SetFetchData} from '../reducers/feedReducer';
import {
  OnServiceFeedResponse,
  OnTaskFeedResponse
} from '../reducers/feedReducer';

class ActionFactory {
  setTaskPageTask(task) {
    return {
      type: SetTaskPageTask,
      val: task
    };
  }
  setServicePageService(task) {
    return {
      type: SetServicePageService,
      val: task
    };
  }

  setUserData(user) {
    return {
      type: SETUSERDATA,
      val: user
    };
  }
  setFetchData(value) {
    return {
      type: SetFetchData,
      val: value
    };
  }
  onServiceFeedResponse(value) {
    return {
      type: OnServiceFeedResponse,
      val: value
    };
  }
  onTaskFeedResponse(value) {
    return {
      type: OnTaskFeedResponse,
      val: value
    };
  }
}

export default new ActionFactory();
