import React, {Component} from 'react';
import './FilterMenu.css';
import {connect} from 'react-redux';
import {ToggleNavbarFilter} from '../../redux/reducers/mainReducer';
import {
  RemoveFilter,
  AddFilter,
  ResetFilters,
  AddPostType
} from '../../redux/reducers/feedReducer';
import DataStore from '../../Data/DataStore';
import {Select, Header, Divider, Label, Form, Icon} from 'semantic-ui-react';
import Drawer from '@material-ui/core/Drawer'; //todo remove package
import {enterKeyHandler} from '../../utils/keyHandlers';
const categories = require('../../Data/constants/Categories.json');

class FilterMenu extends Component {
  render() {
    const filters = this.props.filters;
    let categoryOptions = categories.map(cat => {
      return {key: cat.type.toLowerCase(), value: cat.type, text: cat.text};
    });
    categoryOptions.unshift({key: 'all', value: null, text: 'Alle'});
    return (
      <Drawer
        anchor="left"
        open={this.props.showFilterMenu}
        onClose={() => this.props.toggleNavbarFilter(false)}
      >
        <div className="container-fluid  filter-menu-container">
          <div className={'drawer-header'}>
            <div
              className="reset-filters-btn "
              onClick={() => {
                this.props.resetFilters();
              }}
            >
              Nulstil
            </div>

            <Header size="medium">Filtrer</Header>

            <div
              className="drawer-close-icon"
              onClick={() => this.props.toggleNavbarFilter(false)}
              tabIndex={0}
              role="button"
              onKeyDown={event =>
                enterKeyHandler(event, () =>
                  this.props.toggleNavbarFilter(false)
                )
              }
            >
              {/* <CloseIcon />*/}
              <Icon name="close" size="large" />
            </div>
          </div>

          <Form>
            <div className="container-fluid p-1">
              <div className="row">
                <Form.Field className="w-100">
                  <label>Type af opslag</label>
                  <Select
                    placeholder="Opgaver"
                    options={taskTypes}
                    onChange={(target, value) => {
                      if (value.value) {
                        if (filters.ownertype) {
                          this.props.removeFilter({name: 'ownertype'});
                        }

                        this.props.addPostType({
                          name: 'postType',
                          value: value.value
                        });
                      } else {
                        this.props.removeFilter({name: 'postType'});
                      }
                    }}
                  />
                </Form.Field>
                {/* add workertype to tasks*/}
                {/*filters.postType === 'SERVICE' && (
                  <Form.Field className="w-100">
                    <label>Type af arbejder</label>
                    <Select
                      placeholder="Alle"
                      options={workerTypes}
                      onChange={(target, value) => {
                        if (value.value) {
                          this.props.addFilter({
                            name: 'ownertype',
                            value: value.value
                          });
                        } else {
                          this.props.removeFilter({name: 'ownertype'});
                        }
                      }}
                    />
                  </Form.Field>
                    )*/}
                <Form.Field className="w-100">
                  <label>Kategori</label>
                  <Select
                    placeholder="Alle"
                    options={categoryOptions}
                    onChange={(target, value) => {
                      if (value.value) {
                        this.props.addFilter({
                          name: 'category',
                          value: value.value
                        });
                      } else {
                        this.props.removeFilter({name: 'category'});
                      }
                    }}
                  />
                </Form.Field>
              </div>
              <div className="row">
                <div className="col-6" />
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
    );
  }
}

function mapStateToProps(state) {
  return {
    showFilterMenu: state.main.showFilterMenu,
    filters: state.feed.filters
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addPostType: value => {
      dispatch({
        type: AddPostType,
        val: value
      });
      DataStore.fetchFeed(); // is this neccesary?
    },
    toggleNavbarFilter: value => {
      dispatch({
        type: ToggleNavbarFilter,
        val: value
      });
    },
    addFilter: value => {
      dispatch({
        type: AddFilter,
        val: value
      });

      DataStore.fetchFeed();
    },
    removeFilter: value => {
      dispatch({
        type: RemoveFilter,
        val: value
      });

      DataStore.fetchFeed();
    },
    resetFilters: () => {
      dispatch({
        type: ResetFilters
      });

      dispatch({
        type: ToggleNavbarFilter,
        val: false
      });
      DataStore.fetchFeed();
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
const taskTypes = [
  {value: 'TASK', text: 'Opgaver'},
  {value: 'SERVICE', text: 'Services'}
];
const workerTypes = [
  {key: 'freelancer', value: 'FREELANCER', text: 'Freelancer'},
  {key: 'company', value: 'COMPANY', text: 'Firma'}
];
const paymentTypes = [
  {value: 'FIXED', text: 'Engangsbetaling'},
  {value: 'HOURLY', text: 'Timeløn'}
];
