import React, {Component} from 'react';
import {connect} from 'react-redux';
import './MessageInput.css';
import {Icon, Button, TextArea, Segment, Header, Form} from 'semantic-ui-react';
import SocketFacade from '../../Data/SocketFacade';
import MessageFacade from '../../Data/MessageFacade';
import withNewConversation from '../reuseable/hoc/NewConversation';
import {errorLog} from '../../utils/logger';

import TextareaAutosize from 'react-textarea-autosize';
/**
 * if isModal is passed check if the conversation dosen't exist first and create one if so.
 */

class MessageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {
        author: {
          _id: this.props.user._id,
          name: this.props.user.name
        } /*remove name*/,
        receiver: null,
        content: ''
        //conversationId: this.props.selectedConversationId
      }
    };
  }
  async handleSend() {
    if (this.props.isModal) {
      this.props.SetNewConversation({loading: true});

      let selectedConversationId = null;
      let receiverID = null;

      try {
        let userID = this.props.user._id;
        receiverID = this.props.newConversationModal.receiverID;
        // check and create conversation if it dosent exist.
        selectedConversationId = await MessageFacade.postNewConversation(
          userID,
          receiverID
        );
      } catch (error) {
        errorLog('handleSend.error', error);
      }

      MessageFacade.sendMessage({
        ...this.state.message,
        conversationId: selectedConversationId,
        receiver: receiverID ? receiverID : this.state.message.receiver
      })
        .then(() => {
          this.props.SetNewConversation({loading: false, success: true});

          this.setState({message: {...this.state.message, content: ''}});
          //set loading false
        })
        .catch(() => {
          this.props.SetNewConversation({loading: false, success: false});
        });
    } else {
      const selectedConversation = this.props.conversations[
        this.props.selectedConversationId
      ];
      const messageToEmit = {
        ...this.state.message,
        conversationId: this.props.selectedConversationId,
        receiver: selectedConversation.with._id
      };

      SocketFacade.emitMessage(messageToEmit).then(() => {
        this.setState({message: {...this.state.message, content: ''}});
      });
    }
  }

  render() {
    const TextInput = this.props.isModal ? TextArea : TextareaAutosize;
    const selectedConversation = this.props.conversations[
      this.props.selectedConversationId
    ];

    const nonDeletedUser =
      this.props.isModal ||
      (selectedConversation &&
        selectedConversation.with &&
        selectedConversation.with._id);
    return (
      <div
        className={'compose' + (this.props.isModal ? ' modal-style' : '' + '')}
      >
        {nonDeletedUser && (
          <Form className="compose-form">
            <TextInput
              onChange={evt =>
                this.setState({
                  message: {...this.state.message, content: evt.target.value}
                })
              }
              value={this.state.message.content}
              placeholder="Skriv en besked.."
              rows={4}
            />
            <Button
              type="submit"
              style={{marginLeft: '7px'}}
              onClick={this.handleSend.bind(this)}
            >
              <Icon name="send" />
            </Button>
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    conversations: state.chats.conversations || {},
    selectedConversationId: state.chats.selectedConversationId,
    newConversationModal: state.chats.newConversationModal
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNewConversation(MessageInput));
