import React from 'react';
import Modal from 'react-responsive-modal';
import Login from './Index';
import {connect} from 'react-redux';
import {SET_LOGIN_MODAL} from '../../redux/reducers/loginReducer';
import './Modal.css';

export class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCloseSignIn = () => {
    this.props.setLoginModal(false);
  };
  render() {
    return (
      <Modal
        open={this.props.loginModal}
        onClose={this.onCloseSignIn}
        center
        showCloseIcon={!this.props.isLoading}
        classNames={{
          modal:
            'login-modal-container ' +
            (this.props.isLoading ? 'modalLoadingStyle' : ''),
          closeButton: 'closeBtnClassname'
        }}
      >
        <Login />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
    loginModal: state.login.loginModal
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginModal: value => {
      dispatch({
        type: SET_LOGIN_MODAL,
        val: value
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
