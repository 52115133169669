import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Form,
  Popup,
  Message,
  TextArea,
  Icon,
  Divider,
  Header,
  Segment
} from 'semantic-ui-react';
import {HandleProfileChange} from '../../redux/reducers/userReducer';
//import * as firebase from 'firebase';
import firebase from '../../Data/firebase';
import {errorToast, successToast, warningToast} from '../../utils/toast';
import {errorLog} from '../../utils/logger';

class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      password: '',
      passwordConfirmed: null,
      passwordValidated: false
    };
  }
  validatePassword(passwordConfirmed) {
    let passwordValidation =
      (this.state.password.localeCompare(passwordConfirmed) === 0
        ? true
        : false) && this.state.password.length >= 5;
    this.setState({passwordValidated: passwordValidation});
  }

  handlePasswordUpdate = async () => {
    if (!this.state.passwordValidated) {
      errorToast({
        header: 'Password matcher ikke! Prøv igen.'
      });
      return;
    } else {
      //updates users email in firebase
      firebase
        .auth()
        .signInWithEmailAndPassword(
          this.props.user.mail,
          this.state.oldPassword
        )
        .then(res => {
          res.user
            .updatePassword(this.state.password)
            .then(resp => {
              successToast({
                header: 'Adgangskoden er opdateret.'
              });
            })
            .catch(er => {
              errorToast({
                header: 'Der er sket en fejl. Adgangskoden er ikke opdateret.'
              });
              errorLog(
                'PasswordChange.handlePasswordUpdate.updatePassword',
                er
              );
            });
        })
        .catch(error => {
          errorToast({
            header:
              'Der er sket en fejl. Adgangskoden er ikke opdateret.' +
              error.message
          });
          errorLog(
            'PasswordChange.handlePasswordUpdate.signInWithEmailAndPassword',
            error
          );
        });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Header size="medium" as="h2">
          Ændre adgangskode
        </Header>
        <Divider />

        <Form>
          <Popup
            trigger={
              <Form.Input
                fluid
                label="Nuværrende password"
                placeholder="Nuværrende password"
                name="oldPassword"
                type="password"
                required
                onChange={event => {
                  this.setState({oldPassword: event.target.value});
                }}
                value={this.state.oldPassword}
              />
            }
            content="Skriv dit nuværrende password"
          />
          <Divider />
          <Popup
            trigger={
              <Form.Input
                fluid
                label="Ny adgangskode"
                placeholder="Password"
                name="password"
                type="password"
                required
                onChange={event => {
                  this.setState({password: event.target.value});
                }}
                value={this.state.password}
              />
            }
            content="Skriv et password"
          />

          <Popup
            trigger={
              <Form.Input
                fluid
                label="Gentag nye adgangskode"
                placeholder="Confirm Password"
                name="passwordconfirm"
                type="password"
                required
                value={this.state.passwordConfirmed}
                onChange={event => {
                  this.setState({passwordConfirmed: event.target.value});
                  this.validatePassword(event.target.value);
                }}
                className={
                  'form-data-input ' +
                  (!this.state.passwordValidated &&
                  this.state.password.length !== 0
                    ? ' form-input-error'
                    : '')
                }
              />
            }
            content="Skriv dit password igen"
          />
          {/*this.state.passwordValidated || !this.state.passwordConfirmed*/}
        </Form>

        <div className="edit-profile-btn-container">
          <Button
            className="upload-btn-style success-button"
            onClick={() => {
              this.handlePasswordUpdate();
            }}
          >
            Opdater adgangskode
          </Button>

          <a
            style={{marginLeft: '7px', cursor: 'pointer'}}
            onClick={() => {
              this.props.hidePasswordChange();
            }}
          >
            Fortyd redigering af adgangskode
          </a>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleProfileChange: value => {
      dispatch({
        type: HandleProfileChange,
        val: value
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
