import React, {useState} from 'react';
import {Button, Header, Icon, Image, Modal} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {SelectConversation} from '../../redux/reducers/messageReducer';
import MessageFacade from '../../Data/MessageFacade';
import {SetNewConversation} from '../../redux/reducers/messageReducer';
import MessageInput from '../MessagingNew/MessageInput';
import './MessageModal.css';
import SocketFacade from '../../Data/SocketFacade';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import {Link} from 'react-router-dom';
import Loading from '../reuseable/Loading/Loading';

function MessageModal(props) {
  let conversations = Object.values(props.conversations);
  let newConversationModal = props.newConversationModal;
  function renderContent() {
    let {loading, success} = newConversationModal;

    if (loading) {
      return (
        <React.Fragment>
          <Header icon="mail" content="Sender.." />

          <Modal.Content className="d-md-flex justify-content-center">
            <Loading />
          </Modal.Content>
          <Modal.Actions></Modal.Actions>
        </React.Fragment>
      );
    }
    if (!loading && success && success !== null) {
      return (
        <React.Fragment>
          <Header icon="mail" content="Sendt." />

          <Modal.Content>
            <Icon name="check" />
            Ny besked er sendt!
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={props.closeConversationModal}>Luk modal</Button>
            <Button
              onClick={props.closeConversationModal}
              as={Link}
              to="/beskeder"
            >
              Gå til beskeder
            </Button>
          </Modal.Actions>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Header icon="mail">
          <p>
            {' '}
            {'Ny besked til ' +
              newConversationModal.name +
              ' ' +
              newConversationModal.lastName}{' '}
          </p>
        </Header>
        <Modal.Content className="d-md-flex">
          <MessageInput isModal socket={props.socket} />
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </React.Fragment>
    );
  }

  return (
    <Modal
      open={newConversationModal.open}
      onClose={props.closeConversationModal}
      style={{
        height: 'fit-content',
        margin: 'auto',
        marginTop: 'auto'
      }}
      closeIcon
    >
      {renderContent()}
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.userData,
    chats: state.chats,
    conversations: state.chats.conversations || {},
    selectedConversationId: state.chats.selectedConversationId,
    newConversationModal: state.chats.newConversationModal
  };
}

const mapDispatchToProps = dispatch => {
  return {
    openConversationModal: value => {
      dispatch({
        type: SetNewConversation,
        val: {...value, open: true}
      });
    },
    closeConversationModal: value => {
      dispatch({
        type: SetNewConversation,
        val: {
          open: false,
          userID: '',
          receiverID: '',
          name: '',
          lastName: '',
          success: null
        }
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
