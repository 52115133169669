import React from 'react';
import {Header, Icon} from 'semantic-ui-react';
import './About.css';
import YoutubeVideo from '../reuseable/YoutubeVideo/YoutubeVideo';
import TutorialSteps from '../TutorialSteps/TutorialSteps';
import PageHead from '../reuseable/PageHead/PageHead';
import {useScrollTop} from '../../utils/useScrollTop';

export default () => {
  useScrollTop();
  return (
    <>
      <PageHead title="Om os" />
      <div>
        <Header>Om Heelp.dk</Header>
        <p className="mb-5">
          På heelp.dk kan du tilføje og søge efter opgaver inden for mange
          forskellige brancher, herunder VVS, flytning, håndværk og elektronik.
          For at benytte platformen skal du oprette en profil, enten ved hjælp
          af email eller ved at logge ind med din Facebook-konto. Når din profil
          er oprettet, har du mulighed for at tilføje dine services til
          platformen, så potentielle kunder kan se dine opslag og kontakte dig
          om disse. Hvis du søger hjælp til en opgave kan du oprette en opgave
          så virksomheder og freelancere kan kontakte dig.
        </p>
        {/*      <p>
          Heelp.dk er en platform hvor du kan tilbyde og få hjælp til
          praktiske opgaver så som VVS,flytning, håndværk, elektronik og meget
          andet!
        </p>
        <p>
          Tilbyd eller søg hjælp til praktiske opgaver på Heelp.dk!
        </p>
        <p>
          Du skal blot oprette en profil, med email eller facebook, dernæst kan
          I tilføje de service som I tilbyder til platformen, så potentielle
          kunder kan se jeres opslag og kontakte jer herom.{' '}
  </p>*/}
        <YoutubeVideo
          style={{position: 'unset', width: 'fit-content', margin: 'auto'}}
        />
        <p className="how-it-works">Sådan virker det</p>

        <TutorialSteps />
      </div>
    </>
  );
};
