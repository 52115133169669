import React, {Component} from 'react';
import {connect} from 'react-redux';
import './TaskDetail.css';
import 'moment/locale/da';
import ImageGallery from '../ImageGallery/ImageGallery';
import {
  Header,
  TextArea,
  Label,
  Form,
  Button,
  Icon,
  Divider,
  Message
} from 'semantic-ui-react';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import PostItemsDisplay from '../reuseable/PostItemsDisplay/PostItemsDisplay';
import DataStore from '../../Data/DataStore';
import {Link} from 'react-router-dom';
import withNewConversation from '../reuseable/hoc/NewConversation';
import {successToast, warningToast, errorToast} from '../../utils/toast';

import Share from '../reuseable/Share/Share';

class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageYOffset: null,
      isFavourite: false
    };
  }
  componentWillMount() {
    let id = this.props.id; // TODO get id from params
    DataStore.fetchTaskTaskPageTask(id); //TODO search in taskReducer instead of api-call!
    this.setState({
      isFavourite: this.props.user.favourites.includes(id),
      pageYOffset: window.pageYOffset
    });
    //window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    window.scrollTo(this.state.pageYOffset, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      DataStore.fetchTaskTaskPageTask(this.props.id);
    }
  }
  handleAddFavourite() {
    if (this.props.user.isLoggedIn) {
      if (this.state.isFavourite) {
        DataStore.removeFromFavourites(this.props.task._id);
        this.setState({isFavourite: false});
      } else {
        DataStore.addToFavourites(this.props.task._id);
        this.setState({isFavourite: true});
      }
    } else {
      warningToast({header: 'Du skal logge ind for at tilføje favoritter'});
    }
  }

  render() {
    let task = this.props.task;
    if (task && task._id === this.props.id) {
      //TODO: implement image resizing (amazon lambda?)
      //Todo: image height
      const images = task.gallery.map(img => {
        return {original: img, thumbnail: img};
      }) || [
        {
          original: 'http://lorempixel.com/1000/600/nature/1/',
          thumbnail: 'http://lorempixel.com/250/150/nature/1/'
        }
      ];
      return (
        <React.Fragment>
          {!task.active && (
            <Message warning>
              <Message.Header>Ikke aktiv.</Message.Header>
              <p>Denne opgave er desværre ikke aktiv længere.</p>
            </Message>
          )}

          <div className="row task-detail-container">
            <div className="col-12"></div>
            <div className="col-12 col-md-12 col-lg-8 task-detail-image-gallery">
              <ImageGallery
                gallery={task.gallery}
                lazyLoad
                showPlayButton={false}
                _id={task._id}
              />
            </div>
            <div className={'col-12 col-md-12 col-lg-4 labelize-input h-100d'}>
              <Label className="mt-2" as="a" color="green" attached="top right">
                Opgave
              </Label>

              <Form>
                {task.owner._id === this.props.user._id && (
                  <Label as={Link} to={'/opgaver/' + task._id + '/rediger'}>
                    <Icon name="pencil" />
                    Rediger
                  </Label>
                )}
                <Header as="h2">{task.title || ''}</Header>
                <p>{task?.description || ''} </p>
                <Header className="mb-0" as="h4">
                  Lokation
                </Header>
                <p className="mt-0">
                  {task?.regions?.length > 0
                    ? task.regions
                        .map(reg => reg.charAt(0) + reg.toLowerCase().slice(1))
                        .join(', ')
                    : `${task?.location?.cityName + ','} ${
                        task?.location?.zipCode
                      }`}

                  {/*task.location?.cityName || ''*/}
                  {/*' ' + task.location?.zipCode || ''*/}
                </p>
                <Header className="mb-2" as="h4">
                  Udgiver
                </Header>
                <UserThumbnail
                  name={
                    (task.owner.name || '') + ' ' + (task.owner.lastName || ' ')
                  }
                  subtitle={task.owner.workerData.type || ''}
                  src={task.owner.photoURL || null}
                  userId={task.owner._id}
                  type="hori"
                />

                {task.owner._id != this.props.user._id && (
                  <Button
                    className="mt-4 green-btn-style"
                    fluid
                    onClick={() => {
                      if (this.props.user.isLoggedIn) {
                        this.props.contactUser({
                          receiverID: task.owner._id,
                          name: task.owner.name,
                          lastName: task.owner.lastName
                        });
                      } else {
                        //show toast
                        warningToast({
                          header: 'Du skal logge ind for at sende beskeder'
                        });
                      }
                    }}
                    disabled={!task.active}
                  >
                    Kontakt {task.owner.name || ''}
                  </Button>
                )}
                <div className=" mt-4 icon-display-container">
                  <div
                    style={{cursor: 'pointer'}}
                    onClick={this.handleAddFavourite.bind(this)}
                  >
                    <Icon
                      style={
                        this.state.isFavourite ? {color: 'var(--fersken)'} : {}
                      }
                      name={
                        'heart ' + (this.state.isFavourite ? '' : 'outline')
                      }
                      size="large"
                      color={this.state.isFavourite ? 'red' : 'grey'}
                    />
                    {this.state.isFavourite
                      ? 'Tilføjet til favoritter'
                      : 'Tilføj til favoritter'}
                  </div>

                  <Share />
                </div>
              </Form>
            </div>
          </div>
          <div className="col-12">
            <Divider />
          </div>
          <div className="row">
            <PostItemsDisplay
              title="Opgaver der ligner"
              items={this.props.similarTasks || []}
              postType="opgaver"
            />
          </div>
        </React.Fragment>
      );
    } else {
      return <div />;
    }
  }
}

function mapStateToProps(state) {
  return {
    task: state.task.taskPageTask,
    similarTasks: state.task.similarTasks,
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNewConversation(TaskDetail));
