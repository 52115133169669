const initialState = {
  recentAddedTasks: null,
  editTaskMode: false,
  taskPageTask: null,
  newTask: {
    category: 'ANDET',
    owner: null,
    title: '',
    description: '',
    gallery: [],
    localGallery: [],
    regions: [],
    location: {
      zipCode: '',
      cityName: '',
      street: ''
    },
    movingTaskData: {
      goods: [
        {name: 'Flyttekasse', amount: 5},
        {name: 'Sofa', amount: 1},
        {name: 'TV', amount: 2},
        {name: 'Bord', amount: 1}
      ],
      fromAddress: {
        location: {
          zipCode: '',
          cityName: '',
          street: ''
        },
        elevator: false,
        floors: ''
      },

      toAddress: {
        location: {
          zipCode: '',
          cityName: '',
          street: ''
        },
        elevator: false,
        floors: ''
      },
      similarTasks: []
    }
  }
};
export const SetRecentAddedTasks = 'SetRecentAddedTasks';
export const ResetRecentAddedTasks = 'ResetRecentAddedTasks';

export const SetTaskPageTask = 'SetTaskPageTask';
export const SetNewTaskCategory = 'SetNewTaskCategory';
export const SetNewTaskRegion = 'SetNewTaskRegion';

export const RemoveFromGoods = 'RemoveFromGoods';
export const AddToGoods = 'AddToGoods';
export const UpdateGallery = 'UpdateGallery';
export const UpdateLocalGallery = 'UpdateLocalGallery';
export const HandleNewTaskChange = 'HandleNewTaskChange';
export const ResetNewTask = 'ResetNewTask';
export const SetNewTask = 'SetNewTask';
export const RemoveLocalImage = 'RemoveLocalImage';
export const SetEditTaskMode = 'SetEditTaskMode';
export const RemoveUploadedImage = 'RemoveUploadedImage';
export const SetSimilarTasks = 'SetSimilarTasks';

export default function dispatch(state = initialState, action) {
  let newGoods = []; // make a separate copy of the array
  //let newFilters = {};

  switch (action.type) {
    case SetRecentAddedTasks:
      let loadedTasks = state.recentAddedTasks ? state.recentAddedTasks : [];
      return {
        ...state,
        recentAddedTasks: loadedTasks.concat(action.val)
      };
    case SetSimilarTasks:
      return {
        ...state,
        similarTasks: action.val
      };

    case ResetRecentAddedTasks:
      return {
        ...state,
        recentAddedTasks: null
      };

    case SetEditTaskMode:
      return {
        ...state,
        editTaskMode: action.val
      };
    case SetTaskPageTask:
      return {
        ...state,
        taskPageTask: action.val
      };

    case SetNewTaskCategory:
      return {
        ...state,
        newTask: {...state.newTask, category: action.val}
      };
    case SetNewTaskRegion:
      return {
        ...state,
        newTask: {...state.newTask, regions: action.val}
      };

    case SetNewTask:
      return {
        ...state,
        newTask: {
          ...state.newTask,
          ...action.val,
          movingTaskData: {
            ...state.newTask.movingTaskData,
            ...action.val.movingTaskData
          }
        }
      };
    case RemoveFromGoods:
      newGoods = [...state.newTask.movingTaskData.goods];
      newGoods.splice(action.val, 1);
      return {
        ...state,
        newTask: {
          ...state.newTask,
          movingTaskData: {...state.newTask.movingTaskData, goods: newGoods}
        }
      };

    case AddToGoods:
      newGoods = [...state.newTask.movingTaskData.goods];
      newGoods.push(action.val);
      return {
        ...state,
        newTask: {
          ...state.newTask,
          movingTaskData: {...state.newTask.fromAddress, goods: newGoods}
        }
      };
    case UpdateGallery:
      return {
        ...state,
        newTask: {
          ...state.newTask,
          gallery: [...state.newTask.gallery, ...action.val]
        }
      };
    case UpdateLocalGallery:
      return {
        ...state,
        newTask: {
          ...state.newTask,
          localGallery: [...state.newTask.localGallery, ...action.val]
        }
      };
    case RemoveLocalImage:
      let localImageGallery = [...state.newTask.localGallery];
      localImageGallery.splice(action.val, 1);

      return {
        ...state,
        newTask: {...state.newTask, localGallery: localImageGallery}
      };
    case RemoveUploadedImage:
      let imageGallery = [...state.newTask.gallery];
      imageGallery.splice(action.val, 1);
      return {
        ...state,
        newTask: {...state.newTask, gallery: imageGallery}
      };

    case HandleNewTaskChange:
      let newTask = {...state.newTask};
      let splitetArray = action.val.name.includes('.')
        ? action.val.name.split('.')
        : [action.val.name];

      let level1 = newTask[splitetArray[0]];
      let level2 = newTask[splitetArray[0]][splitetArray[1]];
      let level3 = {};
      switch (splitetArray.length) {
        case 1:
          newTask = {...newTask, [splitetArray[0]]: action.val.value};
          break;
        case 2:
          newTask = {
            ...newTask,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: action.val.value
            }
          };
          break;
        case 3:
          newTask = {
            ...newTask,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: {
                ...level2,
                [splitetArray[2]]: action.val.value
              }
            }
          };
          break;
        case 4:
          level3 = newTask[splitetArray[0]][splitetArray[1]][splitetArray[2]];
          newTask = {
            ...newTask,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: {
                ...level2,
                [splitetArray[2]]: {
                  ...level3,
                  [splitetArray[3]]: action.val.value
                }
              }
            }
          };
          break;
        default:
          break;
      }
      return {
        ...state,
        newTask: newTask
      };

    case ResetNewTask:
      return {
        ...state,
        newTask: {...initialState.newTask}
      };

    default:
      return state;
  }
}
