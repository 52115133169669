import React, {Component} from 'react';
import {connect} from 'react-redux';
import ImageUploader from '../reuseable/ImageUploader/ImageUploader';
import {
  HandleNewServiceChange,
  ResetNewService,
  UpdateServiceGallery,
  UpdateServiceLocalGallery,
  RemoveServiceLocalImage,
  RemoveServiceUploadedImage,
  AddRegion,
  RemoveRegion,
  SetNewServiceCategory,
  SetNewService,
  SetLocationArray
} from '../../redux/reducers/serviceReducer';
import {errorLog} from '../../utils/logger';

import './NewServiceComponent.css';
//import SvgMap from '../reuseable/SvgMap/SvgMap';
import DataStore from '../../Data/DataStore';
import {Header, Button, Label, Form, Popup} from 'semantic-ui-react';
import {successToast, warningToast, errorToast} from '../../utils/toast';
import Loading from '../reuseable/Loading/Loading';
const categories = require('../../Data/constants/Categories.json');

const regions = require('../../Data/constants/regions.json');

class NewServiceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successScreenModal: false,
      isLoading: false,
      modalOpen: false
    };
  }
  componentWillMount() {
    if (this.props.id && this.props.editMode) {
      //service
      this.setState({isLoading: true});

      DataStore.fetchService(this.props.id)
        .then(res => {
          let service = res.data;
          if (service.owner._id === this.props.user.userData._id) {
            this.props.setNewService(service);
          } else {
            this.props.history.push('/service/' + this.props.id);
          }
        })
        .catch(er => {
          errorLog('NewServiceComponent.fetchService', er);
        })
        .finally(() => this.setState({isLoading: false}));
    }
  }
  componentWillUnmount() {
    this.props.resetNewService();
  }

  renderDetailsForm() {
    return (
      <React.Fragment>
        <Popup
          trigger={
            <Form.Input
              fluid
              label="Titel"
              placeholder="Titel"
              name="title"
              value={this.props.newService.title}
              onChange={evt => this.props.handleNewServiceChange(evt.target)}
            />
          }
          content="Skriv en titel til din service"
        />
        <Popup
          trigger={
            <Form.Dropdown
              label="Kategori"
              placeholder="Vælg en kategori"
              defaultValue="ANDET"
              selection
              options={categories}
              onChange={(e, {value}) => {
                this.props.setCategory(value);
              }}
            />
          }
          content="Vælg en kategori"
        />

        <Popup
          trigger={
            <Form.TextArea
              label="Beskrivelse"
              placeholder="Beskriv din service"
              name="description"
              value={this.props.newService.description}
              onChange={evt => this.props.handleNewServiceChange(evt.target)}
            />
          }
          content="Beskriv dit service"
        />

        <Form.Dropdown
          className="mb-4"
          multiple
          label="Region"
          placeholder="Vælg de regioner hvor du kan udføre dine service."
          defaultValue="ALL"
          selection
          options={regions}
          value={this.props.newService.location}
          onChange={(e, {value}) => {
            this.props.setLocationArray(value);
          }}
        />
      </React.Fragment>
    );
  }

  renderPaymentForm() {
    const paymentOptions = [
      {value: 'HOURLY', text: 'Timeløn'},
      {value: 'FIXED', text: 'Engangsbetaling'}
    ];
    return (
      <Form.Group widths="equal">
        <Popup
          trigger={
            <Form.Select
              label="Beløning(valgfrit)"
              placeholder="Vælg beløningstypen"
              options={paymentOptions}
              onChange={(e, data) => {
                this.props.handleNewServiceChange({
                  name: 'payment.type',
                  value: data.value
                });
              }}
            />
          }
          content="Hvordan skal du belønnes med opgaven?"
        />

        <Popup
          trigger={
            <Form.Input
              labelPosition="right"
              label="Beløb"
              placeholder="Beløb"
              onChange={evt => {
                this.props.handleNewServiceChange({
                  name: 'payment.amount',
                  value: evt.target.value
                });
              }}
              value={this.props.newService.payment.amount || ''}
            >
              <input />
              <Label>KR</Label>
            </Form.Input>
          }
          content="Hvor meget skal du belønnes med?"
        />
      </Form.Group>
    );
  }
  /*
  renderLocatoinForm() {
    //TODO: maybe redisgn to modal
    return (
      <React.Fragment>
        <Form.Field>
          <Popup
            trigger={<label>Vælg regioner</label>}
            content="Vælg de regioner de områder kan udføre opgaven i"
          />
          <SvgMap
            regionIsActive={this.regionIsActive.bind(this)}
            onLocatoinClick={this.onLocatoinClick.bind(this)}
            selectedColor="#AFE9AF"
            unSelectedColor="#BABABA"
          />
          <p>Klik på de regioner, som du kan udføre opgaven i.</p>
        </Form.Field>
      </React.Fragment>
    );
  }*/
  renderDefaultService() {
    return (
      <div className="row">
        <div className="col-12 col-sm-6">
          {this.renderDetailsForm()}
          {/*this.renderPaymentForm()*/}
        </div>
        <div className="col-12 col-sm-6">
          <ImageUploader
            onRef={ref => (this.imageUploader = ref)}
            //  onGalleryReady={this.props.updateGallery}
            updateLocalGallery={this.props.updateLocalGallery}
            removeLocalImage={this.props.removeLocalImage}
            removeUploadedImage={this.props.removeUploadedImage}
            gallery={this.props.newService.gallery}
            localGallery={this.props.newService.localGallery}
            popupText="Tilføj billeder til din service, for at gøre den mere attraktiv."
            type="single"
          />
        </div>

        <div className="col-12 col-sm-6">
          {/*this.renderLocatoinForm()*/}
          {this.props.editMode && (
            <div className="mt-4">
              <Popup
                trigger={
                  <Form.Checkbox
                    label="Aktiv"
                    name="active"
                    toggle
                    checked={this.props.newService.active}
                    onChange={evt =>
                      this.props.handleNewServiceChange({
                        name: 'active',
                        value: !this.props.newService.active
                      })
                    }
                  />
                }
                content="Er opslaget stadig aktiv?"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  onLocatoinClick(region) {
    this.props.newService.location.includes(region)
      ? this.props.removeRegion(region)
      : this.props.addRegion(region);
  }
  regionIsActive(region) {
    return this.props.newService.location.includes(region);
  }
  async handleNewService() {
    let newService = {...this.props.newService};
    const {category, title, location, gallery} = newService;
    const categoryIsFilled = category && category.length > 0 ? true : false;
    const titleIsFilled = title && title.length > 0 ? true : false;
    const locationsFilled = location && location.length > 0 ? true : false;

    if (categoryIsFilled && titleIsFilled && locationsFilled) {
      this.setState({isLoading: true});
      const gallery = await this.imageUploader.handleImageUpload();

      if (gallery) {
        newService = {
          ...newService,
          gallery: [...newService.gallery, ...gallery]
        };
      }
      if (!newService.owner) {
        newService = {...newService, owner: this.props.user.userData._id};
      }
      DataStore.postNewService(newService)
        .then(response => {
          let status = response.status;
          this.props.resetNewService();
          if (this.props.editTaskMode) {
            this.props.setEditTaskMode(false);
          }

          if (this.props.editMode) {
            this.props.history.push('/service/' + this.props.id);
          } else {
            this.props.history.push('/profil/annoncer?type=Service');
          }
          this.setState({isLoading: false});

          successToast({
            header:
              'Din service er ' +
              (this.props.editMode ? 'opdateret!' : 'oprettet!')
          });
        })
        .catch(error => {
          this.setState({isLoading: false});
          errorLog('NewServiceComponent.postNewService', error);
          //Error message
          errorToast({header: 'Noget Gik galt. Opgaven er ikke udgivet.'});
        });
    } else {
      const missingFieldsString =
        (categoryIsFilled ? '' : 'kategori,') +
        (titleIsFilled ? '' : 'titel,') +
        (locationsFilled ? '' : 'lokation');
      warningToast({
        header: 'Udfyld venligst alle felterne(' + missingFieldsString + ')'
      });
    }
    //reset new task state
  }
  render() {
    if (this.state.isLoading) {
      return <Loading fullpage={true} />;
    }
    const content = this.renderDefaultService();
    return (
      <div className="container">
        <div className="row modal-header">
          <div className=" col-12 text-center">
            <Header as="h2">Tilbyd en ny service</Header>
            <p>
              Her kan du oprette service som du vil tilbyde. <br /> Beskriv
              venligst din services så grundigt som muligt.{' '}
            </p>
          </div>
        </div>
        <Form className="mb-3">{content}</Form>
        <div className="row">
          <div className="d-flex justify-content-center w-100  mt-5">
            <Button
              className="success-button"
              size="huge"
              onClick={this.handleNewService.bind(this)}
            >
              {this.props.editMode ? 'Gem ændringer' : 'Udgiv'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    newService: state.service.newService,
    user: state.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    removeRegion: value => {
      dispatch({
        type: RemoveRegion,
        val: value
      });
    },
    addRegion: value => {
      dispatch({
        type: AddRegion,
        val: value
      });
    },
    setLocationArray: value => {
      dispatch({
        type: SetLocationArray,
        val: value
      });
    },
    handleNewServiceChange: value => {
      dispatch({
        type: HandleNewServiceChange,
        val: value
      });
    },
    resetNewService: () => {
      dispatch({
        type: ResetNewService
      });
    },
    updateGallery: value => {
      dispatch({
        type: UpdateServiceGallery,
        val: value
      });
    },

    updateLocalGallery: value => {
      dispatch({
        type: UpdateServiceLocalGallery,
        val: value
      });
    },
    removeLocalImage: value => {
      dispatch({
        type: RemoveServiceLocalImage,
        val: value
      });
    },
    removeUploadedImage: value => {
      dispatch({
        type: RemoveServiceUploadedImage,
        val: value
      });
    },
    setCategory: value => {
      dispatch({
        type: SetNewServiceCategory,
        val: value
      });
    },
    setNewService: value => {
      dispatch({
        type: SetNewService,
        val: value
      });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServiceComponent);
