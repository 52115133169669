import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../App.css';
import UserProfile from '../UserProfile/UserProfile';
import './Profile.css';
import DataStore from '../../Data/DataStore';
import Loading from '../reuseable/Loading/Loading';

import PageHead from '../reuseable/PageHead/PageHead';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoading: true,
      userData: {}
    };
    let userId = this.props.match.params.id;

    DataStore.getUserByID(userId)
      .then(res => {
        if (res.status === 200) {
          this.setState({userData: {...res.data}});
          this.setState({isLoading: false});
        } else {
          this.setState({isLoading: false, error: true});
        }
      })
      .catch(err => this.setState({isLoading: false, error: true}));
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }

  render() {
    // let test = this.props.match.params.id;

    if (this.state.isLoading) return <Loading />;

    if (this.state.error) {
      return <h1>error</h1>;
    }
    return (
      <>
        <PageHead title={this.state?.userData?.name} />
        <UserProfile
          user={this.state.userData}
          showUserServices={this.state.userData.workerData.isWorkerProfile}
          // allowEditing={this.state.userData.isLoggedIn}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Profile);
