import React, {Component} from 'react';
import CategoryItem from './CategoryItem.component';
import './NewTask.css';
class CategoriesContainer extends Component {
  chunkArrayInGroups(arr, size) {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size));
    }
    return myArray;
  }
  renderRows(categories) {
    let arrayOfRows = this.chunkArrayInGroups(categories, 4);

    return (
      <div className="category-container">
        {arrayOfRows.map(row => {
          return (
            <div className="category-row">
              {row.map(category => {
                return <CategoryItem category={category} />;
              })}
            </div>
          );
        })}
      </div>
    );
  }
  render() {
    let categories = this.props.categories;
    let renderCategories = this.renderRows(categories);
    return (
      <React.Fragment>
        <h1 className="categories-title"> Vælg en kategori til din opgave </h1>

        {renderCategories}
      </React.Fragment>
    );
  }
}

export default CategoriesContainer;
//define props!
