import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  SetNewTaskCategory,
  SetNewTask,
  SetEditTaskMode
} from '../../redux/reducers/taskReducer';
import PageHead from '../reuseable/PageHead/PageHead';

import NewTaskComponent from '../NewTaskComponent/NewTaskComponent';
import LoginRequired from '../Login/LoginRequired';

class NewTask extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
  render() {
    let content;
    if (!this.props.user.userData.isLoggedIn) {
      content = <LoginRequired pageName="oprette en opgave" />;
    } else {
      let id = this.props.match.params.id; //get id from params
      content = (
        <NewTaskComponent
          editMode={this.props.match.url.includes('rediger')}
          id={id}
          history={this.props.history}
        />
      );
    }

    return (
      <>
        <PageHead title="Ny opgave" />
        {content}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    newTask: state.task.newTask,
    user: state.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCategory: value => {
      dispatch({
        type: SetNewTaskCategory,
        val: value
      });
    },

    setNewTask: value => {
      dispatch({
        type: SetNewTask,
        val: value
      });
    },
    setEditTaskMode: value => {
      dispatch({
        type: SetEditTaskMode,
        val: value
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewTask);
