import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, Modal} from 'semantic-ui-react';
import './Loading.css';
export default ({fullpage, text, onDismiss}) => {
  if (fullpage) {
    return (
      <Modal
        className="loading-modal-container"
        open={true}
        closeOnDimmerClick={true}
        onClose={() => {
          if (onDismiss) {
            onDismiss();
          }
        }}
      >
        <div className="loading-container full-page-loading  ">
          <img src="/loading.gif" alt="Henter data" loading="lazy" />
          <p>{text || 'Henter..'}</p>
        </div>
      </Modal>
    );
  }
  return (
    <div className="loading-container ">
      <img src="/loading.gif" alt="henter data" loading="lazy" />
      <p>{text || 'Henter..'}</p>
    </div>
  );
};
