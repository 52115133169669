import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'semantic-ui-react';

import './MobileNav.css';

class MobileNav extends Component {
  render() {
    return (
      <div class="ui five item menu mobile-bottom-nav">
        <Link className="item " to="/" onClick={() => window.scrollTo(0, 0)}>
          <i class="home   icon" />
        </Link>

        <Link className="item " to="/profil/favoritter">
          <i class="bookmark  icon" />
        </Link>

        <Dropdown icon="plus" className="item">
          <Dropdown.Menu>
            <Link className="item " to="/opret/opgave">
              <Dropdown.Item text="Opret opgave" />
            </Link>
            <Link className="item " to="/opret/service">
              <Dropdown.Item text="Tilbyd service." />
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <Link className="item " to="/beskeder">
          <i class="comment  icon" />{' '}
        </Link>

        <Link className="item " to="/profil">
          <i class="user  icon" />{' '}
        </Link>
      </div>
    );
  }
}

export default MobileNav;
