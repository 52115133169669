import React, {Component} from 'react';
import CategoriesContainer from '../TaskCategories/Categories.container';
import ImageUploader from '../reuseable/ImageUploader/ImageUploader';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {
  SetNewTaskCategory,
  SetNewTaskRegion,
  HandleNewTaskChange,
  UpdateGallery,
  UpdateLocalGallery,
  RemoveLocalImage,
  RemoveUploadedImage,
  ResetNewTask,
  SetNewTask
} from '../../redux/reducers/taskReducer';

import {
  Card,
  Header,
  Button,
  Segment,
  Image,
  Label,
  Popup,
  TextArea,
  Form,
  Checkbox,
  Icon,
  HeaderContent,
  Dropdown
} from 'semantic-ui-react';
import './NewTaskComponent.css';
import axios from 'axios';
import DataStore from '../../Data/DataStore';
import Loading from '../reuseable/Loading/Loading';
import {successToast, warningToast, errorToast} from '../../utils/toast';
import {errorLog} from '../../utils/logger';
const regions = require('../../Data/constants/regions.json');

const categories = require('../../Data/constants/Categories.json');

class NewTaskComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successScreenModal: false,
      isLoading: false,
      redirect: false
    };
  }

  componentWillMount() {
    if (this.props.id && this.props.editMode) {
      //fetchTask
      this.setState({isLoading: true});
      DataStore.fetchTask(this.props.id)
        .then(res => {
          let task = res.data;
          if (task.owner._id === this.props.user.userData._id) {
            this.props.setNewTask(task);
          } else {
            this.props.history.push('/opgaver/' + this.props.id);
          }
        })
        .catch(er => {
          errorLog('NewTaskComponent.fetchTask', er);
        })
        .finally(() => this.setState({isLoading: false}));
    }
  }
  componentWillUnmount() {
    this.props.resetNewTask();
  }

  async _handleAddressChange(target) {
    this.props.handleNewTaskChange(target);
    let zipcode = target.value;
    if (zipcode.length === 4) {
      try {
        let cityName = await this._getCityNameByZip(zipcode);
        this.props.handleNewTaskChange({
          name: target.name.replace('zipCode', 'cityName'),
          value: cityName
        });
      } catch (er) {}
    } else {
      this.props.handleNewTaskChange({
        name: target.name.replace('zipCode', 'cityName'),
        value: ''
      });
    }
  }
  _getCityNameByZip = async zip => {
    let endPoint = 'https://dawa.aws.dk/postnumre/' + zip;
    try {
      let response = await axios.get(endPoint);
      return response.data.navn; //response.data.nr returns postalcode
    } catch (er) {
      errorLog('_getCityNameByZip', er);

      return null;
    }
  };

  renderLokationForm() {
    if (this.props.newTask.category === 'FLYTNING') {
      return (
        <React.Fragment>
          <Form.Group>
            <Popup
              trigger={
                <Form.Input
                  label="Flytte fra (postnummer)"
                  placeholder="Postnummer"
                  name="movingTaskData.fromAddress.location.zipCode"
                  type="text"
                  value={
                    this.props.newTask.movingTaskData.fromAddress.location
                      .zipCode
                  }
                  onChange={evt => this._handleAddressChange(evt.target)}
                  maxLength={4}
                >
                  <input />
                  {this.props.newTask.movingTaskData.fromAddress.location
                    .cityName && ( //TODO: nicer design for label
                    <Label basic>
                      {this.props.newTask.movingTaskData.fromAddress.location
                        .cityName || ''}
                    </Label>
                  )}
                </Form.Input>
              }
              content="Hvor skal der flyttes fra? Skriv postnummeret"
            />
          </Form.Group>
          <Form.Group>
            <Popup
              trigger={
                <Form.Input
                  label="Flytte til (postnummer)"
                  placeholder="postnummer"
                  value={
                    this.props.newTask.movingTaskData.toAddress.location.zipCode
                  }
                  onChange={evt => this._handleAddressChange(evt.target)}
                  name="movingTaskData.toAddress.location.zipCode"
                  type="text"
                  maxLength={4}
                >
                  <input />
                  {this.props.newTask.movingTaskData.toAddress.location
                    .cityName && ( ///TODO: nicer design for label
                    <Label basic>
                      {this.props.newTask.movingTaskData.toAddress.location
                        .cityName || ''}
                    </Label>
                  )}
                </Form.Input>
              }
              content="Hvor skal der flyttes til? Skriv postnummeret"
            />
          </Form.Group>
        </React.Fragment>
      );
    }

    return (
      <Popup
        trigger={
          <Form.Input
            label="Lokation (postnummer)"
            placeholder="Postnummer"
            value={this.props.newTask.location.zipCode}
            onChange={evt => this._handleAddressChange(evt.target)}
            name="location.zipCode"
            type="text"
            maxLength={4}
          >
            <input />
            {this.props.newTask.location.cityName && ( //TODO: nicer design for label
              <Label basic>{this.props.newTask.location.cityName || ''}</Label>
            )}
          </Form.Input>
        }
        content="Hvor er opgavens lokation? Skriv et postnummer"
      />
    );
  }

  renderDetailsForm() {
    return (
      <React.Fragment>
        <Popup
          trigger={
            <Form.Input
              fluid
              id="form-subcomponent-shorthand-input-first-name"
              label="Titel"
              placeholder="Titel"
              name="title"
              value={this.props.newTask.title}
              onChange={evt => this.props.handleNewTaskChange(evt.target)}
            />
          }
          content="Skriv en titel til din opgave"
        />
        <Popup
          trigger={
            <Form.Dropdown
              label="Kategori"
              placeholder="Vælg en kategori"
              defaultValue="ANDET"
              selection
              options={categories}
              onChange={(e, {value}) => {
                this.props.setCategory(value);
              }}
            />
          }
          content="Vælg en kategori"
        />
        <Popup
          trigger={
            <Form.Dropdown
              label="Region"
              multiple
              placeholder="Hele landet"
              defaultValue="ALL"
              selection
              options={regions}
              value={this.props.newTask.regions}
              onChange={(e, {value}) => {
                this.props.setRegion(value);
              }}
            />
          }
          content="Vælg de regioner hvor du kan udføre dine service."
        />
      </React.Fragment>
    );
  }
  renderDefaultTask() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-sm-6">{this.renderDetailsForm()}</div>

          <div className="col-12 col-sm-6 mt-3 mt-sm-0">
            <Popup
              trigger={
                <Form.TextArea
                  label="Beskrivelse"
                  placeholder="Beskriv opgaven"
                  name="description"
                  type="text"
                  value={this.props.newTask.description}
                  onChange={evt => this.props.handleNewTaskChange(evt.target)}
                />
              }
              content="Beskriv din opgave"
            />
            {/*this.renderLokationForm*/}
            <ImageUploader
              onRef={ref => (this.imageUploader = ref)}
              onGalleryReady={this.props.updateGallery}
              updateLocalGallery={this.props.updateLocalGallery}
              removeLocalImage={this.props.removeLocalImage}
              removeUploadedImage={this.props.removeUploadedImage}
              gallery={this.props.newTask.gallery}
              localGallery={this.props.newTask.localGallery}
              popupText="Tilføj billeder til din opgave, for at gøre den mere attraktiv."
            />
            {this.props.editMode && (
              <div className="mt-4">
                <Popup
                  trigger={
                    <Form.Checkbox
                      label="Aktiv"
                      name="active"
                      toggle
                      checked={this.props.newTask.active}
                      onChange={evt =>
                        this.props.handleNewTaskChange({
                          name: 'active',
                          value: !this.props.newTask.active
                        })
                      }
                    />
                  }
                  content="Er opgaven stadig aktiv?"
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  modalContent() {
    return this.renderDefaultTask();
  }

  async handleNewTask() {
    let newTask = {...this.props.newTask};
    const {category, regions, title, location, movingTaskData} = newTask;
    const categoryIsFilled = category && category.length > 0 ? true : false;
    const regionIsFilled = !!(regions && regions.length > 0);
    const titleIsFilled = title && title.length > 0 ? true : false;
    /*let locationsFilled =
      location && location.zipCode && location.zipCode.length > 0
        ? true
        : false;*/
    /* const fromMovingTaskLocationsFilled =
      movingTaskData.fromAddress &&
      movingTaskData.fromAddress.location &&
      movingTaskData.fromAddress.location.zipCode &&
      movingTaskData.fromAddress.location.zipCode.length > 0
        ? true
        : false;
    const toMovingTaskLocationsFilled =
      movingTaskData.toAddress &&
      movingTaskData.toAddress.location &&
      movingTaskData.toAddress.location.zipCode &&
      movingTaskData.toAddress.location.zipCode.length > 0
        ? true
        : false;
    const movingTaskLocationsFilled =
      fromMovingTaskLocationsFilled && toMovingTaskLocationsFilled;
    locationsFilled =
      category === 'FLYTNING' ? movingTaskLocationsFilled : locationsFilled;
*/
    if (categoryIsFilled && titleIsFilled && regionIsFilled) {
      this.setState({isLoading: true});

      const gallery = await this.imageUploader.handleImageUpload();
      if (gallery) {
        newTask = {...newTask, gallery: [...newTask.gallery, ...gallery]};
      }
      if (!newTask.owner) {
        newTask = {...newTask, owner: this.props.user.userData._id};
      }

      DataStore.postNewTask(newTask)
        .then(response => {
          this.props.resetNewTask();
          if (this.props.editTaskMode) {
            this.props.setEditTaskMode(false);
          }
          this.setState({isLoading: false});
          if (this.props.editMode) {
            this.props.history.push('/opgaver/' + this.props.id);
          } else {
            this.props.history.push('/profil/annoncer?type=Task'); // todo rename annoncer to something else? opslag?
          }
          successToast({
            header:
              'Opgaven er ' + (this.props.editMode ? 'opdateret!' : 'oprettet!')
          });
          this.props.resetNewTask();

          //go to task page
        })
        .catch(error => {
          errorLog('NewTaskComponent.postNewTask', error);

          this.setState({isLoading: false});

          //Error message

          errorToast({
            header: 'Noget Gik galt.',
            message: 'Opgaven er ikke udgivet.'
          });
        });
    } else {
      const missingFieldsString =
        (categoryIsFilled ? '' : 'kategori,') +
        (titleIsFilled ? '' : 'titel,') +
        (regionIsFilled ? '' : 'region');
      warningToast({
        header: 'Udfyld venligst alle felterne(' + missingFieldsString + ')'
      });
    }
    //reset new task state
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={'/'} />;
    }
    if (this.state.isLoading) {
      return <Loading fullpage={true} />;
    }
    const content = this.renderDefaultTask();
    return (
      <div className="container">
        <div className="row modal-header">
          <div className=" col-12 text-center">
            <Header as="h2">Opret en ny opgave</Header>
            <p>
              Her kan du oprette en opgave så virksomheder og freelancere kan
              kontakte dig med tilbud til. <br /> Beskriv venligst din opgave så
              grundigt som muligt.{' '}
            </p>
          </div>
        </div>
        <Form className="mb-3">{content}</Form>
        <div className="row">
          <div className="d-flex justify-content-center w-100  mt-5">
            <Button
              className="success-button"
              size="huge"
              onClick={this.handleNewTask.bind(this)}
            >
              {this.props.editMode ? 'Gem ændringer' : 'Udgiv'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    newTask: state.task.newTask,
    user: state.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateGallery: value => {
      dispatch({
        type: UpdateGallery,
        val: value
      });
    },

    updateLocalGallery: value => {
      dispatch({
        type: UpdateLocalGallery,
        val: value
      });
    },
    removeLocalImage: value => {
      dispatch({
        type: RemoveLocalImage,
        val: value
      });
    },
    removeUploadedImage: value => {
      dispatch({
        type: RemoveUploadedImage,
        val: value
      });
    },

    handleNewTaskChange: value => {
      dispatch({
        type: HandleNewTaskChange,
        val: value
      });
    },
    setCategory: value => {
      dispatch({
        type: SetNewTaskCategory,
        val: value
      });
    },
    setRegion: value => {
      dispatch({
        type: SetNewTaskRegion,
        val: value
      });
    },

    resetNewTask: () => {
      dispatch({
        type: ResetNewTask
      });
    },
    setNewTask: value => {
      dispatch({
        type: SetNewTask,
        val: value
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewTaskComponent);
