import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import './Share.css';
const Share = ({
  href,
  className = '',
  target = '_blank',
  preventCash = true,
  onClick = null,
  title = null
}) => {
  const url = 'https://www.facebook.com/sharer/sharer.php?display=page&u=';
  const cash = preventCash ? `?${Date.now()}` : '';
  href = href ? href : window.location.href;

  return (
    <a
      href={`${url}${href}${cash}`}
      title={title}
      target={target}
      onClick={onClick}
      className={`Share d-flex align-items-center petroleum ${className}`}
    >
      <div className="mt-4 facebookShareBtn">
        <Icon name="facebook" /> Del
      </div>
    </a>
  );
};

export default Share;
