import React, {useState} from 'react';

import {Menu, Segment} from 'semantic-ui-react';
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';

export default () => {
  const [activeItem, setActiveItem] = useState('Log ind');
  const handleItemClick = (e, {name}) => setActiveItem(name);

  const renderSelectedView = () => {
    if (activeItem === 'Log ind') {
      return <Login showForgotView={() => setActiveItem('forgot')} />;
    } else if (activeItem === 'opret') {
      return <SignUp fromLoginPage={true} />;
    } else if (activeItem === 'forgot') {
      return <ForgotPassword showLoginView={() => setActiveItem('Log ind')} />;
    }
  };
  const selectedView = renderSelectedView();
  return (
    <div>
      {activeItem !== 'forgot' && (
        <Menu pointing secondary widths="2">
          <Menu.Item
            name="Log ind"
            active={activeItem === 'Log ind'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="opret"
            active={activeItem === 'opret'}
            onClick={handleItemClick}
          />
        </Menu>
      )}

      {selectedView}
    </div>
  );
};
