import {toast} from 'react-toastify';
import React from 'react';
import {Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const isMobile = window.innerWidth <= 500;
export function successToast(props = {}) {
  toast.success(
    <div className="toast-content-container">
      <div className="toast-left-border" />
      <Icon size="big" className="toast-left-icon" name="check circle" />
      <div>
        <h1>{props.header}</h1>
        <p>{props.message}</p>
      </div>
    </div>,
    {
      closeOnClick: false,
      position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_LEFT,
      hideProgressBar: true,
      ...props
    }
  );
}

export function warningToast(props = {header: '', message: ''}) {
  toast.warn(
    <div className="toast-content-container">
      <div className="toast-left-border" />
      <Icon size="big" className="toast-left-icon" name="warning circle" />
      <div>
        <h1>{props.header}</h1>
        <p>{props.message}</p>
      </div>
    </div>,
    {
      closeOnClick: false,
      position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_LEFT,
      hideProgressBar: true,
      ...props
    }
  );
}

export function errorToast(props = {}) {
  toast.error(
    <div className="toast-content-container">
      <div className="toast-left-border" />
      <Icon size="big" className="toast-left-icon" name="remove circle" />
      <div>
        <h1>{props.header}</h1>
        <p>{props.message}</p>
      </div>
    </div>,
    {
      closeOnClick: false,
      position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_LEFT,
      hideProgressBar: true,
      ...props
    }
  );
}

export function chatToast(props = {}) {
  toast.info(
    <Link className="toast-content-container" to="/beskeder">
      <div className="toast-left-border" />
      <Icon size="big" className="toast-left-icon" name="chat  " />
      <div>
        <h1>{props.header}</h1>
        <p>{props.message}</p>
      </div>
    </Link>,
    {
      closeOnClick: false,
      position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_LEFT,
      ...props
    }
  );
}
