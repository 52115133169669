import './YoutubeVideo.css';
import {Embed, Icon, Transition, Modal} from 'semantic-ui-react';
import React, {useState} from 'react';
import {firebaseAnalytics} from '../../../Data/firebase';

export default props => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Transition.Group animation="slide left" duration={500}>
        <Modal
          closeIcon
          open={visible}
          onClose={() => setVisible(false)}
          onOpen={() => setVisible(true)}
        >
          <Embed
            className="embeded-yt-container"
            id="o7Q_sm5de2Q"
            source="youtube"
            placeholder="/images/introPlaceholder.jpg"
            autoplay={true}
          />
        </Modal>
      </Transition.Group>

      <div
        style={props.style}
        onClick={() => {
          setVisible(true);
          firebaseAnalytics.logEvent('open_youtube_video');
        }}
        className="ytv-btn-container"
        tabIndex={0}
        role="button"
      >
        <span> Se video </span> <Icon className="ytv-icon" name="play" />
      </div>
    </>
  );
};
