import React, {useState} from 'react';
import firebase from '../../Data/firebase';

import {Button, Form, Popup, Icon, Divider, Header} from 'semantic-ui-react';
import {successToast, errorToast} from '../../utils/toast';
import {SET_LOGIN_MODAL} from '../../redux/reducers/loginReducer';
import {useDispatch} from 'react-redux';

import './ForgotPassword.css';
export default props => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  return (
    <Form className="forgot-pass-container">
      <Popup
        trigger={
          <Form.Input
            fluid
            label="Mail adresse"
            placeholder="E-mail Address"
            name="email"
            type="text"
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        }
        content="Skriv din email."
      />
      <Button
        fluid
        onClick={() => {
          firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(function() {
              // Email sent.
              successToast({
                header: 'Email sendt!',
                message: 'Følg instruktionerne i den sendte email'
              });

              dispatch({
                type: SET_LOGIN_MODAL,
                val: false
              });
            })
            .catch(function(error) {
              // An error happened.
              errorToast({header: 'Noget gik galt.'});
            });
        }}
      >
        Nulstil adgangskode
      </Button>
      <div className="forgot-back-btn">
        <p onClick={props.showLoginView}> Tilbage</p>
      </div>
    </Form>
  );
};
