import React from 'react';
import ReactDOM from 'react-dom';
import App from './client/App';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './client/redux/store';
import registerServiceWorker from './client/registerServiceWorker';
import './client/style/bootstrap/bootstrap-grid.min.css';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from './client/Components/ErrorFallback/ErrorFallback';
ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ErrorBoundary>,

  document.getElementById('root')
);
registerServiceWorker();
