import React, {Component} from 'react';
import {connect} from 'react-redux';
import './ServiceDetail.css';
import toColor from '../../utils/toColor';
import Moment from 'moment';
import 'moment/locale/da';
import {
  Header,
  TextArea,
  Form,
  Button,
  Message,
  Icon,
  Divider,
  Label
} from 'semantic-ui-react';
import PostItemsDisplay from '../reuseable/PostItemsDisplay/PostItemsDisplay';
import DataStore from '../../Data/DataStore';
import UserThumbnail from '../reuseable/UserThumbnail/UserThumbnail';
import withNewConversation from '../reuseable/hoc/NewConversation';
import Share from '../reuseable/Share/Share';
import {Link} from 'react-router-dom';
import {successToast, warningToast, errorToast} from '../../utils/toast';
import PageHead from '../reuseable/PageHead/PageHead';

class ServiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageYOffset: null,
      isFavourite: false
    };
  }
  componentWillMount() {
    DataStore.fetchServicePageService(this.props.id);
    this.setState({
      isFavourite: this.props.user.favourites.includes(this.props.id),
      pageYOffset: window.pageYOffset
    });
    //  window.scrollTo(0, 0);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
  componentWillUnmount() {
    // window.scrollTo(this.state.pageYOffset, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      DataStore.fetchServicePageService(this.props.id);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  }
  handleAddFavourite() {
    if (this.props.user.isLoggedIn) {
      if (this.state.isFavourite) {
        DataStore.removeFromFavourites(this.props.service._id);
        this.setState({isFavourite: false});
      } else {
        DataStore.addToFavourites(this.props.service._id);
        this.setState({isFavourite: true});
      }
    } else {
      warningToast({header: 'Du skal logge ind for at tilføje favoritter'});
    }
  }

  render() {
    let service = this.props.service;
    console.log(
      'this.props.servicesBySameOwner ',
      this.props.servicesBySameOwner
    );
    if (service && service._id === this.props.id) {
      const postDisplayItems = this?.props?.servicesBySameOwner?.filter(
        item => item._id !== service._id
      );

      const serviceIsActive =
        service.active && service.owner.workerData.isWorkerProfile;

      let imgStyle = {
        backgroundImage: `url(${service.gallery[0]})`,
        backgroundColor: toColor(service._id),
        height: '100%',
        maxHeight: ' 400px'
      };
      return (
        <React.Fragment>
          <PageHead title={service.title} />
          {!serviceIsActive && (
            <Message warning>
              <Message.Header>Ikke aktiv.</Message.Header>
              <p>Dette opslag er desværre ikke aktiv længere.</p>
            </Message>
          )}
          <div className="row labelize-input service-detail-row-container">
            <div className="col-12 col-md-8 ">
              <div style={imgStyle} className="service-cover-image">
                {/*      <Image
              fluid
              src={service.gallery[0]}
        />*/}
              </div>
            </div>
            <div className="col-12 col-md-4 " style={{zIndex: 1}}>
              <Form>
                {service.owner._id === this.props.user._id && (
                  <Label as={Link} to={'/service/' + service._id + '/rediger'}>
                    <Icon name="pencil" />
                    Rediger
                  </Label>
                )}
                <Header className="mt-2" as="h2">
                  {service.title || ''}
                </Header>
                <Label as="a" color="orange">
                  Service
                </Label>
                <p>{service.description || ''}</p>
                <Header className="mb-0" as="h4">
                  Lokation
                </Header>
                <p className="mt-0">
                  {service.location.map(
                    (loc, index) =>
                      loc.charAt(0) +
                      loc.toLowerCase().slice(1) +
                      (index !== service.location.length - 1 ? ', ' : '')
                  )}
                </p>
              </Form>
              <div
                style={{cursor: 'pointer'}}
                onClick={this.handleAddFavourite.bind(this)}
              >
                <Icon
                  style={
                    this.state.isFavourite ? {color: 'var(--fersken)'} : {}
                  }
                  name={'heart ' + (this.state.isFavourite ? '' : 'outline')}
                  size="large"
                  color={this.state.isFavourite ? 'red' : 'grey'}
                />
                {this.state.isFavourite
                  ? 'Tilføjet til favoritter'
                  : 'Tilføj til favoritter'}
              </div>
              <Share className="mb-2 " />
            </div>
          </div>

          <div className="row mb-3 mt-5">
            <div className="col-12   mb-3">
              <Header className="mb-1" as="h3">
                Udgiver:
              </Header>
              <UserThumbnail
                name={
                  (service.owner.name || '') +
                  ' ' +
                  (service.owner.lastName || ' ')
                }
                subtitle={
                  (service.owner.workerData.isWorkerProfile &&
                    service.owner.workerData.type) ||
                  ''
                }
                src={service.owner.photoURL || null}
                userId={service.owner._id}
                type="verti"
              />
              {/*   <div className=" d-flex justify-content-center">
                <Rating
                  maxRating={5}
                  defaultRating={3}
                  icon="star"
                  size="huge"
                />
                <p>(23)</p>
              </div>*/}
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-2 text-center">
              <p className="bold">Bruger siden</p>
              {Moment(service.owner.created_at).format('Do MMMM YYYY')}
            </div>
            <div className="col-12 col-md-2 text-center">
              <p className="bold">Type</p>
              {service.owner.workerData.type}
            </div>

            <Link
              className="col-12 col-md-2 text-center see-full-profile"
              to={
                service.owner._id.length > 1
                  ? '/bruger/' + service.owner._id
                  : ''
              }
            >
              <p className="bold ">Se profil </p>
            </Link>
          </div>
          <div className="row d-flex justify-content-center">
            {service.owner._id != this.props.user._id && (
              <Button
                className="mt-4 green-btn-style col-9 col-md-2"
                fluid
                onClick={() => {
                  if (this.props.user.isLoggedIn) {
                    this.props.contactUser({
                      receiverID: service.owner._id,
                      name: service.owner.name,
                      lastName: service.owner.lastName
                    });
                  } else {
                    //show toast
                    warningToast({
                      header: 'Du skal logge ind for at sende beskeder'
                    });
                  }
                }}
                disabled={!serviceIsActive}
              >
                Kontakt {service.owner.name || ''}
              </Button>
            )}
          </div>

          {postDisplayItems?.length > 0 && (
            <>
              <Divider className="col-12" />

              <div className="row">
                <div className="col-12" />
                <PostItemsDisplay
                  title={service.owner.name + ' tilbyder også'}
                  items={postDisplayItems}
                  postType="service"
                />
              </div>
            </>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div className="App">
          <i className="fa fa-circle-o-notch fa-spin" style={{fontSize: 36}} />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    servicesBySameOwner: state.service.servicesBySameOwner,
    service: state.service.servicePageService,
    user: state.user.userData
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNewConversation(ServiceDetail));
