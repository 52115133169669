import React from 'react';
import {Helmet} from 'react-helmet';

function buildOGMeta(obj) {
  return Object.keys(obj).map((k, i) => {
    if (obj[k] && typeof obj[k] !== 'object') {
      return (
        <meta key={`${k}-meta-${i}`} property={k} content={obj[k] || null} />
      );
    }
    return false;
  });
}

/**

 Example template

 <Head
 title='...'
 description='...'
 canonical='/subpage'
 robots = 'all',
 og={{
      'og:url': 'https://Heelp.dk/opgaver/...',
      image: {
        'og:image': '...',
        'og:image:width': 'x..',
        'og:image:height': 'y..'
      },

    }}
 />
 */
/*

to add:
/opgaver/id 
/service/id
/bruger/id 
/om/ 

*/
const Head = ({
  title = 'Heelp.dk',
  canonical = '',
  description = 'En platform hvor du kan tilbyde og få hjælp til praktiske opgaver så som VVS,flytning, håndværk, elektronik og meget andet!',
  robots = 'all',
  noindex = false,
  og = {
    'og:url': 'https://www.heelp.dk/',
    'og:type': 'website',
    image: {
      'og:image': '/images/woman-painting-small.jpg',
      'og:image:width': '1200',
      'og:image:height': '675'
    }
  }
}) => {
  return (
    <Helmet>
      <title>{title === 'Heelp.dk' ? title : `${title} | Heelp.dk`}</title>
      <link rel="canonical" href={`https://heelp.dk${canonical}`} />
      <meta name="robots" content={robots} />
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      {noindex && <meta name="robots" content="noindex" />}
      {(og && buildOGMeta(og)) || null}
      {(og && og.image && buildOGMeta(og.image)) || null}
    </Helmet>
  );
};

export default Head;
