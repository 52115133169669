import React, {Component} from 'react';
import {Header, Image} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './UserThumbnail.css';
import {Link} from 'react-router-dom';
import profileImagePlaceholder from '../../../style/svg/pb-placeholder.svg';
import toColor from '../../../utils/toColor';

export default class UserThumbnail extends Component {
  renderImageComponent() {
    let imgComponent;
    const fontsizeSyle = this.props.size
      ? {fontSize: this.props.size - 5 + 'px', color: 'white !important'}
      : {color: 'white !important'};
    const imgSyle = this.props.size
      ? {width: this.props.size + 'px', height: this.props.size + 'px'}
      : {};
    if (!this.props.src) {
      imgComponent = (
        <div
          style={{...imgSyle, backgroundColor: toColor(this.props.userId + 10)}}
          className={
            'letter-icon-userthumbnail userthumbnail-image--' + this.props.type
          }
        >
          <p className="lettr-icon-userTh" style={fontsizeSyle}>
            {' '}
            {this.props.name ? this.props.name[0] : ''}
          </p>
        </div>
      );
    } else {
      imgComponent = (
        <img
          loading="lazy"
          alt="Bruger "
          className={'userthumbnail-image--' + this.props.type}
          src={this.props.src || profileImagePlaceholder}
          style={imgSyle}
        />
      );
    }

    if (this.props.enableLink) {
      return (
        <Link
          to={
            this.props.userId.length > 1 ? '/bruger/' + this.props.userId : ''
          }
        >
          {imgComponent}
        </Link>
      );
    }

    return imgComponent;
  }

  renderNameComponent() {
    if (!this.props.name) {
      return;
    }
    const nameComponent = (
      <React.Fragment>
        <Header
          className={'m-0' + (this.props.navbar ? ' menu-thumbnail' : '')}
          as="h4"
        >
          {this.props.name}
        </Header>

        {this.props.subtitle && (
          <Header className="mt-0" as="h6">
            {this.props.subtitle}
          </Header>
        )}
      </React.Fragment>
    );

    if (this.props.enableLink) {
      return (
        <Link
          to={
            this.props.userId.length > 1 ? '/bruger/' + this.props.userId : ''
          }
        >
          {nameComponent}
        </Link>
      );
    }

    return nameComponent;
  }

  renderContent() {
    const nameComponent = this.renderNameComponent();
    const imgComponent = this.renderImageComponent();

    if (this.props.nameOnRight) {
      return (
        <React.Fragment>
          <div
            className={
              ' mr-2 flex-column ' +
              (this.props.type === 'simple' ? 'd-none ' : 'd-flex ')
            }
          >
            {nameComponent}
          </div>
          {imgComponent}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {imgComponent}

          <div
            className={
              ' ml-1 flex-column ' +
              (this.props.type === 'simple' ? 'd-none ' : 'd-flex ')
            }
          >
            {nameComponent}
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div
        className={
          'align-items-center d-flex ' +
          (this.props.className + ' ' || ' ') +
          (this.props.type.includes('verti')
            ? 'flex-column text-center'
            : 'flex-row')
        }
      >
        {this.renderContent()}
      </div>
    );
  }
}

UserThumbnail.contextTypes = {
  name: PropTypes.string,
  subTitle: PropTypes.string,
  src: PropTypes.string,
  userId: PropTypes.string,
  type: PropTypes.oneOf(['hori', 'verti', 'simple', 'verti-large']),
  enableLink: PropTypes.bool,
  size: PropTypes.number,
  nameOnRight: PropTypes.bool
};

UserThumbnail.defaultProps = {
  name: '',
  subtitle: '',
  src: '/images/user.png',
  userId: '',
  type: 'small',
  enableLink: true
};
