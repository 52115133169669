import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {loadCSS} from 'fg-loadcss';

import Icon from '@material-ui/core/Icon';
import './Footer.css';

function Footer() {
  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
  }, []);
  return (
    <React.Fragment>
      <div className="row footer-container">
        <div className="col-md-6 footer-col">
          <div className="footer-social-container">
            <Link to="/om"> Om os</Link>
            <Link to="/brugerbetingelser"> Brugerbetingelser</Link>
            <Link to="/privatlivspolitik"> Privatlivspolitik</Link>
          </div>
        </div>

        <div className="col-md-6  footer-col ">
          <div className="footer-social-container">
            <a
              className="footer-social-link"
              href="https://www.facebook.com/PraktiskeOpgaverdk-103291835035568"
              target="_blank"
            >
              <Icon
                style={{fontSize: '2rem'}}
                className="footer-icon  fab fa-facebook-square"
              />
              <p>Facebook</p>
            </a>
            <a
              className="footer-social-link"
              href="https://www.instagram.com/praktiskeopgaver.dk/"
              target="_blank"
            >
              <Icon
                className="footer-icon fab fa-instagram"
                style={{fontSize: '2rem'}}
              />
              <p>Instagram</p>
            </a>

            <a className="footer-social-link" href="mailto:kontakt@heelp.dk">
              <Icon
                className="footer-icon fas fa-envelope "
                style={{fontSize: '2rem'}}
              />
              <p>kontakt@heelp.dk</p>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
