import React from 'react';
import {ToggleBurgerMenu} from '../../redux/reducers/mainReducer';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import firebase from '../../Data/firebase';
import {successToast} from '../../utils/toast';
import {ResetMessages} from '../../redux/reducers/messageReducer';
import {ResetUserData} from '../../redux/reducers/userReducer';
import {SET_LOGIN_MODAL} from '../../redux/reducers/loginReducer';
import './RightBurgerMenu.css';
import {errorLog} from '../../utils/logger';

function BurgerMenu(props) {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.main.showBurgerMenu);
  const isLoggedIn = useSelector(state => state.user.userData.isLoggedIn);

  const closeMenu = () => dispatch({type: ToggleBurgerMenu, val: false});
  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .catch(error => errorLog('RightBurgerMeny.handleSignOut', error));
    Cookies.remove('elfj');

    dispatch({
      type: ResetUserData
    });
    dispatch({
      type: ResetMessages
    });

    closeMenu();
    successToast({header: 'Du er logget ud.'});
  };

  const handleSignIn = () => {
    props.history.push('/login');
    closeMenu();
  };
  return (
    <Sidebar
      className="right-burger-menu-container"
      as={Menu}
      animation="overlay"
      direction="right"
      icon="labeled"
      onHide={closeMenu}
      vertical
      visible={visible}
      width="thin"
    >
      <div className="burger-menu-header">
        <div className="burger-close-btn" onClick={closeMenu}>
          {/*   <CloseIcon />*/}
          <Icon name="close" size="large" />
        </div>

        <div
          className="reset-filters-btn "
          onClick={() => {
            // this.props.resetFilters();
          }}
        ></div>
      </div>

      <Menu.Item onClick={closeMenu} as={Link} to="/profil">
        <Icon name="user" />
        Min profil
      </Menu.Item>

      <Menu.Item onClick={closeMenu} as={Link} to="/beskeder">
        <Icon name="mail" />
        Beskeder
      </Menu.Item>

      <Menu.Item onClick={closeMenu} as={Link} to="/opret/opgave">
        <Icon name="plus" />
        Opret opgave
      </Menu.Item>

      <Menu.Item onClick={closeMenu} as={Link} to="/opret/service">
        <Icon name="plus" />
        Tilbyd service
      </Menu.Item>

      <Menu.Item onClick={closeMenu} as={Link} to="/profil/favoritter">
        <Icon name="heart" />
        Favoritter
      </Menu.Item>
      <Menu.Item onClick={closeMenu} as={Link} to="/profil/annoncer">
        <Icon name="sticky note" />
        Mine annoncer
      </Menu.Item>
      <Menu.Item onClick={closeMenu} as={Link} to="/profil/rediger">
        <Icon name="setting" />
        Indstillinger
      </Menu.Item>

      <Menu.Item
        style={{margin: 'auto'}}
        onClick={isLoggedIn ? handleSignOut : handleSignIn}
        as={Button}
      >
        {/**TODO: not fully aligned with the other items vertically */}
        <Icon name={isLoggedIn ? 'sign-out' : 'sign-in'} />
        {isLoggedIn ? 'Log ud' : 'Log in'}
      </Menu.Item>
    </Sidebar>
  );
}

export default withRouter(BurgerMenu);
