import {getIdToken} from './firebase';
import axios from 'axios';
import store from '../redux/store';
import {
  SetConversations,
  SetMessages,
  SetConversationsPagination,
  SetConversationsUnread,
  UpdateConversationIsRead
} from '../redux/reducers/messageReducer';
import Cookies from 'js-cookie';
import {errorLog, warningLog} from '../utils/logger';

const queryString = require('query-string');
const api = process.env.REACT_APP_SERVER_HOST;

class MessageFacade {
  loadConversations = async (loadNextPage = false) => {
    // let idToken = await firebase.auth().currentUser.getIdToken();
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();
    const userID = store.getState().user.userData._id;
    let page = store.getState().chats.conversationPagination.page;
    let pages = store.getState().chats.conversationPagination.pages;
    if (page <= pages && userID) {
      let query =
        '/?' +
        queryString.stringify({
          page: loadNextPage ? parseInt(page, 10) + 1 : parseInt(page, 10)
        });

      axios
        .get('/api/chat/conversations/' + userID + query, {
          auth: {idToken: idToken}
        })
        .then(response => {
          //let userServices = response.data;
          store.dispatch({
            type: SetConversations,
            val: response.data.conversations
          });
          store.dispatch({
            type: SetConversationsPagination,
            val: {
              page: response.data.page,
              pages: response.data.pages,
              total: response.data.total,
              hasMore: response.data.pages > response.data.page
            }
          });
          store.dispatch({
            type: SetConversationsUnread,
            val: response.data.totalUnread
          });
        })
        .catch(error => {
          errorLog('MessageFacade.loadConversations', error);
        });
    }
  };

  // retrieve the messages from the Backend
  loadMessages = async conversationId => {
    let page = store.getState().chats.conversations[conversationId].messages
      .page;
    let pages = store.getState().chats.conversations[conversationId].messages
      .pages;
    if (page <= pages) {
      let query = '/?' + queryString.stringify({page: parseInt(page, 10)});
      axios
        .get('/api/chat/messages/' + conversationId + query)
        .then(response => {
          store.dispatch({
            type: SetMessages,
            val: {
              messages: {
                ...response.data,
                page: parseInt(response.data.page, 10) + 1,
                pages: parseInt(response.data.pages, 10)
              },
              conversationId: conversationId
            }
          });
        })
        .catch(error => {
          errorLog('MessageFacade.loadMessages', error);
        });
    } else {
      warningLog('MessageFacade.loadMessages', {
        message: 'no messagfes to load',
        page,
        pages
      });
    }
  };
  // send the message to the Backend
  /*sendMessage(conversationID,message) {

    
    /*
    for (let i = 0; i < message.length; i++) {
      this.messagesRef.push({
        text: message[i].text,
        user: message[i].user,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
      });
    }*/
  //}
  // close the connection to the Backend
  closeChat() {
    if (this.messagesRef) {
      this.messagesRef.off();
    }
  }

  postNewConversation = async (userID, receiverID) => {
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      if (!userID || !receiverID || !idToken) {
        errorLog('MessageFacade.postNewConversation', {
          message: 'invalid idToken, receiverID or userID'
        });
        reject();
      }
      axios
        .post('/api/chat/conversation', {
          idToken,
          userID,
          receiverID
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          errorLog('MessageFacade.postNewConversation', error);
          reject();
        });
    });
  };

  sendMessage = async message => {
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();

    return new Promise((resolve, reject) => {
      axios
        .post('/api/chat/message', {
          idToken,
          message
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
          errorLog('MessageFacade.sendMessage', error);
        });
    });
  };
  setConversationIsRead = async (userID, conversationId) => {
    let idToken = await getIdToken(); // Cookies.get('elfj'); //await firebase.auth().onAuthStateChanged.currentUser.getIdToken();

    return new Promise((resolve, reject) => {
      axios
        .post('/api/chat/messageread', {
          idToken,
          userID,
          conversationId
        })
        .then(res => {
          store.dispatch({
            type: UpdateConversationIsRead,
            val: {
              userID: userID,
              conversationId: conversationId
            }
          });

          resolve(res.data);
        })
        .catch(error => {
          reject(error);
          errorLog('MessageFacade.setConversationIsRead', error);
        });
    });
  };
}

export default new MessageFacade();
