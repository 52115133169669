import {errorToast} from './toast';

export default error => {
  let message = 'Noget gik galt';
  //console.log('error', error.code);
  switch (error.code) {
    case 'auth/email-already-in-use':
      message = 'Der findes allerede en bruger med denne email adresse.';
      break;
    case 'auth/invalid-email':
      message = 'Ugyldig email adresse.';
      break;

    case 'auth/user-not-found':
    case 'auth/wrong-password':
      message = 'Ugyldig email adresse eller password.';
      break;

    case 'auth/too-many-requests':
      message = 'For mange kald til serveren. Prøv igen senre.';
      break;

    case 'auth/weak-password':
      message = 'Adgangsord er ikke stærk nok.';
      break;
    case 'auth/account-exists-with-different-credential':
      message = 'Der findes allerede en bruger med denne email adresse.';
      break;
  }
  errorToast({
    header: message
  });
  return {error: message};
};
