const initialState = {
  userData: {
    isLoggedIn: false,
    uid: '',
    _id: '',
    name: '',
    mail: '',
    photoURL: '',
    coverURL: '',
    location: {zipCode: '', cityName: '', longitude: '', latitude: ''},
    phone: '',
    created_at: null,
    updated_at: null,
    favourites: [],
    workerData: {
      isWorkerProfile: false,
      description: '',
      type: '' //freelancer or company
    }
  },
  emailVerified: null,
  previousData: {},
  userTasks: [],
  userServices: [],
  favourites: {
    favouriteTaks: [],
    favouriteServices: []
  }
};

export const SETUSERDATA = 'SETUSERDATA';
export const SetUserTasks = 'SetUserTasks';
export const SetUserServices = 'SetUserServices';

export const ResetUserData = 'ResetUserData';

export const SetEmailVerification = 'SetEmailVerification';

export const HandleProfileChange = 'HandleProfileChange';
export const HandleCancelEditProfileBtn = 'HandleCancelEditProfileBtn';
export const HandleEditProfileBtn = 'HandleEditProfileBtn';
export const SetPreviousData = 'SetPreviousData';
export const SetUserFavourites = 'SetUserFavourites';
export const SetUserPopulatedFavourites = 'SetUserPopulatedFavourites';

export default function dispatch(state = initialState, action) {
  switch (action.type) {
    case SETUSERDATA:
      return {
        ...state,
        userData: {...state.userData, ...action.val}
      };
    case SetEmailVerification:
      return {
        ...state,
        emailVerified: action.val
      };
    case ResetUserData:
      console.log('\n !!! in ResetUserData reducer ');
      return {
        ...state,
        userData: initialState.userData
      };
    case SetUserTasks:
      return {
        ...state,
        userTasks: [...action.val]
      };

    case SetUserServices:
      return {
        ...state,
        userServices: [...action.val]
      };

    case SetUserFavourites:
      return {
        ...state,
        userData: {...state.userData, favourites: action.val}
      };
    case SetUserPopulatedFavourites:
      return {
        ...state,
        favourites: action.val
      };

    case HandleEditProfileBtn:
      return {
        ...state,
        previousData: {...state.userData}
      };

    case HandleCancelEditProfileBtn:
      return {
        ...state,
        userData: {...state.previousData}
      };

    case SetPreviousData:
      return {
        ...state,
        previousData: action.val
      };

    case HandleProfileChange:
      let userData = {...state.userData};
      let splitetArray = action.val.name.includes('.')
        ? action.val.name.split('.')
        : [action.val.name];

      let level1 = userData[splitetArray[0]];
      let level2 = userData[splitetArray[0]][splitetArray[1]];
      switch (splitetArray.length) {
        case 1:
          userData = {...userData, [splitetArray[0]]: action.val.value};
          break;
        case 2:
          userData = {
            ...userData,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: action.val.value
            }
          };
          break;
        case 3:
          userData = {
            ...userData,
            [splitetArray[0]]: {
              ...level1,
              [splitetArray[1]]: {
                ...level2,
                [splitetArray[2]]: action.val.value
              }
            }
          };
          break;
        default:
          break;
      }
      return {
        ...state,
        userData: userData
      };

    default:
      return state;
  }
}
