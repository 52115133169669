import React, {useState, useEffect} from 'react';
import 'semantic-ui-css/semantic.min.css';

import {Route, Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import SocketFacade from './Data/SocketFacade';

import Main from './Components/Pages/Main';
import Profile from './Components/Pages/Profile';
import UserPage from './Components/Pages/UserPage';
import NewTask from './Components/Pages/NewTask.Page';
import About from './Components/Pages/About';
import Policy from './Components/Pages/Policy';
import Terms from './Components/Pages/Terms';

import RightBurgerMenu from './Components/RightBurgerMenu/RightBurgerMenu';
import FilterMenu from './Components/FilterMenu/FilterMenu';
import './App.css';
import './style/index.css';
import './style/toastStyling.css';

import TaskPage from './Components/Pages/TaskPage';
import ServicePage from './Components/Pages/ServicePage';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewService from './Components/Pages/NewService.Page.js';
import Moment from 'moment';
import Topbar from './Components/MenuBar/Topbar/Topbar';

import MobileNav from './Components/MobileNav/MobileNav';
import EditProfile from './Components/EditProfile/EditProfile';
import MessagePage from './Components/Pages/MessagePage';
import Favourites from './Components/Pages/Favourites';
import MyPosts from './Components/Pages/MyPosts';
import CookieWarning from './Components/CookieWarning/CookieWarning';
import MessageModal from './Components/MessagingNew/MessageModal';
import Footer from './Components/Footer/Footer';
import FeedbackButton from './Components/reuseable/FeedbackButton/FeedbackButton';
import Login from './Components/Login/Index';
import AfterLoginModal from './Components/AfterLoginModal/AfterLoginModal';
import MessageFacade from './Data/MessageFacade';
import TutorialModal from './Components/TutorialModal/TutorialModal';
import PageHead from './Components/reuseable/PageHead/PageHead';
import ErrorFallback from './Components/ErrorFallback/ErrorFallback';

const queryString = require('query-string');

//class App extends Component {
const App = () => {
  const [isMessagePage, setIsMessagePage] = useState(false);
  const [didShowCookieModal, setDidShowCookieModal] = useState(false);
  const user = useSelector(state => state.user.userData);
  Moment.locale('da');
  useEffect(() => {
    MessageFacade.loadConversations();

    checkIfMessagePage();
  });
  useEffect(() => {
    if (user.isLoggedIn) {
      SocketFacade.connect(user._id);
    }
  }, [0]);

  const checkIfMessagePage = () => {
    const pathIsMessagePage = window.location.pathname.includes('beskeder'); //todo use routename instead
    if (pathIsMessagePage !== isMessagePage) {
      setIsMessagePage(pathIsMessagePage);
    }
  };

  return (
    <React.Fragment>
      {process.env.REACT_APP_URL.includes('elilla') && (
        <PageHead noindex={true} />
      )}
      <div className="d-none d-md-block">
        <FilterMenu />
      </div>
      {!isMessagePage && (
        <div className="d-md-none">
          <RightBurgerMenu />
        </div>
      )}
      <Topbar />

      <div className={'app-container container '}>
        <Route path="/" exact component={Main} />

        <Route path="/login" exact component={Login} />
        <Route path="/opgaver/:id" exact component={TaskPage} />
        <Route path="/opgaver/:id/rediger" component={NewTask} />
        <Route path="/service/:id" exact component={ServicePage} />
        <Route path="/service/:id/rediger" component={NewService} />
        <Route path="/opret/opgave" component={NewTask} />
        <Route path="/opret/service" component={NewService} />
        <Route path="/profil" exact component={Profile} />
        <Route path="/profil/rediger" exact component={EditProfile} />
        <Route path="/profil/favoritter" exact component={Favourites} />
        <Route path="/profil/annoncer" exact component={MyPosts} />
        <Route path="/bruger/:id" component={UserPage} />
        <Route
          path="/beskeder"
          exact
          render={props => <MessagePage history={props.history} />}
        />
        <Route path="/brugerbetingelser" component={Terms} />
        <Route path="/privatlivspolitik" component={Policy} />
        <Route path="/om" component={About} />
        <ToastContainer className="toastContainer" />
        <CookieWarning />
        <MessageModal />
      </div>
      <Footer />

      <AfterLoginModal />
      {/* <TutorialModal />*/}
      <FeedbackButton />
    </React.Fragment>
  );
};
export default App;
