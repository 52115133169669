import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Menu,
  Message,
  Segment,
  Table
} from 'semantic-ui-react';
import FeedbackButton from '../reuseable/FeedbackButton/FeedbackButton';
import './ErrorFallback.css';
export default ({error, resetErrorBoundary}) => {
  //log to server using import {errorLog} from '../../utils/logger';

  return (
    <Grid container style={{padding: '5em 0em'}}>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1" dividing>
            Noget gik galt
          </Header>
          <p>
            Der er desværre opstået en fejl. Prøv at genindlæse siden eller gå
            til forsiden.
          </p>
          <a className="main-page-link ui yellow button" href="/">
            Tilbage til forsiden.
          </a>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <FeedbackButton />
      </Grid.Row>
    </Grid>
  );
};
